import {
  Box,
  Text,
  Flex,
  Skeleton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Button,
  useClipboard,
} from '@chakra-ui/react'
import { useAtom } from 'jotai'
import { FiCopy, FiMoreHorizontal } from 'react-icons/fi'
import { Link } from 'react-router-dom'
import { companyRecordAtom } from '../../atoms'
import { BASE_CLOUDFRONT_IMAGE_RESIZER_URL } from '../../config'
import { Company } from '../../interfaces'
import EmptyRecords from './EmptyRecords'

type CompanyCardProps = {
  item?: Company | undefined
  isLoading?: boolean
  onRegenerateToken?: (company: Company) => void
}

const CompanyCard: React.FC<CompanyCardProps> = ({
  item,
  isLoading,
  onRegenerateToken,
}) => {
  const [, setCompanyRecord] = useAtom(companyRecordAtom)
  const { hasCopied, onCopy } = useClipboard(
    `${window?.location ? `${window.location.protocol}//` : ''}${
      window?.location ? window.location.host : ''
    }/company/${item?.companyId}${item?.token ? `?t=${item?.token}` : ''}`
  )

  const onCopySharableLink = () => {
    onCopy()
  }

  return (
    <Box
      boxShadow="0px 4px 5px rgba(133, 72, 72, 0.05)"
      backgroundColor="white"
      borderRadius="10px"
      minHeight="256px"
      minW={isLoading ? '300px' : '280px'}
      p="22px"
      position="relative"
    >
      {!isLoading && !item && <EmptyRecords />}
      {isLoading ? (
        <>
          <Box position="absolute" right="22px" top="28px" cursor="pointer">
            <Skeleton height="10px" w="40px" />
          </Box>
          <Skeleton
            height="56px"
            width="56px"
            borderRadius="10px"
            mt="6px"
            maxW="150px"
          />
          <Skeleton height="18px" mt="18px" maxW="120px" />
          <Skeleton height="12px" maxW="80px" mt="4px" />
          <Box mt="30px">
            <Flex>
              <Skeleton height="13px" w="20px" mt="4px" mr="10px" />
              <Skeleton height="13px" w="60px" mt="4px" />
            </Flex>
            <Flex my="4px">
              <Skeleton height="13px" w="20px" mt="4px" mr="10px" />
              <Skeleton height="13px" w="120px" mt="4px" />
            </Flex>
            <Flex>
              <Skeleton height="13px" w="20px" mt="4px" mr="10px" />
              <Skeleton height="13px" w="100px" mt="4px" />
            </Flex>
          </Box>
        </>
      ) : (
        <>
          <Box position="absolute" right="22px" top="18px" cursor="pointer">
            <Menu closeOnSelect={false}>
              <MenuButton as={Button} variant="ghost" size="xs">
                <FiMoreHorizontal color="#BDC6D9" fontSize="22px" />
              </MenuButton>
              <MenuList minW="20ch">
                <MenuItem onClick={onCopySharableLink}>
                  <Flex justifyContent="center" alignItems="center">
                    <Text as="span" display="block" fontSize="sm" mr="8px">
                      <FiCopy />
                    </Text>
                    <Text as="span" display="block" fontSize="sm">
                      {hasCopied ? 'Copied!' : 'Copy sharable link'}
                    </Text>
                  </Flex>
                </MenuItem>

                {onRegenerateToken && item && (
                  <MenuItem onClick={() => onRegenerateToken(item)}>
                    <Flex justifyContent="center" alignItems="center">
                      <Text as="span" display="block" fontSize="sm" mr="8px">
                        <FiCopy />
                      </Text>
                      <Text as="span" display="block" fontSize="sm">
                        Regenerate Token
                      </Text>
                    </Flex>
                  </MenuItem>
                )}
              </MenuList>
            </Menu>
          </Box>
          <Box
            height="56px"
            width="56px"
            {...(item?.fileNameHashed
              ? {
                  background: `url(${BASE_CLOUDFRONT_IMAGE_RESIZER_URL}/fit-in/100x100/${item?.fileNameHashed}) no-repeat`,
                  backgroundSize: 'cover',
                  backgroundPosition: '50% 50%',
                }
              : { backgroundColor: 'gray6' })}
            mt="6px"
            borderRadius="10px"
          />
          <Link
            to={{
              pathname: `/company/${item?.companyId}`,
              ...(item?.token && {
                search: `?t=${item?.token}`,
              }),
            }}
            onClick={() => {
              setCompanyRecord({ isLoading: true })
            }}
          >
            <Text
              as="span"
              display="block"
              fontWeight="600"
              fontSize="18px"
              mt="12px"
              _hover={{
                textDecoration: 'underline',
              }}
              width="max-content"
            >
              {item?.name?.slice(0, 22)}
              {(item?.name?.length || 0) >= 22 ? '...' : ''}
            </Text>
          </Link>

          <Text
            as="span"
            display="block"
            fontWeight="400"
            fontSize="11px"
            color="darkGray"
            height="12px"
          >
            {item?.website}
          </Text>
          <Box as="ul" mt="30px">
            <Flex as="li" listStyleType="none">
              <Text
                as="span"
                color="violet"
                fontSize="13px"
                fontWeight="700"
                width="30px"
                display="block"
              >
                {item?.showsCount}
              </Text>
              <Text as="span" color="darkGray" fontSize="13px">
                Events
              </Text>
            </Flex>
            <Flex as="li" listStyleType="none" my="4px">
              <Text
                as="span"
                color="violet"
                fontSize="13px"
                fontWeight="700"
                width="30px"
                display="block"
              >
                {item?.boatProductsCount}
              </Text>
              <Text as="span" color="darkGray" fontSize="13px">
                Boat Products
              </Text>
            </Flex>
            <Flex as="li" listStyleType="none">
              <Text
                as="span"
                color="violet"
                fontSize="13px"
                fontWeight="700"
                width="30px"
                display="block"
              >
                {item?.exhibitorsCount}
              </Text>
              <Text as="span" color="darkGray" fontSize="13px">
                Exhibitors
              </Text>
            </Flex>
          </Box>
        </>
      )}
    </Box>
  )
}
export default CompanyCard
