import { useMemo, useRef, useState } from 'react'
import {
  Text,
  Grid,
  Flex,
  useDisclosure,
  Box,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  useToast,
} from '@chakra-ui/react'
import { FiPlus, FiSearch, FiX } from 'react-icons/fi'
import useSWR from 'swr'
import uuid from 'react-uuid'
import { debounce } from 'lodash'

import { CreateCompanyForm } from '../components/partials'
import {
  CompanyCard,
  Pagination,
  ThemedButton,
  ConfirmationModal,
} from '../components/shared'
import { fetcher, httpPost } from '../fetchers'
import DefaultLayout from '../layouts/DefaultLayout'
import { Company, QueryVariables } from '../interfaces'
import EmptyRecords from '../components/shared/EmptyRecords'
import { BASE_URL } from '../config'

const Dashboard: React.FC = () => {
  const [variables, setVariables] = useState<QueryVariables>({
    api: '',
    first: 25,
    after: null,
    savedCursor: [null],
    currentPage: 0,
  })

  const [keyword, setKeyword] = useState('')
  const [key, setKey] = useState('')
  const [companyToRegenerateToken, setCompanyToRegenerateToken] =
    useState<Company | null>(null)
  const ref = useRef<HTMLInputElement>(null)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const toast = useToast()

  const {
    data: res,
    mutate,
    error,
  } = useSWR(
    `/dashboard${keyword ? '/search' : ''}?first=${variables.first}${
      variables.after ? `&after=${encodeURIComponent(variables.after)}` : ''
    }${keyword ? `&keyword=${encodeURIComponent(keyword)}` : ''}`,
    fetcher
  )

  const isLoading = !error && !res
  const records = res?.data?.Items as Company[]

  const { isOpen, onOpen, onClose: onModalClose } = useDisclosure()

  const {
    isOpen: isOpenConfirmModal,
    onOpen: onOpenConfirmModal,
    onClose: onCloseConfirmModal,
  } = useDisclosure()

  const onClose = () => {
    onModalClose()
    setTimeout(() => {
      setKey(uuid())
    }, 300)
  }

  const changeHandler = (event: any) => {
    setKeyword(event.target.value)
    setVariables(v => ({
      ...v,
      currentPage: 0,
      after: event.target.value ? '0' : null,
      savedCursor: [null],
    }))
  }

  const debouncedChangeHandler = useMemo(() => debounce(changeHandler, 300), [])

  const handleNext = () => {
    if (res?.data?.LastEvaluatedKey) {
      setVariables(v => ({
        ...v,
        after: `${res?.data?.LastEvaluatedKey?.companyId}::${res?.data?.LastEvaluatedKey?.name}`,
        savedCursor: [
          ...v.savedCursor,
          `${res?.data?.LastEvaluatedKey?.companyId}::${res?.data?.LastEvaluatedKey?.name}`,
        ],
        currentPage: v.currentPage! + 1,
      }))
    } else if (keyword) {
      setVariables(v => ({
        ...v,
        after: `${v.currentPage! + 1}`,
        savedCursor: [...v.savedCursor, `${v.currentPage! + 1}`],
        currentPage: v.currentPage! + 1,
      }))
    }
  }

  const handlePrev = () => {
    setVariables(v => ({
      ...v,
      after: v.savedCursor[v.currentPage! - 1],
      currentPage: v.currentPage! - 1,
    }))
  }

  const onRegenerateTokenConfirm = async () => {
    setIsSubmitting(true)
    try {
      const response = await httpPost({
        url: `${BASE_URL}/company/${companyToRegenerateToken?.companyId}/regenerate-token`,
        body: {
          token: companyToRegenerateToken?.token,
        },
      })

      if (response.status === 200) {
        mutate().then(() => {
          setIsSubmitting(false)
          onCloseConfirmModal()

          toast({
            title: 'Company updated.',
            description: 'The company token has been updated.',
            status: 'success',
            duration: 1500,
            isClosable: true,
          })
        })
      }
    } catch (error) {
      console.log(error)
      setIsSubmitting(false)
      onCloseConfirmModal()
    }
  }

  const onRegenerateToken = async (company: Company) => {
    setCompanyToRegenerateToken(company)
    onOpenConfirmModal()
  }

  return (
    <>
      <DefaultLayout noXPadding>
        <Flex mx="49px" justifyContent="space-between" alignItems="center">
          <Text as="span" fontSize="32px" fontWeight="600">
            Dashboard
          </Text>
          <ThemedButton
            height="55px"
            width="158px"
            type="button"
            onClick={onOpen}
            leftIcon={<FiPlus color="white" fontSize="22px" />}
          >
            <Text as="span" ml="8px">
              Create
            </Text>
          </ThemedButton>
        </Flex>
        <InputGroup size="md" maxW="sm" mx="49px" mt="20px">
          <InputLeftElement pointerEvents="none" color="gray.500">
            <FiSearch />
          </InputLeftElement>
          <Input
            type="text"
            placeholder="Search"
            backgroundColor="white"
            onChange={debouncedChangeHandler}
            ref={ref}
          />
          {keyword && (
            <InputRightElement
              color="gray.500"
              cursor="pointer"
              onClick={() => {
                setKeyword('')
                ref.current!.value = ''
                setVariables(v => ({
                  ...v,
                  first: 25,
                  after: null,
                  savedCursor: [null],
                  currentPage: 0,
                }))
              }}
            >
              <FiX />
            </InputRightElement>
          )}
        </InputGroup>
        {!isLoading && !records?.length && <EmptyRecords />}
        <Box mx="49px">
          {isLoading ? (
            <Flex>
              <Grid
                templateColumns="repeat(auto-fit,minmax(280px,1fr))"
                autoColumns="max-content"
                autoRows="auto"
                gap="24px"
                mt="58px"
                maxW={272 * 4}
              >
                <CompanyCard isLoading />
                <CompanyCard isLoading />
                <CompanyCard isLoading />
              </Grid>
            </Flex>
          ) : (
            <Box>
              <Grid
                templateColumns="repeat(auto-fit,minmax(280px,1fr))"
                autoColumns="max-content"
                autoRows="auto"
                gap="24px"
                mt="58px"
                {...(records?.length <= 3 && { maxW: 272 * records?.length })}
              >
                {records?.map(r => (
                  <CompanyCard
                    key={r?.companyId}
                    item={r}
                    onRegenerateToken={onRegenerateToken}
                  />
                ))}
              </Grid>
            </Box>
          )}
          <Flex justifyContent="start">
            <Pagination
              mt="3rem"
              isLoadingData={isLoading}
              disableNext={!res?.data?.hasNextPage}
              disablePrev={Number(variables.currentPage) + 1 === 1}
              handleNext={handleNext}
              handlePrev={handlePrev}
            />
          </Flex>
        </Box>
      </DefaultLayout>
      <CreateCompanyForm
        key={key}
        isOpen={isOpen}
        onClose={onClose}
        mutate={mutate}
      />

      <ConfirmationModal
        title="Regenerate Token"
        isOpen={isOpenConfirmModal}
        onCancel={onCloseConfirmModal}
        onSubmit={onRegenerateTokenConfirm}
        isSubmitting={isSubmitting}
      />
    </>
  )
}

export default Dashboard
