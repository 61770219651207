import { useAtom } from 'jotai'
import { useCallback } from 'react'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { companyRecordAtom, productsServicesAtom } from '../atoms'
import { fetcher } from '../fetchers'
import { ProductService } from '../interfaces'

const useProductsServices = () => {
  const [productsServices, setProductsServices] = useAtom(productsServicesAtom)
  const [companyRecord] = useAtom(companyRecordAtom)

  const fetchProductsServices = useCallback(async () => {
    const res = await fetcher(
      `/company/${companyRecord.record?.companyId}/products-services?first=100`
    )
    if (res.data) {
      const productsServicesFromResponse = res.data?.Items?.reduce(
        (acc: any, curr: ProductService) => {
          const t = {
            label: curr.productName,
            value: curr.productServiceId,
          }

          return [...acc, t]
        },
        [] as { label: string; value: string }[]
      )

      setProductsServices(productsServicesFromResponse)
    }
  }, [productsServices.length, companyRecord.record?.companyId])

  useDeepCompareEffect(() => {
    fetchProductsServices()
  }, [productsServices, companyRecord.record?.companyId])

  return { productsServices, setProductsServices }
}

export default useProductsServices
