import { Text, TextProps } from '@chakra-ui/react'

const ThemedFormHelperMessage: React.FC<TextProps & { isDisabled?: boolean }> =
  ({ children, isDisabled, ...props }) => (
    <Text
      as="span"
      display="block"
      fontWeight="400"
      color="darkGray"
      fontSize="11px"
      opacity={isDisabled ? 0.6 : 1}
      {...props}
    >
      {children}
    </Text>
  )

export default ThemedFormHelperMessage
