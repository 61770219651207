import { Flex, Box } from '@chakra-ui/react'
import { useAtom } from 'jotai'

import { CompanyForm } from '../../components/partials'
import { companyRecordAtom } from '../../atoms'
import ThemedFormHelperMessage from '../../components/shared/ThemedFormHelperMessage'

const Profile: React.FC<{ mutate?: any }> = () => {
  const [{ isLoading, record }] = useAtom(companyRecordAtom)

  return (
    <Flex w="full" pb="171px" position="relative">
      <Box mx="auto" mt="54px" minW="800px">
        <CompanyForm isLoading={isLoading} record={record} />
      </Box>
      <Flex
        w="full"
        justifyContent="end"
        mb="20px"
        pr="20px"
        position="absolute"
        bottom="0px"
      >
        <ThemedFormHelperMessage>
          For questions, please contact showservice@nmma.org
        </ThemedFormHelperMessage>
      </Flex>
    </Flex>
  )
}

export default Profile
