import { Crop } from 'react-image-crop'

export interface AWSQueryOutput {
  Items?: Record<string, any>[]
  Count?: number
  ScannedCount?: number
  LastEvaluatedKey?: Record<string, any>
}

export interface Booth {
  id: string
  value: string
}
export interface BoatProduct {
  companyId: string
  boatProductId: string
  boatType: string
  certifiedFlag: string
  description: string
  length: string
  modelType: string
  productName: string
  propulsionType: string
  sourceProductCode: string
  startingPrice: string
  fileName: string
  fileNameHashed: string
  createdAt: string
  updatedAt: string
  gallery: Gallery[]
  basicGallery: Gallery[]
  beam: string
  draft: string
  youtubeUrls: string[]
  brandListingUrl: string
  published: boolean
  cloneCount: number
}

export interface Category {
  name: string
  subCategories: string[]
}

export interface Contact {
  companyId: string
  contactId: string
  firstName: string
  lastName: string
  prefix: string
  address: string
  email: string
  phone: string
  facebook: string
  linkedin: string
  youtube: string
  twitter: string
  title: string
  createdAt: string
  updatedAt: string
}

export interface Company {
  companyId: string
  sourceCompanyCode: string
  name: string
  address: string
  phone: string
  website: string
  facebook: string
  linkedin: string
  youtube: string
  twitter: string
  instagram: string
  type: CompanyType
  nmmaMembershipStatus: string
  nmmaDivision: NMMADivision
  nmmaCertified: boolean
  mraaDealerCertified: boolean
  fileName: string
  fileNameHashed: string
  token?: string
  exhibitorsCount?: string
  showsCount?: string
  boatProductsCount?: string
  order?: boolean
  micrositeUrl?: string
  createdAt: string
  updatedAt: string
}

export enum CompanyTypeEnum {
  BRAND = 'Brand / Manufacturer',
  DEALER = 'Dealer',
  RETAILER = 'Retailer / Service Provider',
}

export type CompanyType =
  | CompanyTypeEnum.BRAND
  | CompanyTypeEnum.DEALER
  | CompanyTypeEnum.RETAILER

export interface Exhibitor {
  companyId: string
  exhibitorId: string
  sourceExhibitorId: string
  bannerName: string
  showId: string
  contactId: string
  status: string
  booths: Booth[]
  order: string
  category: string[]
  subCategory: string[]
  contact: Contact
  show: Show
  mapsBooths?: any[]
  createdAt: string
  updatedAt: string
}

export interface ExhibitorBoatProduct {
  companyId: string
  exhibitorBoatProductId: string
  boothIds: string[]
  brandIds: string[]
  brands?: Company[]
  exhibitor?: Exhibitor
  show?: Show
  boatProduct?: BoatProduct
  boothCoordinates: Partial<Crop> & { basis: { width: number; height: number } }
  status: string
  mapsBooths?: any[]
  createdAt: string
  updatedAt: string
}

export interface ExhibitorProductService {
  companyId: string
  exhibitorProductServiceId: string
  boothIds: string[]
  mapsBooths: any[]
  exhibitor?: Exhibitor
  show?: Show
  productService?: ProductService
  status: string
  createdAt: string
  updatedAt: string
}

export interface GenerateNotifMessageArgs {
  companyName: string
  message: string
}

export enum NMMADivisionEnum {
  MACD = 'MACD',
  ASSOC = 'ASSOC',
  BMD = 'BMD',
  EMD = 'EMD',
  NONE = 'Not Applicable',
}

export type NMMADivision =
  | NMMADivisionEnum.MACD
  | NMMADivisionEnum.ASSOC
  | NMMADivisionEnum.BMD
  | NMMADivisionEnum.EMD
  | NMMADivisionEnum.NONE

export interface Notification {
  clicked: boolean
  companyId: string
  companyName: string
  createdAt: string
  message: string
  notifId: string
  diff?: string
  token?: string
}

export interface ProductService {
  companyId: string
  productServiceId: string
  brandName: string
  category: string
  description: string
  productName: string
  subCategory: string
  fileName: string
  fileNameHashed: string
}

export enum Roles {
  NMMA_ADMIN = 'NMMA_ADMIN',
}

export interface Show {
  showId: string
  showName: string
  description: string
  endDate: string
  family: string
  location: string
  producerEventCode: string
  startDate: string
  status: string
  venue: string
  website: string
  fileName: string
  fileNameHashed: string
  maps: any
  navigations: { isChecked: boolean; name: string; link: string }[]
  mapFileName: string
  mapFileNameHashed: string
  createdAt: string
  updatedAt: string
}

export enum StatusEnum {
  ACTIVE = 'Active',
  APPROVED = 'Approved',
  CANCELLED = 'Cancelled',
  CLOSED = 'Closed',
  DECLINED = 'Declined',
  NONACTIVE = 'Non-Active',
  PENDING = 'Pending',
  REJECTED = 'Rejected',
  APPROVED_EBC = 'Approved EBC',
  PENDING_EBC = 'Pending EBC',
}

export type Status =
  | StatusEnum.ACTIVE
  | StatusEnum.APPROVED
  | StatusEnum.CANCELLED
  | StatusEnum.CLOSED
  | StatusEnum.DECLINED
  | StatusEnum.NONACTIVE
  | StatusEnum.PENDING
  | StatusEnum.APPROVED_EBC
  | StatusEnum.PENDING_EBC

export type ThemedSelectItem = {
  label: string
  value: string | boolean | undefined | number
}

export type Gallery = {
  fileName: string
  fileNameHashed: string
  url: string
}

export interface QueryVariables {
  api: string
  first?: number
  after?: string | null
  filter?: Record<string, any>
  keyword?: string
  savedCursor?: any
  currentPage?: number
  t?: any
  status?: string
}

export enum YesNoEnum {
  YES = 'Yes',
  NO = 'No',
}

export type YesNo = YesNoEnum.YES | YesNoEnum.NO
