import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Box,
  Grid,
  useToast,
  Spinner,
  Checkbox,
} from '@chakra-ui/react'
import { Controller, useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { AxiosResponse } from 'axios'
import useDeepCompareEffect from 'use-deep-compare-effect'

import {
  ThemedButton,
  ThemedDragDrop,
  ThemedFormErrorMessage,
  ThemedInput,
  ThemedLabel,
  ThemedSelect,
} from '../../shared'
import {
  StatusEnum,
  NMMADivisionEnum,
  CompanyTypeEnum,
  YesNoEnum,
  ThemedSelectItem,
} from '../../../interfaces'
import { BASE_URL } from '../../../config'
import { uploadPhotoToS3 } from '../../../utils'
import ThemedFormHelperMessage from '../../shared/ThemedFormHelperMessage'
import { httpPost } from '../../../fetchers'

type CreateCompanyFormProps = {
  isOpen: boolean
  onClose: () => void
  mutate: () => Promise<AxiosResponse<any> | undefined>
}
interface FormData {
  name: string
  sourceCompanyCode: string
  address: string
  phone: string
  website: string
  facebook: string
  linkedin: string
  youtube: string
  twitter: string
  instagram: string
  type: ThemedSelectItem
  nmmaMembershipStatus: ThemedSelectItem
  nmmaDivision: ThemedSelectItem
  nmmaCertified: ThemedSelectItem
  mraaDealerCertified: ThemedSelectItem
  images?: File[]
  order?: boolean
  micrositeUrl?: string
}

const companySchema = yup.object().shape({
  name: yup
    .string()
    .required('Required.')
    .matches(/^([A-Za-z0-9]+[\ \/&',.-]*)*$/g, {
      message: 'Please provide a valid company name.',
    }),
  sourceCompanyCode: yup.string().required('Required.'),
  address: yup.string(),
  phone: yup.string(),
  website: yup
    .string()
    .test(
      'is-valid-url',
      'Please provide a valid url.',
      value =>
        !value ||
        !!value?.match(
          /^((http|https):\/\/)?(www.)?(?!.*(http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+(\/)?.([\w\?[a-zA-Z-_%\/@?.']+)*([^\/\w\?[a-zA-Z0-9_-]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm
        )
    ),
  facebook: yup
    .string()
    .test(
      'is-valid-url',
      'Please provide a valid url.',
      value =>
        !value ||
        !!value?.match(
          /^((http|https):\/\/)?(www.)?(?!.*(http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+(\/)?.([\w\?[a-zA-Z-_%\/@?.']+)*([^\/\w\?[a-zA-Z0-9_-]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm
        )
    ),
  linkedin: yup
    .string()
    .test(
      'is-valid-url',
      'Please provide a valid url.',
      value =>
        !value ||
        !!value?.match(
          /^((http|https):\/\/)?(www.)?(?!.*(http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+(\/)?.([\w\?[a-zA-Z-_%\/@?.']+)*([^\/\w\?[a-zA-Z0-9_-]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm
        )
    ),
  youtube: yup.string(),
  twitter: yup
    .string()
    .test(
      'is-valid-url',
      'Please provide a valid url.',
      value =>
        !value ||
        !!value?.match(
          /^((http|https):\/\/)?(www.)?(?!.*(http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+(\/)?.([\w\?[a-zA-Z-_%\/@?.']+)*([^\/\w\?[a-zA-Z0-9_-]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm
        )
    ),
  instagram: yup
    .string()
    .test(
      'is-valid-url',
      'Please provide a valid url.',
      value =>
        !value ||
        !!value?.match(
          /^((http|https):\/\/)?(www.)?(?!.*(http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+(\/)?.([\w\?[a-zA-Z-_%\/@?.']+)*([^\/\w\?[a-zA-Z0-9_-]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm
        )
    ),
  order: yup.bool(),
  micrositeUrl: yup.string(),
})

const options = {
  nmmaMembershipStatus: [
    { value: StatusEnum.ACTIVE, label: StatusEnum.ACTIVE },
    { value: StatusEnum.NONACTIVE, label: StatusEnum.NONACTIVE },
  ],
  nmmaDivision: [
    { value: NMMADivisionEnum.MACD, label: NMMADivisionEnum.MACD },
    { value: NMMADivisionEnum.ASSOC, label: NMMADivisionEnum.ASSOC },
    { value: NMMADivisionEnum.BMD, label: NMMADivisionEnum.BMD },
    { value: NMMADivisionEnum.EMD, label: NMMADivisionEnum.EMD },
    { value: NMMADivisionEnum.NONE, label: NMMADivisionEnum.NONE },
  ],
  companyTypeOptions: [
    {
      value: CompanyTypeEnum.BRAND,
      label: CompanyTypeEnum.BRAND,
    },
    { value: CompanyTypeEnum.DEALER, label: CompanyTypeEnum.DEALER },
    { value: CompanyTypeEnum.RETAILER, label: CompanyTypeEnum.RETAILER },
  ],
  yesOrNo: [
    { value: true, label: YesNoEnum.YES },
    { value: false, label: YesNoEnum.NO },
  ],
}

const CreateCompanyForm: React.FC<CreateCompanyFormProps> = ({
  isOpen,
  onClose,
  mutate,
}) => {
  const toast = useToast()

  const {
    register,
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
    watch,
    setValue,
    clearErrors,
  } = useForm<FormData>({
    defaultValues: {
      type: options.companyTypeOptions[0],
      nmmaMembershipStatus: options.nmmaMembershipStatus[0],
      nmmaDivision: options.nmmaDivision[0],
      nmmaCertified: options.yesOrNo[0],
      mraaDealerCertified: options.yesOrNo[0],
      images: [],
    },
    resolver: yupResolver(companySchema),
  })

  const onSubmit = async (data: FormData) => {
    const { images, ...rest } = data

    try {
      const image: File | null = images?.length ? images[0] : null
      let fileNameHash: string = ''

      if (image) {
        const fileValues = {
          fileName: image!.name,
          fileType: image!.type,
        }

        const {
          data: { url, fileNameHashed },
        } = await httpPost({
          url: `${BASE_URL}/getPresignedUrl`,
          body: fileValues,
        })

        await uploadPhotoToS3({ url, file: image! })

        fileNameHash = fileNameHashed
      }

      const values = {
        ...rest,
        ...(image !== null && {
          fileName: image.name,
          fileNameHashed: fileNameHash,
        }),
        mraaDealerCertified: rest.mraaDealerCertified.value,
        nmmaCertified: rest.nmmaCertified.value,
        nmmaDivision: rest.nmmaDivision.value,
        nmmaMembershipStatus: rest.nmmaMembershipStatus.value,
        type: rest.type.value,
        ...(rest.type.value === CompanyTypeEnum.BRAND && {
          order: rest.order,
          micrositeUrl: rest.order ? data.micrositeUrl : '',
        }),
      }

      const res = await httpPost({
        url: `${BASE_URL}/company/create`,
        body: values,
      })

      if (res?.data) {
        mutate().then(() => {
          toast({
            title: 'Company created.',
            description: 'The company has been added.',
            status: 'success',
            duration: 1500,
            isClosable: true,
          })
        })
        onClose()
      }
    } catch (err) {
      const errResponse = (err as any)?.response

      if (errResponse) {
        const { code } = errResponse.data

        if (code === 'ConditionalCheckFailedException') {
          toast({
            title: 'Failed to create a company.',
            description: 'There is an existing company with the same name.',
            status: 'error',
            duration: 5000,
            isClosable: true,
          })
        } else {
          toast({
            title: 'Failed to create a company.',
            description: errResponse.data.message,
            status: 'error',
            duration: 2000,
            isClosable: true,
          })
        }
      } else {
        console.error(err)
      }
    }
  }

  const imagesField = register('images')
  const watchedImages = watch('images')

  useDeepCompareEffect(() => {
    if (watchedImages?.length && errors?.images?.message) {
      clearErrors('images')
    }
  }, [watchedImages, errors])

  return (
    <Modal isOpen={isOpen} closeOnOverlayClick={false} onClose={onClose}>
      <ModalOverlay />
      <ModalContent p="0px" minW="700px">
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalHeader textAlign="center" pt="50px">
            <Text as="span" fontWeight="600" fontSize="32px">
              Company
            </Text>
          </ModalHeader>
          <ModalCloseButton
            color="mediumGray"
            marginTop="15px"
            marginRight="10px"
            isDisabled={isSubmitting}
          />
          <ModalBody paddingLeft="32px" paddingRight="32px">
            <Grid
              mt="20px"
              templateColumns="repeat(2,1fr)"
              autoColumns="max-content"
              autoRows="auto"
              rowGap="18px"
              columnGap="16px"
            >
              <Box gridColumn="1/3">
                <ThemedLabel htmlFor="images">Upload Logo</ThemedLabel>
                <ThemedDragDrop
                  id="images"
                  {...imagesField}
                  ref={imagesField.ref}
                  setValue={setValue}
                  isSingleUpload
                />
                <ThemedFormErrorMessage>
                  {errors.images?.message}
                </ThemedFormErrorMessage>
              </Box>
              <Box>
                <ThemedLabel htmlFor="sourceCompanyCode">
                  Source Company Code
                </ThemedLabel>
                <ThemedInput
                  id="sourceCompanyCode"
                  {...register('sourceCompanyCode')}
                />
                {errors.sourceCompanyCode?.message ? (
                  <ThemedFormErrorMessage>
                    {errors.sourceCompanyCode?.message}
                  </ThemedFormErrorMessage>
                ) : (
                  <ThemedFormHelperMessage mt="4px">
                    Internal Use Only
                  </ThemedFormHelperMessage>
                )}
              </Box>
              <Box>
                <ThemedLabel htmlFor="name">Name</ThemedLabel>
                <ThemedInput id="name" {...register('name')} />
                <ThemedFormErrorMessage>
                  {errors.name?.message}
                </ThemedFormErrorMessage>
              </Box>
              <Box>
                <ThemedLabel htmlFor="address">Address</ThemedLabel>
                <ThemedInput id="name" {...register('address')} />
                <ThemedFormErrorMessage>
                  {errors.address?.message}
                </ThemedFormErrorMessage>
              </Box>
              <Box>
                <ThemedLabel htmlFor="phone">Phone</ThemedLabel>
                <ThemedInput id="phone" {...register('phone')} />

                <ThemedFormErrorMessage>
                  {errors.phone?.message}
                </ThemedFormErrorMessage>
              </Box>
              <Box>
                <ThemedLabel htmlFor="website">Website</ThemedLabel>
                <ThemedInput id="website" {...register('website')} />
                <ThemedFormErrorMessage>
                  {errors.website?.message}
                </ThemedFormErrorMessage>
              </Box>
              <Box>
                <ThemedLabel htmlFor="facebook">Facebook</ThemedLabel>
                <ThemedInput id="facebook" {...register('facebook')} />
                <ThemedFormErrorMessage>
                  {errors.facebook?.message}
                </ThemedFormErrorMessage>
              </Box>
              <Box>
                <ThemedLabel htmlFor="linkedin">Linkedin</ThemedLabel>
                <ThemedInput id="linkedin" {...register('linkedin')} />
                <ThemedFormErrorMessage>
                  {errors.linkedin?.message}
                </ThemedFormErrorMessage>
              </Box>
              <Box>
                <ThemedLabel htmlFor="youtube">Youtube</ThemedLabel>
                <ThemedInput id="youtube" {...register('youtube')} />
                <ThemedFormErrorMessage>
                  {errors.youtube?.message}
                </ThemedFormErrorMessage>
              </Box>
              <Box>
                <ThemedLabel htmlFor="twitter">Twitter</ThemedLabel>
                <ThemedInput id="twitter" {...register('twitter')} />
                <ThemedFormErrorMessage>
                  {errors.twitter?.message}
                </ThemedFormErrorMessage>
              </Box>
              <Box>
                <ThemedLabel htmlFor="instagram">Instagram</ThemedLabel>
                <ThemedInput id="instagram" {...register('instagram')} />
                <ThemedFormErrorMessage>
                  {errors.instagram?.message}
                </ThemedFormErrorMessage>
              </Box>
              <Box>
                <ThemedLabel htmlFor="type">Company Type</ThemedLabel>
                <Controller
                  control={control}
                  name="type"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <ThemedSelect
                      id="type"
                      options={options.companyTypeOptions}
                      defaultValue={options.companyTypeOptions[0]}
                      isSearchable={false}
                      maxWidthOptions={299}
                      onBlur={onBlur}
                      onChange={onChange}
                      checked={value}
                      inputRef={ref}
                    />
                  )}
                />
              </Box>
              {watch('type').value === CompanyTypeEnum.BRAND && (
                <>
                  <Box gridColumn="1/3" display="flex">
                    <Box>
                      <Checkbox id="order" {...register('order')}>
                        <ThemedLabel htmlFor="order" mt="6px">
                          Order
                        </ThemedLabel>
                      </Checkbox>
                    </Box>
                  </Box>
                  {watch('order') && (
                    <>
                      <Box gridColumn="1/3">
                        <ThemedLabel htmlFor="micrositeUrl">
                          Microsite URL
                        </ThemedLabel>
                        <ThemedInput
                          id="micrositeUrl"
                          {...register('micrositeUrl')}
                        />
                        <ThemedFormErrorMessage>
                          {errors.micrositeUrl?.message}
                        </ThemedFormErrorMessage>
                      </Box>
                    </>
                  )}
                </>
              )}
              <Box gridColumn="1/3">
                <ThemedLabel htmlFor="nmmaMembershipStatus">
                  NMMA Membership Status
                </ThemedLabel>
                <Controller
                  control={control}
                  name="nmmaMembershipStatus"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <ThemedSelect
                      id="nmmaMembershipStatus"
                      options={options.nmmaMembershipStatus}
                      defaultValue={options.nmmaMembershipStatus[0]}
                      isSearchable={false}
                      maxWidthOptions={625}
                      onBlur={onBlur}
                      onChange={onChange}
                      checked={value}
                      inputRef={ref}
                    />
                  )}
                />
              </Box>
            </Grid>
            <Grid
              templateColumns="repeat(3,1fr)"
              autoColumns="max-content"
              autoRows="auto"
              columnGap="19px"
              mt="18px"
            >
              <Box>
                <ThemedLabel htmlFor="nmmaDivision">NMMA Division</ThemedLabel>
                <Controller
                  control={control}
                  name="nmmaDivision"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <ThemedSelect
                      id="nmmaDivision"
                      options={options.nmmaDivision}
                      defaultValue={options.nmmaDivision[0]}
                      isSearchable={false}
                      maxWidthOptions={188}
                      onBlur={onBlur}
                      onChange={onChange}
                      checked={value}
                      inputRef={ref}
                    />
                  )}
                />
              </Box>
              <Box>
                <ThemedLabel htmlFor="nmmaCertified">
                  Is NMMA Certified
                </ThemedLabel>
                <Controller
                  control={control}
                  name="nmmaCertified"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <ThemedSelect
                      id="nmmaCertified"
                      options={options.yesOrNo}
                      defaultValue={options.yesOrNo[0]}
                      isSearchable={false}
                      maxWidthOptions={188}
                      onBlur={onBlur}
                      onChange={onChange}
                      checked={value}
                      inputRef={ref}
                    />
                  )}
                />
              </Box>
              <Box>
                <ThemedLabel htmlFor="mraaDealerCertified">
                  Is MRAA Dealer Certified
                </ThemedLabel>
                <Controller
                  control={control}
                  name="mraaDealerCertified"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <ThemedSelect
                      id="mraaDealerCertified"
                      options={options.yesOrNo}
                      defaultValue={options.yesOrNo[0]}
                      isSearchable={false}
                      maxWidthOptions={188}
                      onBlur={onBlur}
                      onChange={onChange}
                      checked={value}
                      inputRef={ref}
                    />
                  )}
                />
              </Box>
            </Grid>
          </ModalBody>
          <ModalFooter justifyContent="center" pt="46px" pb="48px">
            <ThemedButton
              type="submit"
              height="55px"
              width="158px"
              isDisabled={isSubmitting}
            >
              {isSubmitting ? (
                <Spinner
                  thickness="2px"
                  speed="0.65s"
                  emptyColor="gray.200"
                  color="blue.500"
                  size="sm"
                />
              ) : (
                <Text as="span">Submit</Text>
              )}
            </ThemedButton>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  )
}

export default CreateCompanyForm
