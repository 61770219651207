import styled from '@emotion/styled'
import {
  Box,
  BoxProps,
  Checkbox,
  Flex,
  forwardRef,
  Skeleton,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'
import React, { useImperativeHandle } from 'react'
import { camelCase, sample } from 'lodash'
import uuid from 'react-uuid'
import { useForm } from 'react-hook-form'

import Status from './Status'
import { Pagination } from './index'
import { StatusEnum } from '../../interfaces'
import EmptyRecords from './EmptyRecords'

const StyledTable = styled(Table)`
  & {
    user-select: 'none';
    thead {
      border-color: transparent;
      tr {
        border-color: transparent;

        th {
          border-color: transparent;
        }
      }
    }
    tbody {
      border-color: transparent;
      tr {
        border-color: transparent;

        td {
          border-color: transparent;
          font-weight: 400;
          font-size: 14px;
          white-space: nowrap;
        }
      }
    }
  }
`
const StyledFlex = styled(Flex)``

interface ThemedTableProps extends BoxProps {
  columns?: string[]
  rows?: Record<string, any> | undefined[]
  isLoading?: boolean
  onRowClick?: (row?: any) => void
  disableNext?: boolean
  disablePrev?: boolean
  handleNext?: () => void
  setChecks?: (arg: any) => void
  handlePrev?: () => void
  allowMultipleChecks?: boolean
  isPendingEbc?: boolean
  setHasChecks?: (arg: any) => void
  allowClone?: boolean
  afterSuccessCallback?: any
}

const ThemedTable: React.FC<ThemedTableProps> = forwardRef(
  (
    {
      columns,
      rows,
      isLoading,
      onRowClick,
      disableNext,
      disablePrev,
      handleNext,
      handlePrev,
      setChecks,
      allowMultipleChecks,
      isPendingEbc,
      setHasChecks,
      allowClone,
      ...props
    },
    ref
  ) => {
    const { register, watch, setValue, reset } = useForm()
    const allChecked = watch('checkbox')?.every(Boolean)
    const isIndeterminate = watch('checkbox')?.some(Boolean) && !allChecked

    useImperativeHandle(ref, () => ({
      reset: () => {
        setChecks?.([false])
        reset()
        for (let index = 0; index < rows?.length; index += 1) {
          setValue(`checkbox[${index}]`, false)
        }
      },
    }))

    return !isLoading && !rows?.length ? (
      <>
        <EmptyRecords pl="49px" pr="36px" />
        <Flex mx="49px" justifyContent="start">
          <Pagination
            mt="1.5rem"
            isLoadingData={isLoading}
            disablePrev={disablePrev}
            disableNext={disableNext}
            handleNext={handleNext}
            handlePrev={handlePrev}
          />
        </Flex>
      </>
    ) : (
      <Box w="100%" {...props}>
        <StyledFlex
          pl="49px"
          mb="35px"
          overflow="auto"
          pb="40px"
          id="tableEvent"
        >
          <StyledTable variant="simple">
            <Thead
              border="1px solid"
              borderColor="transparent"
              backgroundColor="transparent"
              borderRadius="10px"
              cursor="auto"
            >
              <Tr
                h="60px"
                backgroundColor="white"
                boxShadow="0px 4px 5px rgba(0, 0, 0, 0.05)"
                borderRadius="10px"
              >
                {allowMultipleChecks && !isPendingEbc && !allowClone && (
                  <Th borderTopLeftRadius="10px" borderBottomLeftRadius="10px">
                    <Checkbox
                      isChecked={allChecked}
                      isIndeterminate={isIndeterminate}
                      onChange={e => {
                        if (e.target.checked && rows?.length) {
                          for (
                            let index = 0;
                            index < rows?.length;
                            index += 1
                          ) {
                            setValue(
                              `checkbox[${index}]`,
                              `${rows[index].companyId}::${rows[index].exhibitorId}::${rows[index].showId}`
                            )
                          }
                        } else {
                          for (
                            let index = 0;
                            index < rows?.length;
                            index += 1
                          ) {
                            setValue(`checkbox[${index}]`, false)
                          }
                        }
                        const watchedChecks = watch('checkbox')

                        setChecks?.(watchedChecks)
                        setHasChecks?.(
                          !watchedChecks.every((check: any) => check === false)
                        )
                      }}
                    />
                  </Th>
                )}

                {allowMultipleChecks && isPendingEbc && !allowClone && (
                  <Th borderTopLeftRadius="10px" borderBottomLeftRadius="10px">
                    <Checkbox
                      isChecked={allChecked}
                      isIndeterminate={isIndeterminate}
                      onChange={e => {
                        if (e.target.checked && rows?.length) {
                          for (
                            let index = 0;
                            index < rows?.length;
                            index += 1
                          ) {
                            setValue(
                              `checkbox[${index}]`,
                              `${rows[index].companyId}::${rows[index].boatProductId}`
                            )
                          }
                        } else {
                          for (
                            let index = 0;
                            index < rows?.length;
                            index += 1
                          ) {
                            setValue(`checkbox[${index}]`, false)
                          }
                        }
                        const watchedChecks = watch('checkbox')

                        setChecks?.(watchedChecks)
                        setHasChecks?.(
                          !watchedChecks.every((check: any) => check === false)
                        )
                      }}
                    />
                  </Th>
                )}

                {allowMultipleChecks && !isPendingEbc && allowClone && (
                  <Th borderTopLeftRadius="10px" borderBottomLeftRadius="10px">
                    <Checkbox
                      isChecked={allChecked}
                      isIndeterminate={isIndeterminate}
                      onChange={e => {
                        if (e.target.checked && rows?.length) {
                          for (
                            let index = 0;
                            index < rows?.length;
                            index += 1
                          ) {
                            setValue(
                              `checkbox[${index}]`,
                              `${rows[index].boatProductId}`
                            )
                          }
                        } else {
                          for (
                            let index = 0;
                            index < rows?.length;
                            index += 1
                          ) {
                            setValue(`checkbox[${index}]`, false)
                          }
                        }
                        const watchedChecks = watch('checkbox')

                        setChecks?.(watchedChecks)
                        setHasChecks?.(
                          !watchedChecks.every((check: any) => check === false)
                        )
                      }}
                    />
                  </Th>
                )}

                {columns?.length ? (
                  columns.map((c, columnIndex) => (
                    <Th
                      {...(!columnIndex &&
                        !allowMultipleChecks && {
                          borderTopLeftRadius: '10px',
                          borderBottomLeftRadius: '10px',
                        })}
                      textTransform="capitalize"
                      key={uuid()}
                      color="darkGray"
                      fontSize="13px"
                      fontWeight="600"
                      whiteSpace="nowrap"
                    >
                      {c}
                    </Th>
                  ))
                ) : (
                  <></>
                )}
                <Th
                  borderTopRightRadius="10px"
                  borderBottomRightRadius="10px"
                />
              </Tr>
            </Thead>
            <Tbody
              border="1px solid"
              borderColor="transparent"
              backgroundColor="transparent"
              borderRadius="10px"
            >
              <Tr height="30px" />
              {isLoading ? (
                Array.from({ length: 4 }).map((_, i) => (
                  <React.Fragment key={uuid()}>
                    <Tr
                      h="70px"
                      backgroundColor="white"
                      boxShadow="0px 4px 5px rgba(0, 0, 0, 0.05)"
                      borderRadius="10px"
                      color="darkGray"
                    >
                      <Td
                        borderTopLeftRadius="10px"
                        borderBottomLeftRadius="10px"
                      >
                        <Skeleton height="18px" maxW="18px" />
                      </Td>
                      {columns?.length ? (
                        columns?.map(_ => (
                          <Td key={uuid()}>
                            <Skeleton
                              height="18px"
                              maxW={sample(['100px', '80px', '50px'])}
                            />
                          </Td>
                        ))
                      ) : (
                        <></>
                      )}
                      {allowMultipleChecks && (
                        <Td
                          borderTopRightRadius="10px"
                          borderBottomRightRadius="10px"
                        >
                          <Box maxW="18px" />
                        </Td>
                      )}
                    </Tr>
                    {i < 4 && <Tr height="11px" />}
                  </React.Fragment>
                ))
              ) : (
                <></>
              )}

              {rows?.length ? (
                rows.map((r: any, i: number) => {
                  const registeredCheckbox = register(`checkbox[${i}]`)
                  return (
                    <React.Fragment key={r.id}>
                      <Tr
                        cursor="pointer"
                        className="mainRowElement"
                        onClick={(e: any) => {
                          e.stopPropagation()

                          if (
                            !e.target.classList.value.includes(
                              'chakra-checkbox'
                            ) &&
                            e.target.localName !== 'svg' &&
                            e.target.localName !== 'polyline'
                          ) {
                            onRowClick?.(r)
                          }
                        }}
                        h="70px"
                        backgroundColor="white"
                        boxShadow="0px 4px 5px rgba(0, 0, 0, 0.05)"
                        borderRadius="10px"
                        color="darkGray"
                      >
                        {allowMultipleChecks && !isPendingEbc && !allowClone && (
                          <Td
                            borderTopLeftRadius="10px"
                            borderBottomLeftRadius="10px"
                          >
                            <Checkbox
                              {...registeredCheckbox}
                              isChecked={watch(`checkbox[${i}]`)}
                              value={`${r.companyId}::${r.exhibitorId}::${r.showId}`}
                              onChange={e => {
                                registeredCheckbox.onChange(e)
                                const watchedChecks = watch('checkbox')

                                setChecks?.(watchedChecks)
                                setHasChecks?.(
                                  !watchedChecks.every(
                                    (check: any) => check === false
                                  )
                                )
                              }}
                            />
                          </Td>
                        )}

                        {allowMultipleChecks && isPendingEbc && !allowClone && (
                          <Td
                            borderTopLeftRadius="10px"
                            borderBottomLeftRadius="10px"
                          >
                            <Checkbox
                              {...registeredCheckbox}
                              isChecked={watch(`checkbox[${i}]`)}
                              value={`${r.companyId}::${r.boatProductId}`}
                              onChange={e => {
                                registeredCheckbox.onChange(e)
                                const watchedChecks = watch('checkbox')

                                setChecks?.(watchedChecks)
                                setHasChecks?.(
                                  !watchedChecks.every(
                                    (check: any) => check === false
                                  )
                                )
                              }}
                            />
                          </Td>
                        )}

                        {allowMultipleChecks && !isPendingEbc && allowClone && (
                          <Td
                            borderTopLeftRadius="10px"
                            borderBottomLeftRadius="10px"
                          >
                            <Checkbox
                              {...registeredCheckbox}
                              isChecked={watch(`checkbox[${i}]`)}
                              value={`${r.boatProductId}`}
                              onChange={e => {
                                registeredCheckbox.onChange(e)
                                const watchedChecks = watch('checkbox')

                                setChecks?.(watchedChecks)
                                setHasChecks?.(
                                  !watchedChecks.every(
                                    (check: any) => check === false
                                  )
                                )
                              }}
                            />
                          </Td>
                        )}

                        {columns?.length ? (
                          columns?.map((c, columnIndex) => {
                            const column = camelCase(c)
                            const isStatus = column
                              .toLowerCase()
                              .includes('status')
                            const id = uuid()

                            if (isStatus) {
                              return (
                                <Td
                                  {...(!columnIndex &&
                                    !allowMultipleChecks && {
                                      borderTopLeftRadius: '10px',
                                      borderBottomLeftRadius: '10px',
                                    })}
                                  key={id}
                                >
                                  <Status name={r.status as StatusEnum} />
                                </Td>
                              )
                            }

                            return (
                              <Td
                                {...(!columnIndex &&
                                  !allowMultipleChecks && {
                                    borderTopLeftRadius: '10px',
                                    borderBottomLeftRadius: '10px',
                                  })}
                                key={id}
                              >
                                <Box>
                                  {(() => {
                                    if (column === 'isEbc') {
                                      return (
                                        <Checkbox
                                          isChecked={r.enhancedBoatCard}
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                          }}
                                        />
                                      )
                                    }

                                    if (column === 'publishedUnpublished') {
                                      return (
                                        <Checkbox
                                          isChecked={r.published}
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                          }}
                                        />
                                      )
                                    }

                                    return !r[column] ? 'N/A' : r[column]
                                  })()}
                                </Box>
                              </Td>
                            )
                          })
                        ) : (
                          <></>
                        )}
                        <Td
                          borderTopRightRadius="10px"
                          borderBottomRightRadius="10px"
                          minW="80px"
                          key={`${r.id}-column`}
                        />
                      </Tr>
                      {i < rows.length - 1 && <Tr height="11px" />}
                    </React.Fragment>
                  )
                })
              ) : (
                <Tr key="spaced" />
              )}
            </Tbody>
          </StyledTable>

          <Box minWidth="49px" height="20px" />
        </StyledFlex>
        <Flex mx="49px" justifyContent="start">
          <Pagination
            mt="1.5rem"
            isLoadingData={isLoading}
            disablePrev={disablePrev}
            disableNext={disableNext}
            handleNext={handleNext}
            handlePrev={handlePrev}
          />
        </Flex>
      </Box>
    )
  }
)

export default ThemedTable
