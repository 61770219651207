/* eslint-disable consistent-return */
import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import Userpool from '../Userpool'

const useAuthentication = () => {
  const history = useHistory()
  const accessToken = localStorage.getItem('accessToken')

  const isAuthenticated = Boolean(accessToken)

  const authenticateUserSession = async () => {
    const cognitoUser = Userpool.getCurrentUser()

    if (cognitoUser) {
      return cognitoUser.getSession((err: any, session: any) => {
        if (err) {
          console.log(err.message)
          return
        }
        return session.isValid()
      })
    }
    return false
  }

  const logoutUser = async () => {
    const cognitoUser = Userpool.getCurrentUser()

    if (cognitoUser) {
      localStorage.removeItem('accessToken')
      cognitoUser.signOut()
      history.push('/login')
    }
  }

  const setAccessToken = (arg: any) => {
    localStorage.setItem('accessToken', arg)
  }

  const clearAuthentication = () => {
    localStorage.clear()
  }

  useEffect(() => {
    if (accessToken === 'true') {
      clearAuthentication()
    }
  }, [accessToken])

  return {
    isAuthenticated,
    setAccessToken,
    clearAuthentication,
    logoutUser,
    authenticateUserSession,
  }
}

export default useAuthentication
