/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect } from 'react'
// import reactCSS from 'reactcss'
import { SketchPicker } from 'react-color'
import { FiXCircle } from 'react-icons/fi'
import { Box } from '@chakra-ui/react'

interface ColorPickerProps {
  onChange: Function
  onRemove: Function
  defaultValue: String
  disabled?: Boolean
}

type ColorType = {
  r: number
  g: number
  b: number
  a: number
}

const ColorPicker = ({
  onChange,
  onRemove,
  defaultValue,
  disabled,
}: ColorPickerProps) => {
  const [displayColorPicker, setDisplayColorPicker] = useState(false)
  const [color, setColor] = useState<ColorType | undefined>(undefined)

  const handleClick = (e: any) => {
    e.stopPropagation()
    if (!disabled) {
      setDisplayColorPicker(!displayColorPicker)
    }
  }

  const handleClose = () => {
    setDisplayColorPicker(false)
  }

  const handleChange = (color: any) => {
    if (!disabled) {
      setColor(color.rgb)
      onChange(
        `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`
      )
    }
  }

  const handleRemove = () => {
    onRemove()
    setColor(undefined)
  }

  useEffect(() => {
    if (defaultValue) {
      const rgba = defaultValue
        .replace('rgba(', '')
        .replace(')', '')
        .split(', ')
      setColor({
        r: Number(rgba[0]),
        g: Number(rgba[1]),
        b: Number(rgba[2]),
        a: Number(rgba[3]),
      })
    }
    // eslint-disable-next-line react/destructuring-assignment
  }, [defaultValue])

  return (
    <Box position="relative" marginRight="20px">
      {color && !disabled && (
        <div
          style={{
            position: 'absolute',
            top: -10,
            right: -8,
            cursor: 'pointer',
            zIndex: 2,
            backgroundColor: 'red',
            width: 20,
            borderRadius: '50%',
          }}
          onClick={handleRemove}
        >
          <FiXCircle color="white" fontSize={20} />
        </div>
      )}
      <div
        style={{
          padding: '25px',
          backgroundColor: !color
            ? '#fff'
            : `rgba(${color!.r}, ${color!.g}, ${color!.b}, ${color!.a})`,
          borderRadius: '1px',
          boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
          display: 'inline-block',
          cursor: 'pointer',
          position: 'relative',
        }}
        onClick={handleClick}
      >
        <div />
      </div>
      {displayColorPicker ? (
        <div
          style={{
            position: 'absolute',
            zIndex: 2,
          }}
        >
          <div
            style={{
              position: 'fixed',
              top: '-100px',
              right: '0px',
              bottom: '0px',
              left: '0px',
            }}
            onClick={handleClose}
          />
          <SketchPicker color={color} onChange={handleChange} />
        </div>
      ) : null}
    </Box>
  )
}

export default ColorPicker
