import {
  Flex,
  Box,
  Text,
  useDisclosure,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  FlexProps,
} from '@chakra-ui/react'
import styled from '@emotion/styled'
import { useAtom } from 'jotai'
import { isEqual } from 'lodash'
import { useEffect, useState } from 'react'
import { FiPlus } from 'react-icons/fi'
import uuid from 'react-uuid'
import { useLocation } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import useSWR from 'swr'

import { companyRecordAtom } from '../../atoms'
import { CreateExhibitorBoatProductsForm } from '../../components/partials'
import {
  ThemedButton,
  ThemedSelect,
  ThemedTable,
} from '../../components/shared'
import { fetcher } from '../../fetchers'
import { useAuthentication, useExhibitors } from '../../hooks'
import {
  AWSQueryOutput,
  CompanyTypeEnum,
  ExhibitorBoatProduct,
  QueryVariables,
  StatusEnum,
  ThemedSelectItem,
} from '../../interfaces'
import ThemedFormHelperMessage from '../../components/shared/ThemedFormHelperMessage'

const filters = {
  status: [
    { value: 'All', label: 'All' },
    { value: StatusEnum.APPROVED, label: StatusEnum.APPROVED },
    { value: StatusEnum.PENDING, label: StatusEnum.PENDING },
    { value: StatusEnum.REJECTED, label: StatusEnum.REJECTED },
  ],
}

const StyledThemedTable = styled(ThemedTable)`
  & {
    width: ${({ isLoading }) =>
      isLoading ? 'calc(100%)' : 'calc(100% - 200px)'};

    @media (min-width: 1500px) {
      width: calc(100%);
    }
  }
`

const StyledFlex: React.FC<FlexProps & { isFooter?: boolean }> = ({
  children,
  ...props
}) => <Flex {...props}>{children}</Flex>

const StyledEBPFlex = styled(StyledFlex)`
  & {
    width: ${({ isFooter }) =>
      isFooter
        ? 'calc(calc(100vw - 245px) - 292px)'
        : 'calc(calc(100vw - 245px) - 302px)'};

    @media (min-width: 1500px) {
      width: ${({ isFooter }) =>
        isFooter
          ? 'calc(calc(100vw - 245px) - 292px)'
          : 'calc(calc(100vw - 245px) - 322px)'};
    }
  }
`

const defaultColumns = [
  'Exhibitor (Show)',
  'Approval Status',
  'Boat Product',
  'Booth',
  'Exhibitor ID',
]

const ExhibitorBoatProducts: React.FC = () => {
  const { isAuthenticated } = useAuthentication()
  const [companyRecord] = useAtom(companyRecordAtom)
  const { exhibitors } = useExhibitors()
  const [columns, setColumns] = useState<string[]>(defaultColumns)
  const [defaultValues, setDefaultValues] = useState<Record<string, any>>({})
  const location = useLocation()
  const locationState = location?.state as any
  const { register, setValue } = useForm()
  const [statusKey, setStatusKey] = useState('')
  const [filter, setFilter] = useState<Record<string, any>>({})
  const [variables, setVariables] = useState<QueryVariables>({
    api: '',
    first: 10,
    after: null,
    savedCursor: [null],
    currentPage: 0,
  })

  const {
    data: res,
    mutate,
    error,
  } = useSWR(
    [
      `/company/${
        companyRecord.record?.companyId
      }/exhibitor-boat-products?first=${variables.first}${
        variables.after ? `&after=${encodeURIComponent(variables.after)}` : ''
      }`,
      filter,
    ],
    fetcher
  )

  const isLoading = !error && !res

  const records = (res?.data as AWSQueryOutput)?.Items?.map(i => {
    const c = i as ExhibitorBoatProduct

    return {
      ...c,
      id: c.exhibitorBoatProductId,
      exhibitorId: c?.exhibitor?.exhibitorId,
      exhibitorShow: `${c?.exhibitor?.bannerName ?? ''}${
        c?.show?.showName ? ` (${c?.show?.showName})` : ''
      }`,
      boatProduct: c.boatProduct?.productName,
      boatProductId: c.boatProduct?.boatProductId,
      showCode: c?.show?.producerEventCode,
      brand: c?.brands?.reduce(
        (acc: any, { name }: any, index) =>
          `${acc}${name}${index === (c?.brands?.length || 0) - 1 ? '' : ', '}`,
        ''
      ),
      booth: c.mapsBooths?.reduce((acc: string, curr: any, i: number) => {
        const [, mapId, boothId] = curr.value.split('::')
        const map = c?.show?.maps?.find((m: any) => m.id === mapId) || []
        const booth = map?.booths?.find((b: any) => b.id === boothId)

        return `${acc}${booth?.name ? `${map.name} - ${booth.name}` : ''}${
          i < (c.mapsBooths?.length || 0) - 1 ? ',' : ''
        } `
      }, ''),
      approvalStatus: c.status,
    }
  })

  useEffect(() => {
    if (companyRecord.record?.type) {
      setColumns(
        companyRecord.record.type === CompanyTypeEnum.BRAND
          ? defaultColumns
          : [
              ...defaultColumns.slice(0, 4),
              'Brand',
              ...defaultColumns.slice(-2),
            ]
      )
    }
  }, [companyRecord.record?.type])

  const { isOpen, onOpen, onClose: onModalClose } = useDisclosure()

  const onClose = () => {
    onModalClose()
    setDefaultValues({})
  }

  const onRowClick = (row: any) => {
    const record = (res?.data as AWSQueryOutput)?.Items?.find(
      c =>
        c.exhibitorBoatProductId === row.id &&
        c.boatProduct.boatProductId === row.boatProductId
    )

    setDefaultValues({
      ...record,
      exhibitor: {
        label: `${record?.exhibitor?.bannerName ?? ''}${
          record?.show.showName ? ` (${record?.show.showName})` : ''
        }`,
        value: `${record?.exhibitor?.exhibitorId}~${record?.show?.showId}`,
      },
      status: { label: record?.status, value: record?.status },
      boatProduct: [
        {
          label: record?.boatProduct.productName,
          value: record?.boatProduct.boatProductId,
        },
      ],
      ...(record?.brands?.length && {
        brandIds: record.brands.map(({ name, companyId }: any) => ({
          label: name,
          value: companyId,
        })),
      }),
      // ...(companyRecord.record?.type === CompanyTypeEnum.BRAND && {
      //   brandIds: [
      //     {
      //       label: companyRecord.record.name,
      //       value: companyRecord.record.companyId,
      //     },
      //   ],
      // }),
      mapsBooths: record?.mapsBooths?.map((b: any) => {
        const [, mapId, boothId] = b.value.split('::')
        const map = record?.show?.maps?.find((m: any) => m.id === mapId) || []
        const booth = map?.booths?.find((b: any) => b.id === boothId)

        if (b.value === '-') {
          return {
            label: 'No Booth - Not at Show',
            value: '-',
          }
        }

        return {
          ...b,
          label: booth?.name ? `${map.name} - ${booth.name}` : '',
        }
      }),
    })
    onOpen()
  }

  const handleNext = () => {
    if (res?.data?.LastEvaluatedKey) {
      setVariables(v => ({
        ...v,
        after: `${res?.data?.LastEvaluatedKey?.companyId}~${res?.data?.LastEvaluatedKey?.exhibitorBoatProductId}`,
        savedCursor: [
          ...v.savedCursor,
          `${res?.data?.LastEvaluatedKey?.companyId}~${res?.data?.LastEvaluatedKey?.exhibitorBoatProductId}`,
        ],
        currentPage: v.currentPage! + 1,
      }))
    }
  }

  const handlePrev = () => {
    setVariables(v => ({
      ...v,
      after: v.savedCursor[v.currentPage! - 1],
      currentPage: v.currentPage! - 1,
    }))
  }

  useEffect(() => {
    if (locationState?.pending) {
      setValue('status', filters.status[2])
      setFilter({ status: StatusEnum.PENDING })
      setStatusKey(uuid())
    }
  }, [locationState])

  const statusField = register('status')

  return (
    <Box pb="171px" position="relative">
      <StyledEBPFlex justifyContent="space-between" mt="54px" ml="51px">
        <Box>
          <Text as="span" fontSize="32px" fontWeight="600">
            My Boats
          </Text>
          <Text
            as="span"
            display="block"
            fontSize="13px"
            color="darkGray"
            maxW={isAuthenticated ? '60ch' : '80ch'}
          >
            {isAuthenticated
              ? 'Please assign the boats that you will be bringing to the show. Click on the + Create button to start assigning Boats to Exhibit spaces'
              : 'When at least one exhibitor assigns themselves to a boat model your manufacturer, your entire fleet of boats will be available on the default view of the SRP.'}
          </Text>
        </Box>
        <Box position="relative">
          {!exhibitors.length && (
            <Box
              position="absolute"
              right="0px"
              height="55px"
              width="158px"
              zIndex="2"
            >
              <Popover trigger="hover" flip>
                <PopoverTrigger>
                  <Box
                    position="relative"
                    right="0px"
                    height="55px"
                    width="158px"
                    cursor="not-allowed"
                  />
                </PopoverTrigger>
                <PopoverContent>
                  <PopoverHeader fontWeight="semibold">
                    No exhibitors found.
                  </PopoverHeader>
                  <PopoverArrow />
                  <PopoverBody>
                    <Text as="span" fontSize="sm">
                      You have to create first an exhibitor before you can
                      proceed in creating an exhibitor boat product.
                    </Text>
                  </PopoverBody>
                </PopoverContent>
              </Popover>
            </Box>
          )}
          <ThemedButton
            type="button"
            height="55px"
            width="158px"
            onClick={onOpen}
            leftIcon={<FiPlus color="white" fontSize="22px" />}
            isDisabled={isLoading || !exhibitors.length}
          >
            <Text as="span" ml="8px">
              Create
            </Text>
          </ThemedButton>
        </Box>
      </StyledEBPFlex>
      <Box ml="51px" mt="47px" maxW="250px">
        <Text
          as="span"
          display="block"
          fontSize="13px"
          color="darkGray"
          mb="4px"
        >
          Status
        </Text>

        <ThemedSelect
          id="status"
          options={filters.status}
          isSearchable={false}
          defaultValue={
            locationState?.pending ? filters.status[2] : filters.status[0]
          }
          {...statusField}
          key={statusKey}
          maxWidthOptions={236}
          hasShadow
          color="#718096"
          onChange={(e: ThemedSelectItem) => {
            if (!isEqual(filter, { ...filter, boatType: e.value })) {
              setVariables(v => ({
                ...v,
                savedCursor: [null],
                currentPage: 0,
                after: null,
              }))
              if (e.value === 'All') {
                setFilter({})
              } else {
                setFilter(prev => ({
                  ...prev,
                  status: e.value,
                }))
              }
            }
          }}
        />
      </Box>
      <StyledThemedTable
        columns={columns}
        rows={records}
        mt="24px"
        isLoading={isLoading}
        onRowClick={onRowClick}
        disableNext={!res?.data?.hasNextPage}
        disablePrev={Number(variables.currentPage) + 1 === 1}
        handleNext={handleNext}
        handlePrev={handlePrev}
      />
      <StyledEBPFlex
        justifyContent="end"
        ml="51px"
        mb="20px"
        position="absolute"
        bottom="0px"
        isFooter
      >
        <ThemedFormHelperMessage>
          For questions, please contact showservice@nmma.org
        </ThemedFormHelperMessage>
      </StyledEBPFlex>
      <CreateExhibitorBoatProductsForm
        isOpen={isOpen}
        onClose={onClose}
        mutate={mutate}
        companyId={companyRecord.record?.companyId}
        defaultValues={defaultValues}
      />
    </Box>
  )
}

export default ExhibitorBoatProducts
