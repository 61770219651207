import { Box, Flex } from '@chakra-ui/react'
import { useLocation } from 'react-router-dom'
import { Sidebar, Topbar } from '../components/shared'
import ThemedFormHelperMessage from '../components/shared/ThemedFormHelperMessage'

type DefaultLayoutProps = {
  noXPadding?: boolean
  noYPadding?: boolean
}

const DefaultLayout: React.FC<DefaultLayoutProps> = ({
  children,
  noXPadding,
  noYPadding,
}) => {
  const location = useLocation()
  const split = location.pathname.split('/')
  const isBasePage = split?.length <= 2
  return (
    <Flex overflow="hidden">
      <Sidebar />
      <Box w="calc(100vw - 245px)" position="relative">
        <Topbar />
        <Box
          minH="calc(100vh - 80px)"
          maxH="calc(100vh - 80px)"
          overflow="hidden auto"
          backgroundColor="lightGray"
          padding={`${noYPadding ? '0px' : '67px'} ${
            noXPadding ? '0px' : '49px'
          }`}
          paddingBottom="0px"
        >
          {children}
          {isBasePage && (
            <>
              <Box minH="50px" height="calc(30vh)" />
              <Flex justifyContent="end" mr="20px" mb="20px">
                <ThemedFormHelperMessage>
                  For questions, please contact showservice@nmma.org
                </ThemedFormHelperMessage>
              </Flex>
            </>
          )}
        </Box>
      </Box>
    </Flex>
  )
}

export default DefaultLayout
