import { useAtom } from 'jotai'
import { useCallback } from 'react'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { companyRecordAtom, contactsAtom } from '../atoms'
import { fetcher } from '../fetchers'
import { Contact } from '../interfaces'

const useContacts = () => {
  const [contacts, setContacts] = useAtom(contactsAtom)
  const [companyRecord] = useAtom(companyRecordAtom)

  const fetchContacts = useCallback(async () => {
    if (companyRecord.record?.companyId) {
      const res = await fetcher(
        `/company/${companyRecord.record?.companyId}/contacts`
      )
      if (res.data) {
        const contactsFromResponse = res.data?.Items?.reduce(
          (acc: any, curr: Contact) => {
            const t = {
              label: `${curr.firstName} ${curr.lastName}`,
              value: curr.contactId,
            }
            return [...acc, t]
          },
          [] as { label: string; value: string }[]
        ).sort((curr: any, next: any) => (curr.label < next.label ? -1 : 1))

        const contactsItems = [
          { label: '-', value: '' },
          ...contactsFromResponse,
        ]

        setContacts(contactsItems)
      }
    }
  }, [contacts.length, companyRecord.record?.companyId])

  useDeepCompareEffect(() => {
    fetchContacts()
  }, [contacts, companyRecord.record?.companyId])

  return { contacts, setContacts }
}

export default useContacts
