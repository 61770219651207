import {
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { useMemo, useRef, useState } from 'react'
import { FiPlus, FiSearch, FiX } from 'react-icons/fi'
import uuid from 'react-uuid'
import { useHistory } from 'react-router-dom'
import { useAtom } from 'jotai'
import useSWR from 'swr'
import { debounce } from 'lodash'

import { CreateCompanyForm } from '../../components/partials'
import { ThemedButton, ThemedTable } from '../../components/shared'
import { fetcher } from '../../fetchers'
import { QueryVariables, Company } from '../../interfaces'
import DefaultLayout from '../../layouts/DefaultLayout'
import { yesOrNo } from '../../utils'
import { companyRecordAtom } from '../../atoms'

const columns = [
  'ID',
  'Source Code',
  'Name',
  'Address',
  'Phone',
  'Website',
  'Facebook',
  'Linkedin',
  'Youtube',
  'Twitter',
  'Company type',
  'NMMA Membership Status',
  'NMMA Division',
  'Is NMMA Certified',
  'Is MRAA Dealer Certified',
]

const CompanyPage: React.FC = () => {
  const [, setCompanyRecord] = useAtom(companyRecordAtom)
  const history = useHistory()

  const [keyword, setKeyword] = useState('')
  const ref = useRef<HTMLInputElement>(null)

  const [variables, setVariables] = useState<QueryVariables>({
    api: '',
    first: 25,
    after: null,
    savedCursor: [null],
    currentPage: 0,
  })

  const {
    data: res,
    mutate,
    error,
  } = useSWR(
    `/company${keyword ? '/search' : '/list'}?first=${variables.first}${
      variables.after ? `&after=${encodeURIComponent(variables.after)}` : ''
    }${keyword ? `&keyword=${encodeURIComponent(keyword)}` : ''}`,
    fetcher
  )

  const isLoading = !error && !res
  const records = res?.data?.Items?.map((d: Company) => {
    const {
      companyId,
      sourceCompanyCode,
      type,
      nmmaCertified,
      mraaDealerCertified,
      ...rest
    } = d
    return {
      id: companyId,
      sourceCode: sourceCompanyCode,
      companyType: type,
      isNmmaCertified: yesOrNo(nmmaCertified),
      isMraaDealerCertified: yesOrNo(mraaDealerCertified),
      ...rest,
    }
  })

  const [key, setKey] = useState('')

  const { isOpen, onOpen, onClose: onModalClose } = useDisclosure()

  const onClose = () => {
    onModalClose()
    setTimeout(() => {
      setKey(uuid())
    }, 300)
  }

  const onRowClick = (row: any) => {
    setCompanyRecord({ isLoading: true })
    history.push(`/company/${row?.id}${row?.token ? `?t=${row?.token}` : ''}`)
  }

  const changeHandler = (event: any) => {
    setKeyword(event.target.value)
    setVariables(v => ({
      ...v,
      currentPage: 0,
      after: event.target.value ? '0' : null,
      savedCursor: [null],
    }))
  }

  const debouncedChangeHandler = useMemo(() => debounce(changeHandler, 300), [])

  const handleNext = () => {
    if (res?.data?.LastEvaluatedKey) {
      setVariables(v => ({
        ...v,
        after: `${res?.data?.LastEvaluatedKey?.companyId}::${res?.data?.LastEvaluatedKey?.name}`,
        savedCursor: [
          ...v.savedCursor,
          `${res?.data?.LastEvaluatedKey?.companyId}::${res?.data?.LastEvaluatedKey?.name}`,
        ],
        currentPage: v.currentPage! + 1,
      }))
    } else if (keyword) {
      setVariables(v => ({
        ...v,
        after: `${v.currentPage! + 1}`,
        savedCursor: [...v.savedCursor, `${v.currentPage! + 1}`],
        currentPage: v.currentPage! + 1,
      }))
    }
  }

  const handlePrev = () => {
    setVariables(v => ({
      ...v,
      after: v.savedCursor[v.currentPage! - 1],
      currentPage: v.currentPage! - 1,
    }))
  }

  return (
    <>
      <DefaultLayout noXPadding>
        <Flex justifyContent="space-between" alignItems="center" px="49px">
          <Text as="span" fontSize="32px" fontWeight="600">
            Company
          </Text>
          <ThemedButton
            type="button"
            height="55px"
            width="158px"
            onClick={onOpen}
            leftIcon={<FiPlus color="white" fontSize="22px" />}
          >
            <Text as="span" ml="8px">
              Create
            </Text>
          </ThemedButton>
        </Flex>
        <InputGroup size="md" maxW="sm" mx="49px" mt="20px">
          <InputLeftElement pointerEvents="none" color="gray.500">
            <FiSearch />
          </InputLeftElement>
          <Input
            type="text"
            placeholder="Search"
            backgroundColor="white"
            onChange={debouncedChangeHandler}
            ref={ref}
          />
          {keyword && (
            <InputRightElement
              color="gray.500"
              cursor="pointer"
              onClick={() => {
                setKeyword('')
                ref.current!.value = ''
                setVariables(v => ({
                  ...v,
                  first: 25,
                  after: null,
                  savedCursor: [null],
                  currentPage: 0,
                }))
              }}
            >
              <FiX />
            </InputRightElement>
          )}
        </InputGroup>

        <ThemedTable
          columns={columns}
          rows={records}
          mt="60px"
          isLoading={isLoading}
          onRowClick={onRowClick}
          disableNext={!res?.data?.hasNextPage}
          disablePrev={Number(variables.currentPage) + 1 === 1}
          handleNext={handleNext}
          handlePrev={handlePrev}
        />
      </DefaultLayout>
      <CreateCompanyForm
        key={key}
        isOpen={isOpen}
        onClose={onClose}
        mutate={mutate as any}
      />
    </>
  )
}
export default CompanyPage
