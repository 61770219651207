import axios from 'axios'
import { BASE_URL } from '../config'
import { QueryVariables } from '../interfaces'

export const getKey =
  ({ api, first, after, filter }: QueryVariables) =>
  (pageIndex: number, previousPageData: any) => {
    if (previousPageData && !previousPageData.data) {
      return null
    }

    if (pageIndex === 0) {
      return [api, first, filter]
    }

    // add the cursor to the API endpoint
    return [api, first, filter, after]
  }

export const fetcher = (query: string, filter?: any) => {
  const adminToken = localStorage.getItem('accessToken')
  const urlParams = new URLSearchParams(window.location.search)
  const companyToken = urlParams.get('t')
  const companyId = companyToken && window.location.pathname.split('/')[2]

  const axiosInstance = axios.create({
    baseURL: BASE_URL,
    headers: {
      'Cache-Control': 'no-cache',
      Authorization: adminToken ? `Bearer ${adminToken}` : '',
      'x-company-token': companyToken ? `${companyId}:${companyToken}` : '',
    },
  })

  return axiosInstance
    .get(query, {
      params: { ...filter },
    })
    .catch(err => {
      if (err.response.status === 401) {
        window.location.href = `${
          window?.location ? `${window.location.protocol}//` : ''
        }${window?.location ? window.location.host : ''}/login`
      }

      return err
    })
}

export const generalFetcher = (
  query: string,
  first: number,
  filter: Record<string, any>,
  after: string | null
) => {
  const axiosInstance = axios.create({
    baseURL: BASE_URL,
    headers: {
      'Cache-Control': 'no-cache',
    },
  })

  return axiosInstance.get(query, {
    params: {
      first,
      after,
      filter,
    },
  })
}

export const httpPost = async ({
  url,
  body,
}: {
  url: string
  body?: object
}) => {
  const adminToken = localStorage.getItem('accessToken')
  const urlParams = new URLSearchParams(window.location.search)
  const companyToken = urlParams.get('t')
  const companyId = companyToken && window.location.pathname.split('/')[2]

  const axiostPostInstance = axios.create({
    baseURL: BASE_URL,
    headers: {
      'Cache-Control': 'no-cache',
      Authorization: adminToken ? `Bearer ${adminToken}` : '',
      'x-company-token': companyToken ? `${companyId}:${companyToken}` : '',
    },
  })

  return axiostPostInstance.post(url, body).catch(err => {
    if (err.response.status === 401) {
      window.location.href = `${
        window?.location ? `${window.location.protocol}//` : ''
      }${window?.location ? window.location.host : ''}/login`
    }

    return err
  })
}
