import {
  Box,
  Text,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalCloseButton,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Input,
  Button,
  useDisclosure,
  Flex,
  useToast,
} from '@chakra-ui/react'
import ReactCrop, { Crop } from 'react-image-crop'
import styled from '@emotion/styled'
import { useEffect, useState } from 'react'
import { FiCheck, FiPlus, FiTrash, FiX } from 'react-icons/fi'
import { useDropzone } from 'react-dropzone'
import { useFieldArray, useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import uuid from 'react-uuid'
import 'react-image-crop/dist/ReactCrop.css'

import EmptyRecords from '../../shared/EmptyRecords'
import { ThemedButton, ThemedFormErrorMessage, ThemedLabel } from '../../shared'
import { BASE_CLOUDFRONT_IMAGE_RESIZER_URL } from '../../../config'

const StyledReactCrop = styled(ReactCrop)`
  width: 690px;

  & > div.ReactCrop__crop-selection {
    border: 2px solid white !important;
  }

  & > div > img {
    height: 392px;
    width: 690px;
  }
`
type CustomFile = File & { preview: string; fileNameHashed?: string }
type Files = CustomFile[]

const EmptyFiles: React.FC<{ isDragActive: boolean; open: () => void }> = ({
  isDragActive,
  open,
}) => (
  <>
    {isDragActive ? (
      <Text as="span" color="darkGray" fontSize="14px" fontWeight="normal">
        Drop the files here...
      </Text>
    ) : (
      <>
        <Text as="span" color="darkGray" fontSize="14px" fontWeight="normal">
          Drag files here or
        </Text>
        <ThemedButton
          w="97px"
          h="30px"
          borderRadius="25px"
          fontSize="14px"
          fontWeight="normal"
          ml="8px"
          onClick={open}
        >
          Add file
        </ThemedButton>
      </>
    )}
  </>
)

const MapUploader: React.FC<{
  files: Files
  setFiles: (arg: any) => void
  setHasChanged: (arg: any) => void
}> = ({ files, setFiles, setHasChanged }) => {
  const [isRejected, setRejected] = useState(false)

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop: (acceptedFiles: File[]) => {
      setRejected(false)
      const newFiles = [
        ...files,
        ...acceptedFiles.map(file => {
          Object.defineProperty(file, 'preview', {
            value: URL.createObjectURL(file),
            writable: false,
          })
          return file
        }),
      ]

      setFiles(newFiles as Files)
      setHasChanged(true)
    },
    onDropRejected: () => {
      setRejected(true)
    },
    noKeyboard: true,
    noClick: true,
    maxSize: 32000000,
    accept: 'image/jpg, image/jpeg, image/png',
    multiple: false,
  })

  return (
    <Box>
      <Flex
        backgroundColor={isDragActive ? 'gray.100' : 'lightGray'}
        border="1px dashed"
        borderColor="mediumGray"
        minHeight="352px"
        borderRadius="10px"
        justifyContent="center"
        alignItems="center"
        {...(files.length && { py: '10px' })}
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        {!files.length && (
          <EmptyFiles isDragActive={isDragActive} open={open} />
        )}
      </Flex>
      <Text
        as="span"
        display="block"
        fontWeight="400"
        color="darkGray"
        fontSize="11px"
        mt="10px"
      >
        File Format: JPG or PNG. Maximum upload file size: 32MB
      </Text>
      {!!isRejected && (
        <Text
          as="span"
          display="block"
          fontWeight="400"
          color="red.600"
          fontSize="11px"
          mt="5px"
        >
          Your file exceeds the maximum upload file size.
        </Text>
      )}
    </Box>
  )
}

type MapFormProps = {
  maps: any
  setMaps: (arg: any) => void
  onClose: () => void
  mode: 'CREATE' | 'UPDATE'
  selectedMap?: any
}

const defaultCrop: Partial<Crop> = {
  x: -1,
  y: -1,
  unit: 'px',
}

const schema = yup.object().shape({
  name: yup.string().required('Required'),
  booths: yup.array().of(
    yup.object().shape({
      name: yup.string(),
      coordinates: yup.object().shape({}),
      hasCoordinatesChanged: yup.bool(),
    })
  ),
})

const MapForm: React.FC<MapFormProps> = ({
  onClose,
  setMaps,
  maps,
  mode,
  selectedMap,
}) => {
  const [files, setFiles] = useState<Files>([])
  const [focusedIndex, setFocusedIndex] = useState(0)
  const toast = useToast()

  const [crop, setCrop] = useState<Partial<Crop>>(defaultCrop)

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    clearErrors,
    control,
    watch,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  })

  const [hasChanged, setHasChanged] = useState(false)

  const onChange = (crop: Crop) => {
    setValue(`booths.${focusedIndex}.coordinates`, crop)
    if (!watch(`booths.${focusedIndex}.hasCoordinatesChanged`)) {
      setValue(`booths.${focusedIndex}.hasCoordinatesChanged`, true)
    }

    clearErrors('booths')
    setCrop(crop)
  }

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'booths',
  })

  const onSubmit = () => {
    if (
      maps?.find((map: any) => map.name === watch('name')) &&
      mode === 'CREATE'
    ) {
      return setError('name', {
        message: 'A map with the same name already exists.',
      })
    }

    const savedMap = {
      ...(mode === 'UPDATE' && {
        ...selectedMap,
        id: selectedMap?.id,
        ...(!selectedMap.mapFileNameHashed
          ? {
              ...(hasChanged
                ? { image: files[0] }
                : { image: selectedMap.image }),
            }
          : {
              ...(hasChanged ? { image: files[0] } : { image: undefined }),
            }),
        ...(selectedMap.mapFileNameHashed &&
          !selectedMap.previousImage && {
            previousImage: true,
          }),
      }),
      ...(mode === 'CREATE' && {
        id: uuid(),
        image: files[0],
      }),
      name: watch('name'),
      booths: watch('booths').filter((b: any) => b.name),
    }

    setMaps((existingMaps: any[]) => {
      if (mode === 'CREATE') {
        return existingMaps ? [...existingMaps, savedMap] : [savedMap]
      }
      if (mode === 'UPDATE' && selectedMap.name !== watch('name')) {
        return [
          ...existingMaps.filter(m => m.name !== selectedMap.name),
          savedMap,
        ]
      }
      const updatedMaps = existingMaps
      const index = updatedMaps.findIndex(m => m.name === selectedMap.name)
      updatedMaps[index] = savedMap

      return updatedMaps
    })

    toast({
      title: `Map has been ${mode.toLowerCase()}d.`,
      status: 'success',
      duration: 1500,
      isClosable: true,
    })

    setHasChanged(false)

    return onClose()
  }

  const registeredMapName = register('name')

  useEffect(() => {
    if (mode === 'CREATE') {
      append({ name: '' })
    } else {
      const { image, name, booths, mapFileName, mapFileNameHashed } =
        selectedMap

      setValue('name', name)
      setValue('booths', booths)

      const fileName = image?.name ?? mapFileName
      const file = new File([''], fileName, {
        type:
          fileName?.split('.').pop() === 'jpg' ||
          fileName?.split('.').pop() === 'jpeg'
            ? 'image/jpg'
            : 'image/png',
      })

      Object.defineProperty(file, 'preview', {
        value:
          image?.preview ??
          `${BASE_CLOUDFRONT_IMAGE_RESIZER_URL}/fit-in/690x392/${mapFileNameHashed}`,
        writable: false,
      })

      setFiles([file as CustomFile])

      setFocusedIndex(0)
      if (booths?.length) {
        setCrop(booths[0].coordinates)
      }
    }
  }, [])

  return (
    <Box maxW="6xl" mx="auto" mt="20px" pb="40px">
      <Button size="sm" onClick={onClose}>
        Back
      </Button>
      <form
        onSubmit={e => {
          e.preventDefault()
          e.stopPropagation()
          handleSubmit(onSubmit)(e)
        }}
      >
        <Flex
          mt="40px"
          mb="40px"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box>
            <Text as="span" fontSize="2xl" fontWeight="500" display="block">
              {`${mode === 'CREATE' ? 'Create Map' : selectedMap.name}`}
            </Text>
            <ThemedLabel as="span" fontWeight="400">
              Upload a map image and provide the map name. Select a booth and
              start drawing its location on the map.
            </ThemedLabel>
          </Box>
          <Flex>
            {mode === 'UPDATE' && (
              <Button
                variant="outline"
                size="md"
                color="darkGray"
                leftIcon={<FiTrash />}
                onClick={() => {
                  setMaps((existingMaps: any[]) =>
                    existingMaps.filter(m => m.id !== selectedMap.id)
                  )
                  toast({
                    title: `Map has been deleted.`,
                    status: 'success',
                    duration: 1500,
                    isClosable: true,
                  })
                  onClose()
                }}
                mr="8px"
                type="button"
              >
                Delete
              </Button>
            )}
            <Button
              variant="outline"
              size="md"
              color="darkGray"
              leftIcon={<FiCheck />}
              isDisabled={!files.length}
              mr="8px"
              type="submit"
            >
              Submit
            </Button>
          </Flex>
        </Flex>
        <Flex>
          <Box width="690px" flex="none">
            <Flex justifyContent="space-between" alignItems="center">
              <ThemedLabel>Map Image</ThemedLabel>
              {!!files.length && (
                <ThemedLabel
                  fontWeight="400"
                  color="red.400"
                  cursor="pointer"
                  _hover={{
                    textDecoration: 'underline',
                  }}
                  onClick={() => {
                    setFiles([])
                  }}
                >
                  Remove Image
                </ThemedLabel>
              )}
            </Flex>

            {!files.length && (
              <MapUploader
                files={files}
                setFiles={setFiles}
                setHasChanged={setHasChanged}
              />
            )}
            {!!files.length && (
              <Box position="relative" height="394px" width="692px">
                <StyledReactCrop
                  src={files[0].preview}
                  crop={crop ?? defaultCrop}
                  onChange={onChange}
                  imageAlt="Show floor map"
                  {...(crop?.x !== -1 && { keepSelection: true })}
                />
                {crop?.x! >= 0 && (
                  <Box
                    position="absolute"
                    top="8px"
                    right="8px"
                    color="gray.300"
                    cursor="pointer"
                    onClick={() => {
                      setCrop(defaultCrop)
                      setValue(
                        `booths.${focusedIndex}.coordinates`,
                        defaultCrop
                      )
                    }}
                  >
                    <FiX fontSize="24px" />
                  </Box>
                )}
              </Box>
            )}
          </Box>
          <Box ml="20px" w="450px">
            <ThemedLabel>Map Name</ThemedLabel>
            <Input
              type="text"
              {...registeredMapName}
              onChange={() => {
                clearErrors('name')
              }}
            />
            <ThemedFormErrorMessage>
              {errors.name?.message}
            </ThemedFormErrorMessage>
            <Box mt="40px">
              <ThemedLabel>Booths</ThemedLabel>
              <ThemedLabel as="span" fontWeight="400" mb="20px">
                Only booths with both name and coordinates values are saved.
              </ThemedLabel>
              {fields.map((f, i) => (
                <Box key={f.id}>
                  <Controller
                    control={control}
                    name={`booths.${i}.name`}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Box>
                        <Flex alignItems="center">
                          <Input
                            onChange={e => {
                              onChange(e)
                              clearErrors('booths')
                            }}
                            onBlur={onBlur}
                            value={value}
                            mb="6px"
                            onFocus={() => {
                              setFocusedIndex(i)
                              setCrop(
                                watch(`booths.${i}.coordinates`) || defaultCrop
                              )
                            }}
                            {...(focusedIndex === i &&
                              files?.length &&
                              watch('name') && {
                                borderColor: 'blue.500',
                                _hover: {
                                  borderColor: 'blue.500',
                                },
                              })}
                            {...(fields.length > 1 && { w: '92%' })}
                          />
                          {fields.length > 1 && (
                            <Box position="relative">
                              <Button
                                onClick={() => {
                                  setFocusedIndex(i ? i - 1 : i)

                                  setCrop(
                                    watch(
                                      `booths.${i ? i - 1 : i}.coordinates`
                                    ) || defaultCrop
                                  )
                                  remove(i)
                                }}
                                ml="10px"
                                size="sm"
                                color="darkGray"
                                variant="outline"
                              >
                                <FiTrash />
                              </Button>
                            </Box>
                          )}
                        </Flex>
                        <ThemedFormErrorMessage>
                          {/* {errors.booths?.[i]?.name?.message} */}
                        </ThemedFormErrorMessage>
                      </Box>
                    )}
                  />
                  <Controller
                    control={control}
                    name={`booths.${i}.coordinates`}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Input
                        type="hidden"
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name={`booths.${i}.hasCoordinatesChanged`}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Input
                        type="hidden"
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name={`booths.${i}.id`}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Input
                        type="hidden"
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                      />
                    )}
                  />
                </Box>
              ))}
              <ThemedFormErrorMessage
                {...(errors.booths?.message && { mb: '4px' })}
              >
                {errors.booths?.message}
              </ThemedFormErrorMessage>
              <Flex mb="10px">
                <Button
                  onClick={() => {
                    append({ name: '' })
                    setCrop(defaultCrop)
                    setFocusedIndex(fields.length)
                  }}
                  variant="outline"
                  size="sm"
                  color="darkGray"
                  fontSize="13px"
                  leftIcon={<FiPlus />}
                  isDisabled={!watch('name') || !files.length}
                  mr="8px"
                >
                  Add another booth
                </Button>
              </Flex>
            </Box>
          </Box>
        </Flex>
      </form>
    </Box>
  )
}

type MapsAndBoothsProps = {
  isOpen: boolean
  onClose: () => void
  maps: any
  setMaps: (arg: any) => void
}

const MapsAndBooths: React.FC<MapsAndBoothsProps> = ({
  onClose,
  maps,
  setMaps,
  ...props
}) => {
  const {
    isOpen: isOpenCreateForm,
    onOpen: onOpenCreateForm,
    onClose: onCloseCreateForm,
  } = useDisclosure()
  const {
    isOpen: isOpenUpdateForm,
    onOpen: onOpenUpdateForm,
    onClose: onCloseUpdateForm,
  } = useDisclosure()

  const [selectedMap, setSelectedMap] = useState({})

  const allMaps = maps

  return (
    <Modal
      {...props}
      closeOnOverlayClick={false}
      onClose={() => {
        onCloseCreateForm()
        onCloseUpdateForm()
        onClose()
      }}
    >
      <ModalOverlay />
      <ModalContent p="10px" minW="1200px" minHeight="600px">
        <ModalHeader>
          <Text as="span" fontWeight="600" fontSize="20px">
            Maps &amp; Booths
          </Text>
          <ThemedButton
            ml="24px"
            onClick={() => {
              onCloseUpdateForm()
              onOpenCreateForm()
            }}
            leftIcon={<FiPlus color="white" fontSize="18px" />}
          >
            Create Map
          </ThemedButton>
        </ModalHeader>
        <ModalCloseButton
          color="mediumGray"
          marginTop="10px"
          marginRight="10px"
        />
        <ModalBody paddingRight="32px">
          {isOpenCreateForm && (
            <MapForm
              maps={maps}
              setMaps={setMaps}
              onClose={onCloseCreateForm}
              mode="CREATE"
            />
          )}
          {isOpenUpdateForm && (
            <MapForm
              maps={maps}
              setMaps={setMaps}
              onClose={onCloseUpdateForm}
              mode="UPDATE"
              selectedMap={selectedMap}
            />
          )}
          {!isOpenCreateForm && !isOpenUpdateForm && (
            <>
              {!allMaps?.length && <EmptyRecords mt="0px" />}
              {allMaps?.length && (
                <Table variant="simple">
                  <Thead>
                    <Tr>
                      <Th>Map Name</Th>
                      <Th>No. of Booths</Th>
                      <Th>No. of exhibitors</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {allMaps.map((m: any) => (
                      <Tr key={m.name}>
                        <Td
                          _hover={{ color: 'blue.500' }}
                          cursor="pointer"
                          onClick={() => {
                            setSelectedMap(m)
                            onOpenUpdateForm()
                          }}
                        >
                          {m.name}
                        </Td>
                        <Td>{Number(m.booths?.length || 0)}</Td>
                        <Td>{Number(m.exhibitors?.length || 0)}</Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              )}
            </>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default MapsAndBooths
