import {
  Flex,
  Box,
  Text,
  Grid,
  useDisclosure,
  FlexProps,
} from '@chakra-ui/react'
import { FiPlus } from 'react-icons/fi'
import styled from '@emotion/styled'
import { useAtom } from 'jotai'
import { isEqual } from 'lodash'
import { useEffect, useState } from 'react'
import uuid from 'react-uuid'
import { useLocation } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import useSWR from 'swr'

import { CreateExhibitorForm } from '../../components/partials'
import {
  ThemedButton,
  ThemedTable,
  ThemedSelect,
} from '../../components/shared'
import {
  AWSQueryOutput,
  CompanyTypeEnum,
  Exhibitor,
  QueryVariables,
  StatusEnum,
  ThemedSelectItem,
  YesNoEnum,
} from '../../interfaces'
import { companyRecordAtom } from '../../atoms'
import { fetcher } from '../../fetchers'
import { useAuthentication, useCategories } from '../../hooks'
import useShows from '../../hooks/useShows'
import ThemedFormHelperMessage from '../../components/shared/ThemedFormHelperMessage'

const columns = [
  'Banner Name',
  'Show',
  'Booths',
  'Status',
  'Contact',
  'Order',
  'ID',
]

const statuses = [
  { value: 'All', label: 'All' },
  { value: StatusEnum.ACTIVE, label: StatusEnum.ACTIVE },
  { value: StatusEnum.PENDING, label: StatusEnum.PENDING },
  { value: StatusEnum.CANCELLED, label: StatusEnum.CANCELLED },
]

const StyledThemedTable = styled(ThemedTable)`
  & {
    width: ${({ isLoading }) =>
      isLoading ? '100%' : 'calc(calc(100vw - 245px) - 200px)'};

    @media (min-width: 1560px) {
      width: calc(100% - 150px);
    }
  }
`

const StyledFlex: React.FC<FlexProps & { isFooter?: boolean }> = ({
  children,
  ...props
}) => <Flex {...props}>{children}</Flex>

const StyledExhibitorFlex = styled(StyledFlex)`
  & {
    width: ${({ isFooter }) =>
      isFooter
        ? 'calc(calc(100vw - 245px) - 292px)'
        : 'calc(calc(100vw - 245px) - 302px)'};

    @media (min-width: 1500px) {
      width: ${({ isFooter }) =>
        isFooter
          ? 'calc(calc(100vw - 245px) - 292px)'
          : 'calc(calc(100vw - 245px) - 322px)'};
    }
  }
`

const ExhibitorPage: React.FC = () => {
  const [companyRecord] = useAtom(companyRecordAtom)
  const [filter, setFilter] = useState<Record<string, any>>({})
  const [defaultValues, setDefaultValues] = useState<Record<string, any>>({})
  const { isAuthenticated } = useAuthentication()
  const { categories, subCategories } = useCategories({ includeBoats: true })
  const location = useLocation()
  const locationState = location?.state as any
  const { register, setValue } = useForm()
  const [statusKey, setStatusKey] = useState('')
  const { shows } = useShows()

  const loadingContents = !categories.length
  const [variables, setVariables] = useState<QueryVariables>({
    api: '',
    first: 10,
    after: null,
    savedCursor: [null],
    currentPage: 0,
  })

  const {
    data: res,
    mutate,
    error,
  } = useSWR(
    [
      `/company/${companyRecord.record?.companyId}/exhibitors?first=${
        variables.first
      }${
        variables.after ? `&after=${encodeURIComponent(variables.after)}` : ''
      }`,
      filter,
    ],
    fetcher
  )

  const isLoading = !error && !res

  const records = (res?.data as AWSQueryOutput)?.Items?.map(i => {
    const c = i as Exhibitor
    return {
      ...c,
      booths: c.mapsBooths?.reduce((acc: string, curr: any, i: number) => {
        const [, mapId, boothId] = curr.value.split('::')
        const map = c.show.maps?.find((m: any) => m.id === mapId) || []
        const booth = map?.booths?.find((b: any) => b.id === boothId)

        return `${acc}${booth?.name ? `${map.name} - ${booth.name}` : ''}${
          i < (c.mapsBooths?.length || 0) - 1 ? ',' : ''
        } `
      }, ''),
      ...(companyRecord.record?.type !== CompanyTypeEnum.BRAND
        ? { order: c.order ? YesNoEnum.YES : YesNoEnum.NO }
        : undefined),
      id: c.exhibitorId,
      contact: `${c?.contact?.firstName ? c?.contact?.firstName : '-'} ${
        c?.contact?.lastName ?? ''
      }`,
      show: c?.show?.showName,
    }
  })

  const [key, setKey] = useState('')
  const { isOpen, onOpen, onClose: onModalClose } = useDisclosure()

  const onClose = () => {
    onModalClose()
    setDefaultValues({})
    setTimeout(() => {
      setKey(uuid())
    }, 300)
  }

  const onRowClick = (row: any) => {
    const record = (res?.data as AWSQueryOutput)?.Items?.find(
      c => c.exhibitorId === row.id
    )
    setDefaultValues({
      ...record,
      ...(record?.contact?.firstName
        ? {
            contact: {
              label: `${record?.contact?.firstName} ${record?.contact?.lastName}`,
              value: record?.contact?.contactId,
            },
          }
        : { contact: { label: '-', value: '' } }),

      status: { label: record?.status, value: record?.status },
      show: { label: record?.show?.showName, value: record?.show?.showId },
      category: record?.category?.length
        ? record?.category.map((i: string) => ({ label: i, value: i }))
        : [],
      subCategory: record?.subCategory?.length
        ? record?.subCategory.map((i: string) => ({
            label: i,
            value: i,
            category: subCategories
              ? Object.entries(subCategories).find(s =>
                  s[1].map(s => s.label).includes(i)
                )?.[0]
              : '',
          }))
        : [],
      mapsBooths: record?.mapsBooths?.map((b: any) => {
        const [, mapId, boothId] = b.value.split('::')
        const map = record?.show?.maps?.find((m: any) => m.id === mapId) || []
        const booth = map?.booths?.find((b: any) => b.id === boothId)

        return {
          ...b,
          label: booth?.name ? `${map.name} - ${booth.name}` : '',
        }
      }),
    })
    onOpen()
  }

  const handleNext = () => {
    if (res?.data?.LastEvaluatedKey) {
      setVariables(v => ({
        ...v,
        after: `${res?.data?.LastEvaluatedKey?.companyId}~${res?.data?.LastEvaluatedKey?.exhibitorId}`,
        savedCursor: [
          ...v.savedCursor,
          `${res?.data?.LastEvaluatedKey?.companyId}~${res?.data?.LastEvaluatedKey?.exhibitorId}`,
        ],
        currentPage: v.currentPage! + 1,
      }))
    }
  }

  const handlePrev = () => {
    setVariables(v => ({
      ...v,
      after: v.savedCursor[v.currentPage! - 1] ?? null,
      currentPage: v.currentPage! - 1,
    }))
  }

  useEffect(() => {
    if (locationState?.pending) {
      setValue('status', statuses[2])
      setFilter({ status: StatusEnum.PENDING })
      setStatusKey(uuid())
    }
  }, [locationState])

  const statusField = register('status')

  return (
    <Box pb="171px" position="relative">
      <StyledExhibitorFlex justifyContent="space-between" mt="54px" ml="51px">
        <Box>
          <Text as="span" fontSize="32px" fontWeight="600">
            My Exhibit Space
          </Text>
          <Text
            as="span"
            display="block"
            fontSize="13px"
            color="darkGray"
            maxW="60ch"
          >
            List of Exhibitor Space(s) by show associated with your company.
            Please review and if changes needed contact showservice@nmma.org
          </Text>
        </Box>
        <Box position="relative">
          <ThemedButton
            type="button"
            height="55px"
            width="158px"
            onClick={onOpen}
            leftIcon={<FiPlus color="white" fontSize="22px" />}
            isDisabled={isLoading || loadingContents || !isAuthenticated}
          >
            <Text as="span" ml="8px">
              Create
            </Text>
          </ThemedButton>
        </Box>
      </StyledExhibitorFlex>
      <Grid
        w="500px"
        templateColumns="repeat(2, 1fr)"
        columnGap="14px"
        ml="51px"
        mt="47px"
      >
        <Box>
          <Text
            as="span"
            display="block"
            fontSize="13px"
            color="darkGray"
            mb="4px"
          >
            Show
          </Text>
          <ThemedSelect
            id=""
            options={shows}
            maxWidthOptions={230}
            hasShadow
            color="#718096"
            {...statusField}
            key={statusKey}
            isSearchable
            isClearable
            onChange={(e: ThemedSelectItem) => {
              const value = e?.value ?? null

              if (!isEqual(filter, { ...filter, showId: value })) {
                setVariables(v => ({
                  ...v,
                  savedCursor: [null],
                  currentPage: 0,
                  after: null,
                }))
                if (value === null) {
                  setFilter(prev => {
                    const newPrev = prev
                    delete newPrev.showId
                    return {
                      ...newPrev,
                    }
                  })
                } else {
                  setFilter(prev => ({
                    ...prev,
                    showId: value,
                  }))
                }
              }
            }}
          />
        </Box>
        <Box>
          <Text
            as="span"
            display="block"
            fontSize="13px"
            color="darkGray"
            mb="4px"
          >
            Status
          </Text>
          <ThemedSelect
            id=""
            options={statuses}
            isSearchable={false}
            defaultValue={locationState?.pending ? statuses[2] : statuses[0]}
            maxWidthOptions={230}
            hasShadow
            color="#718096"
            {...statusField}
            key={statusKey}
            onChange={(e: ThemedSelectItem) => {
              if (!isEqual(filter, { ...filter, status: e.value })) {
                if (e.value === 'All') {
                  setVariables(v => ({
                    ...v,
                    savedCursor: [null],
                    currentPage: 0,
                    after: null,
                  }))
                  setFilter(prev => {
                    const newPrev = prev
                    delete newPrev.status
                    return {
                      ...newPrev,
                    }
                  })
                } else {
                  setVariables(v => ({
                    ...v,
                    savedCursor: [null],
                    currentPage: 0,
                    after: null,
                  }))
                  setFilter(prev => ({
                    ...prev,
                    status: e.value,
                  }))
                }
              }
            }}
          />
        </Box>
      </Grid>
      <StyledThemedTable
        columns={columns}
        rows={records}
        mt="24px"
        isLoading={isLoading}
        onRowClick={onRowClick}
        disableNext={!res?.data?.hasNextPage}
        disablePrev={Number(variables.currentPage) + 1 === 1}
        handleNext={handleNext}
        handlePrev={handlePrev}
      />
      <StyledExhibitorFlex
        ml="51px"
        justifyContent="end"
        mb="20px"
        position="absolute"
        bottom="0px"
        isFooter
      >
        <ThemedFormHelperMessage>
          For questions, please contact showservice@nmma.org
        </ThemedFormHelperMessage>
      </StyledExhibitorFlex>
      <CreateExhibitorForm
        isOpen={isOpen}
        onClose={onClose}
        mutate={mutate}
        companyId={companyRecord.record?.companyId}
        defaultValues={defaultValues}
        key={key}
        isAllowedToUpdate={isAuthenticated}
      />
    </Box>
  )
}

export default ExhibitorPage
