import { Flex, Box, Text, useDisclosure } from '@chakra-ui/react'
import styled from '@emotion/styled'
import React, { useState } from 'react'
import { FiPlus } from 'react-icons/fi'
import { useAtom } from 'jotai'
import uuid from 'react-uuid'
import useSWR from 'swr'
import { CreateContactForm } from '../../components/partials'
import { ThemedButton, ThemedTable } from '../../components/shared'
import { Contact, QueryVariables } from '../../interfaces'
import { fetcher } from '../../fetchers'
import { companyRecordAtom } from '../../atoms'
import ThemedFormHelperMessage from '../../components/shared/ThemedFormHelperMessage'

const columns = [
  'First Name',
  'Last Name',
  'Prefix',
  'Email',
  'Phone',
  'Title',
  'ID',
]

const StyledThemedTable = styled(ThemedTable)`
  width: calc(100% - 200px);

  @media (min-width: 1600px) {
    width: calc(100% - 130px);
  }

  @media (min-width: 1700px) {
    width: 100%;
  }
`

const ContactPage: React.FC = () => {
  const [companyRecord] = useAtom(companyRecordAtom)
  const [defaultValues, setDefaultValues] = useState<Record<string, any>>({})

  const [variables, setVariables] = useState<QueryVariables>({
    api: '',
    first: 10,
    after: null,
    savedCursor: [null],
    currentPage: 0,
  })

  const {
    data: res,
    mutate,
    error,
  } = useSWR(
    `/company/${companyRecord.record?.companyId}/contacts?first=${
      variables.first
    }${variables.after ? `&after=${encodeURIComponent(variables.after)}` : ''}`,
    fetcher
  )

  const isLoading = !error && !res
  const records = res?.data?.Items?.map((d: Contact) => ({
    ...d,
    id: d.contactId,
  }))

  const [key, setKey] = useState('')

  const { isOpen, onOpen, onClose: onModalClose } = useDisclosure()

  const onClose = () => {
    onModalClose()
    setDefaultValues({})
    setTimeout(() => {
      setKey(uuid())
    }, 300)
  }

  const onRowClick = (row: any) => {
    setDefaultValues(
      res?.data?.Items?.find((c: Contact) => c.contactId === row.id)
    )
    onOpen()
  }

  const handleNext = () => {
    if (res?.data?.LastEvaluatedKey) {
      setVariables(v => ({
        ...v,
        after: `${res?.data?.LastEvaluatedKey?.companyId}~${res?.data?.LastEvaluatedKey?.contactId}`,
        savedCursor: [
          ...v.savedCursor,
          `${res?.data?.LastEvaluatedKey?.companyId}~${res?.data?.LastEvaluatedKey?.contactId}`,
        ],
        currentPage: v.currentPage! + 1,
      }))
    }
  }

  const handlePrev = () => {
    setVariables(v => ({
      ...v,
      after: v.savedCursor[v.currentPage! - 1],
      currentPage: v.currentPage! - 1,
    }))
  }

  return (
    <Box pb="171px" position="relative">
      <Flex
        justifyContent="space-between"
        mt="54px"
        w="calc(calc(100vw - 245px) - 302px)"
        ml="51px"
      >
        <Box>
          <Text as="span" fontSize="32px" fontWeight="600">
            Contacts
          </Text>
          <Text
            as="span"
            display="block"
            fontSize="13px"
            color="darkGray"
            maxW="60ch"
          >
            List of Contacts associated with booth at show bookings. Internal
            field only. Will not be shown on Consumer facing digital show guide.
          </Text>
        </Box>
        <ThemedButton
          type="button"
          height="55px"
          width="158px"
          onClick={onOpen}
          leftIcon={<FiPlus color="white" fontSize="22px" />}
          isDisabled={isLoading}
        >
          <Text as="span" ml="8px">
            Create
          </Text>
        </ThemedButton>
      </Flex>
      <StyledThemedTable
        columns={columns}
        rows={records}
        isLoading={isLoading}
        mt="47px"
        onRowClick={onRowClick}
        disableNext={!res?.data?.hasNextPage}
        disablePrev={Number(variables.currentPage) + 1 === 1}
        handleNext={handleNext}
        handlePrev={handlePrev}
      />
      <Flex
        justifyContent="end"
        mb="20px"
        w="calc(calc(100vw - 245px) - 292px)"
        ml="51px"
        position="absolute"
        bottom="0px"
      >
        <ThemedFormHelperMessage>
          For questions, please contact showservice@nmma.org
        </ThemedFormHelperMessage>
      </Flex>
      <CreateContactForm
        isOpen={isOpen}
        onClose={onClose}
        mutate={mutate}
        companyId={companyRecord.record?.companyId}
        key={key}
        defaultValues={defaultValues}
      />
    </Box>
  )
}

export default ContactPage
