import {
  Flex,
  Box,
  Text,
  useDisclosure,
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  useToast,
} from '@chakra-ui/react'
import { useAtom } from 'jotai'
import { isEqual } from 'lodash'
import { FiPlus } from 'react-icons/fi'

import numeral from 'numeral'
import { useState, useEffect, useRef } from 'react'
import useSWR from 'swr'
import { companyRecordAtom } from '../../atoms'
import { CreateBoatProductForm } from '../../components/partials'
import {
  ThemedButton,
  ThemedImagesAtRow,
  ThemedSelect,
  ThemedTable,
} from '../../components/shared'
import { fetcher, httpPost } from '../../fetchers'
import {
  AWSQueryOutput,
  BoatProduct,
  QueryVariables,
  ThemedSelectItem,
} from '../../interfaces'
import { useBoatTypes } from '../../hooks'
import ThemedFormHelperMessage from '../../components/shared/ThemedFormHelperMessage'
import { BASE_URL } from '../../config'

const columns = [
  'Image',
  'Product Name',
  'Brand Company',
  'Model Year',
  'isEBC',
  'EBC Status',
  'Published/Unpublished',
  'Source Product Code',
  'Boat Type',
  'Length',
  'Propulsion Type',
  'Certified Flag',
  'Starting Price',
  'ID',
]

const BoatProductPage: React.FC = () => {
  const { boatTypes } = useBoatTypes()
  const [companyRecord] = useAtom(companyRecordAtom)
  const [filter, setFilter] = useState<Record<string, any>>({})
  const [defaultValues, setDefaultValues] = useState<Record<string, any>>({})
  const [checks, setChecks] = useState([false])
  const [hasChecks, setHasChecks] = useState(false)
  const [approvalMode, setApprovalMode] = useState('')
  const [isSubmitting, setSubmitting] = useState(false)

  const toast = useToast()
  const tableRef = useRef<any>(null)

  const boatTypeOptions = [{ value: 'All', label: 'All' }, ...boatTypes]

  const [variables, setVariables] = useState<QueryVariables>({
    api: '',
    first: 10,
    after: null,
    savedCursor: [null],
    currentPage: 0,
  })

  const {
    isOpen: isOpenConfirmModal,
    onOpen: onOpenConfirmModal,
    onClose: onCloseConfirmModal,
  } = useDisclosure()

  const {
    data: res,
    mutate,
    error,
  } = useSWR(
    [
      `/company/${companyRecord.record?.companyId}/boat-products?first=${
        variables.first
      }${
        variables.after ? `&after=${encodeURIComponent(variables.after)}` : ''
      }`,
      filter,
    ],
    fetcher
  )

  const isLoading = !error && !res
  const records = (res?.data as AWSQueryOutput)?.Items?.map(i => {
    const c = i as BoatProduct
    return {
      ...c,
      id: c.boatProductId,
      description: `${c.description?.substr(0, 19)}${
        c.description?.length >= 19 ? '...' : ''
      }`,
      image: (
        <ThemedImagesAtRow
          fileNameHashed={
            c?.fileNameHashed
              ? c?.fileNameHashed
              : c?.basicGallery?.[0]?.fileNameHashed ||
                c?.gallery?.[0]?.fileNameHashed
          }
        />
      ),
      brandCompany: companyRecord.record?.name,
      startingPrice: numeral(c.startingPrice).format('$0,.00'),
      length: `${
        Math.floor(Number(c.length)) ? `${Math.floor(Number(c.length))} ft` : ''
      } ${
        Number(
          Number(
            (Number(c?.length) - Math.floor(Number(c?.length))) * 12
          ).toFixed(2)
        )
          ? `${Number(
              Number(
                (Number(c?.length) - Math.floor(Number(c?.length))) * 12
              ).toFixed(2)
            )} in`
          : ''
      }`,
    }
  })

  const { isOpen, onOpen, onClose: onModalClose } = useDisclosure()

  const onClose = () => {
    onModalClose()
    setDefaultValues({})
  }

  const handleNext = () => {
    if (res?.data?.LastEvaluatedKey) {
      setVariables(v => ({
        ...v,
        after: `${res?.data?.LastEvaluatedKey?.companyId}~${res?.data?.LastEvaluatedKey?.boatProductId}`,
        savedCursor: [
          ...v.savedCursor,
          `${res?.data?.LastEvaluatedKey?.companyId}~${res?.data?.LastEvaluatedKey?.boatProductId}`,
        ],
        currentPage: v.currentPage! + 1,
      }))
    }
  }

  const handlePrev = () => {
    setVariables(v => ({
      ...v,
      after: v.savedCursor[v.currentPage! - 1],
      currentPage: v.currentPage! - 1,
    }))
  }

  const onRowClick = (row: any) => {
    const record = res?.data?.Items?.find(
      (d: BoatProduct) => d.boatProductId === row.id
    )

    setDefaultValues({
      ...record,
      boatType: { label: record.boatType, value: record.boatType },
      hullMaterial: { label: record.hullMaterial, value: record.hullMaterial },
      fuelType: { label: record.fuelType, value: record.fuelType },
      modelYear: { label: record.modelYear, value: record.modelYear },
      otherHullColor1:
        record?.otherHullColor?.length > 0 ? record.otherHullColor[0] : '',
      otherHullColor2:
        record?.otherHullColor?.length > 0 ? record.otherHullColor[1] : '',
      otherHullColor3:
        record?.otherHullColor?.length > 0 ? record.otherHullColor[2] : '',
      otherHullColor4:
        record?.otherHullColor?.length > 0 ? record.otherHullColor[3] : '',
      propulsionType: {
        label: record?.propulsionType,
        value: record?.propulsionType,
      },
      status: { label: record?.status, value: record?.status },
    })
    onOpen()
  }

  const onApprovalClick = async () => {
    try {
      setSubmitting(true)

      const forCloneIds = checks.filter(Boolean)

      const forCloneData = forCloneIds
        .map(id => records?.find(r => r.boatProductId === String(id)))
        .map((item: any) => {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { boatProductId, image, ...rest } = item ?? {
            boatProductId: '',
            productName: '',
            image: '',
            length: '',
            startingPrice: '',
            cloneCount: 0,
            status: '',
            enhancedBoatCard: false,
          }

          const cloneCounter = rest.cloneCount ? rest.cloneCount + 1 : 1
          const nameSplit = rest.productName.split(' ')
          const cloneCounterPresent =
            nameSplit[nameSplit.length - 1].includes('(') &&
            nameSplit[nameSplit.length - 1].includes(')')

          const modifiedProductName =
            cloneCounterPresent && !rest.isCloned
              ? `${nameSplit.slice(0, -1).join('')} (${cloneCounter})`
              : `${rest.productName} (${cloneCounter})`

          return {
            ...rest,
            productName: modifiedProductName,
            published: false,
            length: Number(rest.length.replace('ft', '').trim()),
            startingPrice: Number(rest.startingPrice.replace(/[^0-9.-]+/g, '')),
            isCloned: true,
            cloneCount: 0,
            status: rest.enhancedBoatCard === false ? '' : 'Pending EBC',
            originalProductName: rest.productName,
            originalBoatProductId: boatProductId,
          }
        })

      await Promise.all(
        forCloneData.map(async item => {
          const response = await httpPost({
            url: `${BASE_URL}/boat-product/create`,
            body: item,
          })

          if (response?.data) {
            const forUpdate: any = records?.find(
              r => r.boatProductId === item.originalBoatProductId
            )

            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { image, ...restForUpdate } = forUpdate

            const itemForUpdate = {
              ...restForUpdate,
              cloneCount: restForUpdate?.cloneCount
                ? restForUpdate?.cloneCount + 1
                : 1,
              length: Number(restForUpdate.length.replace('ft', '').trim()),
              startingPrice: Number(
                restForUpdate.startingPrice.replace(/[^0-9.-]+/g, '')
              ),
            }

            await httpPost({
              url: `${BASE_URL}/boat-product/update`,
              body: itemForUpdate,
            })

            toast({
              title: `${item.originalProductName} has been cloned succesfully.`,
              status: 'success',
              duration: 1500,
              isClosable: true,
            })
          }
        })
      )

      mutate().then(() => {
        setChecks([false])
        setHasChecks(false)
        tableRef.current.reset()
      })

      onCloseConfirmModal()
      setSubmitting(false)
    } catch (err) {
      toast({
        title: 'There were errors processing your request.',
        status: 'error',
        duration: 1500,
        isClosable: true,
      })
      tableRef.current.reset()
      onCloseConfirmModal()
      setSubmitting(false)
    }
  }

  useEffect(() => {
    console.log(hasChecks)
    console.log(checks)
  }, [hasChecks, checks])

  return (
    <>
      <Box pb="171px" position="relative">
        <Flex
          justifyContent="space-between"
          mt="54px"
          w="calc(calc(100vw - 245px) - 302px)"
          ml="51px"
        >
          <Box>
            <Text as="span" fontSize="32px" fontWeight="600">
              Add Boats
            </Text>
            <Text
              as="span"
              display="block"
              fontSize="13px"
              color="darkGray"
              maxW="60ch"
            >
              Please add your new model year boats to the system by clicking on
              the + Create button and following the instructions.
            </Text>
          </Box>
          <ThemedButton
            height="55px"
            width="158px"
            type="button"
            onClick={onOpen}
            leftIcon={<FiPlus color="white" fontSize="22px" />}
            isDisabled={isLoading || !boatTypes.length}
          >
            <Text as="span" ml="8px">
              Create
            </Text>
          </ThemedButton>
        </Flex>
        <Flex
          ml="51px"
          mt="47px"
          justifyContent="space-between"
          w="calc(calc(100vw - 245px) - 292px)"
        >
          <Box minW="250px">
            <Text
              as="span"
              display="block"
              fontSize="13px"
              color="darkGray"
              mb="4px"
            >
              Boat Type
            </Text>

            <ThemedSelect
              id="boatType"
              options={boatTypeOptions}
              isSearchable={false}
              defaultValue={boatTypeOptions[0]}
              maxWidthOptions={220}
              hasShadow
              color="#718096"
              onChange={(e: ThemedSelectItem) => {
                if (!isEqual(filter, { ...filter, boatType: e.value })) {
                  setVariables(v => ({
                    ...v,
                    savedCursor: [null],
                    currentPage: 0,
                    after: null,
                  }))
                  if (e.value === 'All') {
                    setFilter({})
                  } else {
                    setFilter(prev => ({
                      ...prev,
                      boatType: e.value,
                    }))
                  }
                }
              }}
            />
          </Box>

          {hasChecks && (
            <>
              <Button
                size="md"
                onClick={() => {
                  onOpenConfirmModal()
                  setApprovalMode('')
                }}
                minW="100px"
                colorScheme="blue"
                marginRight={4}
              >
                <Text as="span">Clone</Text>
              </Button>
            </>
          )}
        </Flex>
        <ThemedTable
          // @ts-ignore
          ref={tableRef}
          id="boatProductTable"
          columns={columns}
          rows={records}
          isLoading={isLoading}
          onRowClick={onRowClick}
          disableNext={!res?.data?.hasNextPage}
          disablePrev={Number(variables.currentPage) + 1 === 1}
          handleNext={handleNext}
          handlePrev={handlePrev}
          w="calc(100% - 200px)"
          mt="24px"
          allowMultipleChecks
          allowClone
          setChecks={setChecks}
          setHasChecks={setHasChecks}
        />
        <Flex
          w="calc(calc(100vw - 245px) - 292px)"
          justifyContent="end"
          mb="20px"
          position="absolute"
          bottom="0px"
          ml="51px"
        >
          <ThemedFormHelperMessage>
            For questions, please contact showservice@nmma.org
          </ThemedFormHelperMessage>
        </Flex>
        <CreateBoatProductForm
          isOpen={isOpen}
          onClose={onClose}
          mutate={mutate}
          companyId={companyRecord.record?.companyId}
          isCertified={companyRecord.record?.nmmaCertified}
          defaultValues={defaultValues}
        />
      </Box>

      <Modal
        isOpen={isOpenConfirmModal}
        onClose={() => {
          if (!isSubmitting) {
            onCloseConfirmModal()
          }
        }}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent p="0px" minW="500px">
          <ModalHeader>
            <Text as="span" fontWeight="600" fontSize="20px">
              {approvalMode}
            </Text>
          </ModalHeader>
          <ModalBody paddingRight="32px">
            <Text as="span" fontSize="16px" display="block">
              Are you sure to proceed?
            </Text>
          </ModalBody>
          <ModalFooter justifyContent="end">
            <Button
              size="sm"
              onClick={() => {
                onCloseConfirmModal()
              }}
              mr="12px"
              isDisabled={isSubmitting}
              minW="100px"
            >
              <Text as="span">Cancel</Text>
            </Button>
            <Button
              size="sm"
              onClick={onApprovalClick}
              isDisabled={isSubmitting}
              minW="100px"
              colorScheme="blue"
            >
              {isSubmitting ? (
                <Spinner color="gray.500" size="sm" />
              ) : (
                <Text as="span">Confirm</Text>
              )}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default BoatProductPage
