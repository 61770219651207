import {
  Flex,
  Box,
  Text,
  useDisclosure,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  FlexProps,
} from '@chakra-ui/react'
import { isEqual } from 'lodash'
import { FiPlus } from 'react-icons/fi'
import styled from '@emotion/styled'
import { useAtom } from 'jotai'
import uuid from 'react-uuid'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import useSWR from 'swr'

import { CreateExhibitorProductsServicesForm } from '../../components/partials'
import {
  ThemedButton,
  ThemedImagesAtRow,
  ThemedSelect,
  ThemedTable,
} from '../../components/shared'
import {
  AWSQueryOutput,
  ExhibitorProductService,
  QueryVariables,
  StatusEnum,
  ThemedSelectItem,
} from '../../interfaces'
import { companyRecordAtom } from '../../atoms'
import { useExhibitors } from '../../hooks'
import { fetcher } from '../../fetchers'
import ThemedFormHelperMessage from '../../components/shared/ThemedFormHelperMessage'

const columns = [
  'Exhibitor',
  'Status',
  'Show Code',
  'Product & Service',
  'Image',
  'Category',
  'Sub Category',
  'Booth',
  'Exhibitor ID',
]

const filters = {
  status: [
    { value: 'All', label: 'All' },
    { value: StatusEnum.APPROVED, label: StatusEnum.APPROVED },
    { value: StatusEnum.PENDING, label: StatusEnum.PENDING },
    { value: StatusEnum.REJECTED, label: StatusEnum.REJECTED },
  ],
}

const StyledThemedTable = styled(ThemedTable)`
  & {
    width: calc(100% - 200px);

    @media (min-width: 1500px) {
      width: calc(100% - 200px);
    }
    @media (min-width: 1800px) {
      width: calc(100% - 100px);
    }
  }
`
const StyledFlex: React.FC<FlexProps & { isFooter?: boolean }> = ({
  children,
  ...props
}) => <Flex {...props}>{children}</Flex>

const StyledEPSFlex = styled(StyledFlex)`
  & {
    width: ${({ isFooter }) =>
      isFooter
        ? 'calc(calc(100vw - 245px) - 292px)'
        : 'calc(calc(100vw - 245px) - 302px)'};

    @media (min-width: 1500px) {
      width: ${({ isFooter }) =>
        isFooter
          ? 'calc(calc(100vw - 245px) - 292px)'
          : 'calc(calc(100vw - 245px) - 322px)'};
    }
  }
`

const ExhibitorProductsServices: React.FC = () => {
  const [companyRecord] = useAtom(companyRecordAtom)
  const { exhibitors } = useExhibitors()
  const [defaultValues, setDefaultValues] = useState<Record<string, any>>({})
  const [filter, setFilter] = useState<Record<string, any>>({})

  const location = useLocation()
  const locationState = location?.state as any
  const { register, setValue } = useForm()
  const [statusKey, setStatusKey] = useState('')
  const [variables, setVariables] = useState<QueryVariables>({
    api: '',
    first: 10,
    after: null,
    savedCursor: [null],
    currentPage: 0,
  })

  const {
    data: res,
    mutate,
    error,
  } = useSWR(
    [
      `/company/${
        companyRecord.record?.companyId
      }/exhibitor-products-services?first=${variables.first}${
        variables.after ? `&after=${encodeURIComponent(variables.after)}` : ''
      }`,
      filter,
    ],
    fetcher
  )

  const isLoading = !error && !res

  const records = (res?.data as AWSQueryOutput)?.Items?.map(i => {
    const c = i as ExhibitorProductService

    return {
      ...c,
      id: c.exhibitorProductServiceId,
      exhibitorId: c?.exhibitor?.exhibitorId,
      exhibitor: `${c?.exhibitor?.bannerName ?? ''}${
        c?.show?.showName ? ` (${c?.show.showName})` : ''
      }`,
      showCode: c?.show?.producerEventCode,
      productService: c?.productService?.productName,
      category: c?.productService?.category,
      subCategory: c?.productService?.subCategory,
      status: c?.status,
      image: (
        <ThemedImagesAtRow fileNameHashed={c?.productService?.fileNameHashed} />
      ),
      booth: c.mapsBooths?.reduce((acc: string, curr: any, i: number) => {
        const [, mapId, boothId] = curr.value.split('::')
        const map = c?.show?.maps?.find((m: any) => m.id === mapId) || []
        const booth = map?.booths?.find((b: any) => b.id === boothId)

        return `${acc}${booth?.name ? `${map.name} - ${booth.name}` : ''}${
          i < (c.mapsBooths?.length || 0) - 1 ? ',' : ''
        } `
      }, ''),
    }
  })
  const { isOpen, onOpen, onClose: onModalClose } = useDisclosure()

  const onClose = () => {
    onModalClose()
    setDefaultValues({})
  }

  const onRowClick = (row: any) => {
    const record = (res?.data as AWSQueryOutput)?.Items?.find(
      c => c.exhibitorProductServiceId === row.id
    )

    setDefaultValues({
      ...record,
      exhibitor: {
        label: `${record?.exhibitor?.bannerName ?? ''}${
          record?.show.showName ? ` (${record?.show.showName})` : ''
        }`,
        value: `${record?.exhibitor?.exhibitorId}~${record?.show?.showId}`,
      },
      status: { label: record?.status, value: record?.status },
      productsServices: [
        {
          label: record?.productService.productName,
          value: record?.productService.productServiceId,
        },
      ],
      mapsBooths: record?.mapsBooths?.map((b: any) => {
        const [, mapId, boothId] = b.value.split('::')
        const map = record?.show?.maps?.find((m: any) => m.id === mapId) || []
        const booth = map?.booths?.find((b: any) => b.id === boothId)

        return {
          ...b,
          label: booth?.name ? `${map.name} - ${booth.name}` : '',
        }
      }),
    })
    onOpen()
  }

  const handleNext = () => {
    if (res?.data?.LastEvaluatedKey) {
      setVariables(v => ({
        ...v,
        after: `${res?.data?.LastEvaluatedKey?.companyId}~${res?.data?.LastEvaluatedKey?.exhibitorProductServiceId}`,
        savedCursor: [
          ...v.savedCursor,
          `${res?.data?.LastEvaluatedKey?.companyId}~${res?.data?.LastEvaluatedKey?.exhibitorProductServiceId}`,
        ],
        currentPage: v.currentPage! + 1,
      }))
    }
  }

  const handlePrev = () => {
    setVariables(v => ({
      ...v,
      after: v.savedCursor[v.currentPage! - 1],
      currentPage: v.currentPage! - 1,
    }))
  }

  useEffect(() => {
    if (locationState?.pending) {
      setValue('status', filters.status[2])
      setFilter({ status: StatusEnum.PENDING })
      setStatusKey(uuid())
    }
  }, [locationState])

  const statusField = register('status')

  return (
    <Box pb="171px" position="relative">
      <StyledEPSFlex justifyContent="space-between" mt="54px" ml="51px">
        <Box>
          <Text as="span" fontSize="32px" fontWeight="600">
            My Products &amp; Services
          </Text>
          <Text as="span" display="block" fontSize="13px" color="darkGray">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit
          </Text>
        </Box>

        <Box position="relative">
          {!exhibitors.length && (
            <Box
              position="absolute"
              right="0px"
              height="55px"
              width="158px"
              zIndex="2"
            >
              <Popover trigger="hover" flip>
                <PopoverTrigger>
                  <Box
                    position="relative"
                    right="0px"
                    height="55px"
                    width="158px"
                    cursor="not-allowed"
                  />
                </PopoverTrigger>
                <PopoverContent>
                  <PopoverHeader fontWeight="semibold">
                    No exhibitors found.
                  </PopoverHeader>
                  <PopoverArrow />
                  <PopoverBody>
                    <Text as="span" fontSize="sm">
                      You have to create first an exhibitor before you can
                      proceed in creating an exhibitor product service.
                    </Text>
                  </PopoverBody>
                </PopoverContent>
              </Popover>
            </Box>
          )}
          <ThemedButton
            type="button"
            height="55px"
            width="158px"
            onClick={onOpen}
            leftIcon={<FiPlus color="white" fontSize="22px" />}
            isDisabled={isLoading || !exhibitors.length}
          >
            <Text as="span" ml="8px">
              Create
            </Text>
          </ThemedButton>
        </Box>
      </StyledEPSFlex>
      <Box ml="51px" mt="47px" maxW="250px">
        <Text
          as="span"
          display="block"
          fontSize="13px"
          color="darkGray"
          mb="4px"
        >
          Status
        </Text>
        <ThemedSelect
          id="status"
          options={filters.status}
          isSearchable={false}
          defaultValue={
            locationState?.pending ? filters.status[2] : filters.status[0]
          }
          maxWidthOptions={236}
          hasShadow
          color="#718096"
          {...statusField}
          key={statusKey}
          onChange={(e: ThemedSelectItem) => {
            if (!isEqual(filter, { ...filter, boatType: e.value })) {
              setVariables(v => ({
                ...v,
                savedCursor: [null],
                currentPage: 0,
                after: null,
              }))
              if (e.value === 'All') {
                setFilter({})
              } else {
                setFilter(prev => ({
                  ...prev,
                  status: e.value,
                }))
              }
            }
          }}
        />
      </Box>
      <StyledThemedTable
        columns={columns}
        rows={records}
        isLoading={isLoading}
        mt="24px"
        onRowClick={onRowClick}
        disableNext={!res?.data?.hasNextPage}
        disablePrev={Number(variables.currentPage) + 1 === 1}
        handleNext={handleNext}
        handlePrev={handlePrev}
      />
      <StyledEPSFlex
        justifyContent="end"
        ml="51px"
        mb="20px"
        position="absolute"
        bottom="0px"
        isFooter
      >
        <ThemedFormHelperMessage>
          For questions, please contact showservice@nmma.org
        </ThemedFormHelperMessage>
      </StyledEPSFlex>
      <CreateExhibitorProductsServicesForm
        isOpen={isOpen}
        onClose={onClose}
        mutate={mutate}
        companyId={companyRecord.record?.companyId}
        defaultValues={defaultValues}
      />
    </Box>
  )
}

export default ExhibitorProductsServices
