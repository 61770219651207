import { Flex, Button, FlexProps } from '@chakra-ui/react'
import React from 'react'
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi'
import uuid from 'react-uuid'

interface PaginationProps extends FlexProps {
  isLoadingData?: boolean
  disablePrev?: boolean
  disableNext?: boolean
  handleNext?: () => void
  handlePrev?: () => void
}

const Pagination: React.FC<PaginationProps> = ({
  isLoadingData,
  disablePrev,
  disableNext,
  handlePrev,
  handleNext,
  ...props
}) => (
  <Flex
    backgroundColor={
      (isLoadingData || disableNext) && (isLoadingData || disablePrev)
        ? 'gray.200'
        : 'transparent'
    }
    boxShadow="0px 4px 5px rgba(0, 0, 0, 0.05)"
    borderRadius="10px"
    alignItems="center"
    justifyContent="center"
    maxW="max-content"
    {...props}
  >
    <Button
      fontWeight="normal"
      fontSize="17px"
      size="xs"
      key={uuid()}
      borderRadius="3px"
      backgroundColor={
        isLoadingData || disablePrev ? 'gray.200' : 'transparent'
      }
      {...((isLoadingData || disablePrev) && {
        _hover: {
          backgroundColor: 'gray.300',
        },
      })}
      color="darkGray"
      p="1.25rem"
      isDisabled={isLoadingData || disablePrev}
      onClick={handlePrev}
    >
      <FiChevronLeft color="#989DA9" />
    </Button>
    <Button
      fontWeight="normal"
      fontSize="17px"
      size="xs"
      key={uuid()}
      borderRadius="3px"
      backgroundColor={
        isLoadingData || disableNext ? 'gray.200' : 'transparent'
      }
      {...((isLoadingData || disablePrev) && {
        _hover: {
          backgroundColor: 'gray.300',
        },
      })}
      color="darkGray"
      p="1.25rem"
      isDisabled={isLoadingData || disableNext}
      onClick={handleNext}
    >
      <FiChevronRight color="#989DA9" />
    </Button>
  </Flex>
)

export default Pagination
