import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Box,
  Grid,
  useToast,
  Spinner,
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { AxiosResponse } from 'axios'
import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import useDeepCompareEffect from 'use-deep-compare-effect'
import * as yup from 'yup'
import uuid from 'react-uuid'
import { uniqueId } from 'lodash'

import { BASE_URL } from '../../../config'
import { httpPost } from '../../../fetchers'
import {
  useExhibitors,
  useBoatProducts,
  useBrands,
  useAuthentication,
  useCompany,
} from '../../../hooks'
import {
  CompanyTypeEnum,
  StatusEnum,
  ThemedSelectItem,
} from '../../../interfaces'
import {
  ThemedButton,
  ThemedFormErrorMessage,
  ThemedLabel,
  ThemedModalTrashButton,
  ThemedSelect,
} from '../../shared'

type CreateExhibitorBoatProductsFormProps = {
  isOpen: boolean
  onClose: () => void
  mutate?: () => Promise<AxiosResponse<any> | undefined>
  companyId?: string | undefined
  defaultValues?: Record<string, any>
}

interface FormData {
  companyId: string
  exhibitor: ThemedSelectItem
  status: ThemedSelectItem
  brandIds: ThemedSelectItem[]
  boatProduct: ThemedSelectItem[]
  mapsBooths: any[]
  boothCoordinates: any
}

const exhibitorBoatProductSchema = yup.object().shape({
  boatProduct: yup.array().test({
    message: 'Please provide at least one boat product.',
    test: arr => {
      if (arr?.length) {
        return arr?.length > 0
      }
      return false
    },
  }),
  brandIds: yup
    .array()
    .of(yup.object().shape({}))
    .test({
      message: 'Please provide at least one brand.',
      test: arr => {
        if (arr?.length) {
          return arr?.length > 0
        }
        return false
      },
    }),
  mapsBooths: yup.array().of(
    yup.object().shape({
      value: yup.string(),
    })
  ),
})

const options = {
  status: [
    { value: StatusEnum.APPROVED, label: StatusEnum.APPROVED },
    { value: StatusEnum.PENDING, label: StatusEnum.PENDING },
    { value: StatusEnum.REJECTED, label: StatusEnum.REJECTED },
  ],
}

const CreateExhibitorBoatProductsForm: React.FC<
  CreateExhibitorBoatProductsFormProps
> = ({
  isOpen,
  onClose: onFormClose,
  companyId,
  mutate,
  defaultValues = {},
}) => {
  const toast = useToast()
  const [exhibitorKey, setExhibitorKey] = useState<string>('')

  const { isAuthenticated: isAllowedToUpdate } = useAuthentication()
  const { exhibitors } = useExhibitors()
  // const { brands, isBrand } = useBrands()
  const { brands: allBrands } = useBrands({ isMultiple: true })

  const [mapsBooths, setMapsBooths] = useState<ThemedSelectItem[]>([])
  const [mapsBoothsKey, setMapsBoothsKey] = useState('')

  const { companyRecord } = useCompany()

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
    watch,
    setValue,
    reset,
  } = useForm<FormData>({
    defaultValues: {
      companyId,
      mapsBooths: [],
      status: options.status[1],
    },
    resolver: yupResolver(exhibitorBoatProductSchema),
  })
  const [isDeleting, setIsDeleting] = useState(false)

  const watchedBrands = watch('brandIds')

  const { boatProducts } = useBoatProducts(watchedBrands, {
    isPublished: true,
    isMultipleCompanies: true,
  })

  const defKeys = Object.keys(defaultValues)

  const watchedMapsBooths = watch('mapsBooths')
  const watchedExhibitor = watch('exhibitor')?.value

  const onClose = () => {
    reset()
    setIsDeleting(false)
    setValue('exhibitor', exhibitors[0])
    setExhibitorKey(uuid())
    onFormClose()
  }

  const onSubmit = async (data: FormData) => {
    const { status, exhibitor, mapsBooths, brandIds, boatProduct, ...rest } =
      data

    const forUpdate = defKeys.length

    const values = {
      ...rest,
      boatProductIds: boatProduct.map(b => b.value),
      mapsBooths,
      brandIds: brandIds.map(b => b.value),
      exhibitorId: exhibitor.value?.toString().split('~')[0],
      showId: exhibitor.value?.toString().split('~')[1],
      status: status.value,
      companyId,
      ...(forUpdate && {
        exhibitorBoatProductId: defaultValues.exhibitorBoatProductId,
      }),
    }

    try {
      const res = await httpPost({
        url: `${BASE_URL}/exhibitor-boat-product/${
          forUpdate ? 'update' : 'create'
        }`,
        body: values,
      })

      if (res?.data) {
        mutate?.().then(() => {
          toast({
            title: `Exhibitor boat product  ${
              forUpdate ? 'updated' : 'created'
            }.`,
            description: `Exhibitor boat product has been ${
              forUpdate ? 'updated' : 'created'
            }.`,
            status: 'success',
            duration: 1500,
            isClosable: true,
          })
        })
        onClose()
      }
    } catch (err) {
      const errResponse = (err as any)?.response

      if (errResponse) {
        const { code } = errResponse.data

        if (code === 'ConditionalCheckFailedException') {
          toast({
            title: `Failed to ${
              forUpdate ? 'update' : 'create'
            } an exhibitor boat product.`,
            description:
              'There is an existing exhibitor boat product with the same name.',
            status: 'error',
            duration: 5000,
            isClosable: true,
          })
        } else {
          toast({
            title: `Failed to ${
              forUpdate ? 'update' : 'create'
            } an exhibitor boat product.`,
            description: errResponse.data.message,
            status: 'error',
            duration: 2000,
            isClosable: true,
          })
        }
      } else {
        console.error(err)
      }
    }
  }

  const onDelete = async () => {
    setIsDeleting(true)
    if (defaultValues?.exhibitorBoatProductId) {
      try {
        const res = await httpPost({
          url: `${BASE_URL}/exhibitor-boat-product/delete`,
          body: {
            companyId,
            exhibitorBoatProductId: defaultValues?.exhibitorBoatProductId,
          },
        })

        if (res?.data) {
          mutate?.().then(() => {
            toast({
              title: 'Exhibitor boat product association has been deleted.',
              description:
                'The exhibitor boat product association has been deleted.',
              status: 'success',
              duration: 1500,
              isClosable: true,
            })
          })
          onClose()
        }
      } catch (err) {
        const errResponse = (err as any)?.response

        toast({
          title: 'Failed to delete an exhibitor boat product association.',
          description: errResponse.data.message,
          status: 'error',
          duration: 6000,
          isClosable: true,
        })
        setIsDeleting(false)
      }
    }
  }

  useDeepCompareEffect(() => {
    if (exhibitors?.length && isOpen && !watchedExhibitor) {
      setValue('exhibitor', exhibitors[0])
      setExhibitorKey(uuid())
    }
  }, [exhibitors, isOpen])

  useDeepCompareEffect(() => {
    // if (brands?.length && isBrand && isOpen) {
    //   setValue('brandIds', [brands[0]])
    // }

    if (allBrands.length && isOpen && !defKeys.length && companyRecord) {
      const currentCompany = allBrands.find(
        cc => cc.value === companyRecord.record?.companyId
      )

      if (currentCompany) {
        setValue('brandIds', [currentCompany])
      }
    }
  }, [allBrands, isOpen, companyRecord])

  useDeepCompareEffect(() => {
    if (defKeys.length) {
      defKeys.forEach(key => {
        setValue(key as keyof FormData, defaultValues[key])
      })
    } else {
      reset()
      setValue('exhibitor', exhibitors[0])
    }
  }, [defaultValues])

  useEffect(() => {
    if (watchedExhibitor) {
      setValue('mapsBooths', defaultValues?.mapsBooths ?? [])
      setMapsBoothsKey(uniqueId())

      if (exhibitors.length) {
        const exhibitor = exhibitors.find(e => {
          const [exhibitorId, showId] = e?.value?.toString().split('~')!

          return (
            exhibitorId === watchedExhibitor?.toString().split('~')[0] &&
            showId === watchedExhibitor?.toString().split('~')[1]
          )
        }) as any

        let availableBooths: ThemedSelectItem[] = []

        if (companyRecord?.record?.type === CompanyTypeEnum.DEALER) {
          availableBooths = [
            {
              label: 'No Booth - Not at Show',
              value: '-',
            },
          ]
        }

        if (exhibitor?.mapsBooths) {
          availableBooths = [
            ...availableBooths,
            ...exhibitor.mapsBooths?.sort((curr: any, next: any) =>
              curr?.label < next?.label ? -1 : 1
            ),
          ]
        }

        setMapsBooths(availableBooths)
      }
    }
  }, [watchedExhibitor])

  return (
    <Modal isOpen={isOpen} closeOnOverlayClick={false} onClose={onClose}>
      <ModalOverlay />
      <ModalContent p="0px" minW="750px">
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalHeader textAlign="center" pt="50px">
            <Text as="span" fontWeight="600" fontSize="32px" display="block">
              {defKeys.length ? 'My Boat Details' : 'Upload My Boat'}
            </Text>
          </ModalHeader>
          {!!defKeys.length && (
            <ThemedModalTrashButton
              onClick={onDelete}
              isDeleting={isDeleting}
              entity="exhibitor-boat-product"
            />
          )}
          <ModalCloseButton
            color="mediumGray"
            marginTop="15px"
            marginRight="10px"
            isDisabled={isSubmitting || isDeleting}
          />
          <ModalBody paddingLeft="32px" paddingRight="32px">
            <Grid
              mt="20px"
              templateColumns="1fr"
              autoColumns="max-content"
              autoRows="auto"
              rowGap="18px"
              columnGap="16px"
            >
              <Box>
                <ThemedLabel htmlFor="exhibitor">Exhibitor</ThemedLabel>
                <Controller
                  control={control}
                  name="exhibitor"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <ThemedSelect
                      id="exhibitor"
                      key={exhibitorKey}
                      options={exhibitors}
                      isSearchable
                      defaultValue={defaultValues.exhibitor ?? exhibitors[0]}
                      isDisabled={defaultValues.exhibitor}
                      maxWidthOptions={675}
                      onBlur={onBlur}
                      onChange={onChange}
                      checked={value}
                      inputRef={ref}
                    />
                  )}
                />
              </Box>

              <Box>
                <ThemedLabel htmlFor="status">Status</ThemedLabel>
                <Controller
                  control={control}
                  name="status"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <ThemedSelect
                      id="status"
                      options={options.status}
                      isSearchable={false}
                      defaultValue={defaultValues.status ?? options.status[1]}
                      isDisabled={!isAllowedToUpdate}
                      maxWidthOptions={675}
                      onBlur={onBlur}
                      onChange={onChange}
                      checked={value}
                      inputRef={ref}
                    />
                  )}
                />
              </Box>
              <Box>
                <ThemedLabel htmlFor="brand">Brand</ThemedLabel>
                <Controller
                  control={control}
                  name="brandIds"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <ThemedSelect
                      id="brandIds"
                      options={allBrands}
                      {...(defaultValues?.brandIds && {
                        defaultValue: defaultValues?.brandIds,
                      })}
                      isSearchable
                      maxWidthOptions={675}
                      isMulti
                      onBlur={onBlur}
                      onChange={onChange}
                      checked={value}
                      value={value}
                      inputRef={ref}
                      isDisabled={!!defKeys?.length}
                    />
                  )}
                />
                <ThemedFormErrorMessage>
                  {errors.brandIds?.message}
                </ThemedFormErrorMessage>
              </Box>

              <Box>
                <ThemedLabel htmlFor="boatProduct">Boat Product</ThemedLabel>
                <Controller
                  control={control}
                  name="boatProduct"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <ThemedSelect
                      id="boatProduct"
                      options={boatProducts}
                      isSearchable
                      maxWidthOptions={675}
                      isMulti
                      isDisabled={!boatProducts.length || !!defKeys.length}
                      defaultValue={defaultValues.boatProduct}
                      onBlur={onBlur}
                      onChange={onChange}
                      checked={value}
                      inputRef={ref}
                    />
                  )}
                />
                <ThemedFormErrorMessage>
                  {errors.boatProduct?.message}
                </ThemedFormErrorMessage>
              </Box>
              <Box>
                <ThemedLabel htmlFor="mapsBooths">Maps and Booths</ThemedLabel>
                <Controller
                  control={control}
                  name="mapsBooths"
                  render={({ field: { onBlur, value, ref } }) => (
                    <ThemedSelect
                      id="mapsBooths"
                      options={mapsBooths}
                      key={mapsBoothsKey}
                      defaultValue={defaultValues?.mapsBooths}
                      isSearchable
                      maxWidthOptions={610}
                      isMulti
                      onBlur={onBlur}
                      onChange={(e: any) => {
                        setValue('mapsBooths', e)
                      }}
                      checked={value}
                      inputRef={ref}
                      value={watchedMapsBooths}
                      // isDisabled={isDisabled}
                    />
                  )}
                />
              </Box>
            </Grid>
          </ModalBody>
          <ModalFooter justifyContent="center" pt="46px" pb="48px">
            {(isAllowedToUpdate || !defKeys.length) && (
              <ThemedButton
                isDisabled={isSubmitting || isDeleting}
                type="submit"
                height="55px"
                width="158px"
              >
                {isAllowedToUpdate && !!defKeys.length && isSubmitting && (
                  <Spinner
                    thickness="2px"
                    speed="0.65s"
                    emptyColor="gray.200"
                    color="blue.500"
                    size="sm"
                  />
                )}
                {isAllowedToUpdate && !!defKeys.length && !isSubmitting && (
                  <Text as="span">Update</Text>
                )}
                {!defKeys.length && isSubmitting && (
                  <Spinner
                    thickness="2px"
                    speed="0.65s"
                    emptyColor="gray.200"
                    color="blue.500"
                    size="sm"
                  />
                )}
                {!defKeys.length && !isSubmitting && (
                  <Text as="span">Submit</Text>
                )}
              </ThemedButton>
            )}
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  )
}

export default CreateExhibitorBoatProductsForm
