import { FiHome, FiCalendar, FiBriefcase } from 'react-icons/fi'
import { CompanyTypeEnum } from '../interfaces'

const links = [
  {
    label: 'Dashboard',
    url: '/',
    icon: <FiHome color="white" fontSize="20px" />,
    private: true,
  },
  {
    label: 'Shows',
    url: '/shows',
    icon: <FiCalendar color="white" fontSize="20px" />,
    private: true,
    subCategories: [
      {
        label: 'Pending Exhibitors',
        url: '/pending-exhibitors',
        icon: <FiCalendar color="white" fontSize="20px" />,
        private: true,
      },
    ],
  },
  {
    label: 'Company',
    url: '/company',
    icon: <FiBriefcase color="white" fontSize="20px" />,
    private: false,
    subCategories: [
      {
        label: 'Pending Enhanced Boat Cards',
        url: '/pending-enhanced-boat-cards',
        icon: <FiCalendar color="white" fontSize="20px" />,
        private: true,
      },
    ],
  },
]

export const companyLinks = [
  {
    label: 'Contacts',
    url: '/contacts',
    isHidden: true,
  },
  {
    label: 'Add Boats',
    url: '/add-boats',
    companyTypes: [CompanyTypeEnum.BRAND],
  },
  {
    label: 'My Exhibit Space',
    url: '/my-exhibit-space',
  },
  {
    label: 'My Boats at the Show',
    url: '/my-boats-at-the-show',
  },
  {
    label: 'Add Products & Services',
    url: '/add-products-services',
    isDisabled: true,
  },
  {
    label: 'My Products & Services',
    url: '/my-products-services',
    isDisabled: true,
  },
]

export default links
