import { Switch, Route, useHistory } from 'react-router-dom'
import { useEffect } from 'react'

import Dashboard from './pages/Dashboard'
import Shows from './pages/Shows'
import Company from './pages/Company/index'
import CompanyDetails from './pages/Company/Details'
import ErrorHandler from './components/shared/ErrorHandler'
import NotFoundPage from './pages/404'
import Login from './pages/Login'
import { useAuthentication } from './hooks'
import PendingExhibitors from './pages/PendingExhibitors'
import PendingEnhancedBoatCards from './pages/PendingEnhancedBoatCards'

const App: React.FC = () => {
  const history = useHistory()
  // const location = useLocation()
  const { isAuthenticated, authenticateUserSession } = useAuthentication()
  const urlParams = new URLSearchParams(window.location.search)
  const companyToken = urlParams.get('t')

  // useEffect(() => {
  //   const authenticatedRoutes = [
  //     '/',
  //     '/login',
  //     '/shows',
  //     '/company',
  //     '/company/pending-enhanced-boat-cards',
  //   ]

  //   if (
  //     !isAuthenticated &&
  //     authenticatedRoutes.find(r => r === location.pathname)
  //   ) {
  //     history.push('/login')
  //   }
  // }, [isAuthenticated, companyToken])

  useEffect(() => {
    const checkUserSession = async () => {
      const session = await authenticateUserSession()
      if (!session && !companyToken) {
        history.push('/login')
      }
    }

    checkUserSession()
  }, [])

  return (
    <ErrorHandler>
      <Switch>
        <Route exact path="/">
          <Dashboard />
        </Route>
        <Route exact path="/login">
          <Login />
        </Route>
        <Route exact path="/shows">
          <Shows />
        </Route>
        <Route exact path="/shows/pending-exhibitors">
          <PendingExhibitors />
        </Route>
        <Route exact path="/company">
          <Company />
        </Route>

        {isAuthenticated && (
          <Route exact path="/company/pending-enhanced-boat-cards">
            <PendingEnhancedBoatCards />
          </Route>
        )}

        <Route path="/company/:id">
          <CompanyDetails />
        </Route>

        <Route>
          <NotFoundPage />
        </Route>
      </Switch>
    </ErrorHandler>
  )
}
export default App
