/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Box,
  Grid,
  useToast,
  Spinner,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Checkbox,
  Flex,
  Switch,
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { AxiosResponse } from 'axios'
import { Controller, useForm } from 'react-hook-form'
import useDeepCompareEffect from 'use-deep-compare-effect'
import * as yup from 'yup'
import { useEffect, useState, useRef } from 'react'

import { BASE_URL } from '../../../config'
import {
  CompanyTypeEnum,
  ThemedSelectItem,
  YesNoEnum,
  StatusEnum,
} from '../../../interfaces'
import { useAuthentication, useBoatTypes, useCompany } from '../../../hooks'
import {
  ThemedButton,
  ThemedDragDrop,
  ThemedFormErrorMessage,
  ThemedInput,
  ThemedLabel,
  ThemedModalTrashButton,
  ThemedSelect,
  ThemedTextarea,
  ColorPicker,
} from '../../shared'
import { uploadPhotoToS3, yesOrNo } from '../../../utils'
import ThemedFormHelperMessage from '../../shared/ThemedFormHelperMessage'
import { httpPost } from '../../../fetchers'

type CreateBoatProductFormProps = {
  isOpen: boolean
  onClose: () => void
  mutate?: () => Promise<AxiosResponse<any> | undefined>
  companyId?: string | undefined
  boatTypes?: ThemedSelectItem[]
  defaultValues?: Record<string, any>
  isAllowedToUpdate?: boolean
  isCertified?: boolean
  isPendingEbc?: boolean
  viewOnly?: boolean
}

interface CustomFile extends File {
  fileName: string
  fileNameHashed: string
}

interface BPFormData {
  companyId: string
  productName: string
  basicGallery: CustomFile[]
  ebcGallery: CustomFile[]
  certifiedFlag: ThemedSelectItem
  description: string
  ft: string
  inches: string
  modelYear: ThemedSelectItem | null
  propulsionType: ThemedSelectItem | null
  boatType: ThemedSelectItem | null
  sourceProductCode: string
  startingPrice: string
  used: boolean
  youtubeUrl: string
  beamFt: string
  beamIn: string
  draftFt: string
  draftIn: string
  brandListingUrl: string
  maxPassengerCapacity: string
  boatWeight: string
  maxWeightCapacity: string
  maxBridgeClearanceFt: string
  maxBridgeClearanceIn: string
  hullMaterial: ThemedSelectItem
  hullColor: string
  otherHullColor1: string
  otherHullColor2: string
  otherHullColor3: string
  otherHullColor4: string
  propulsionManufacturer: string
  fuelType: ThemedSelectItem
  maxHorsePower: string
  maxFuelCapacity: string
  numberOfEngines: string
  enhancedBoatCard: boolean
  enhancedDescription: string
  published: boolean
  status: ThemedSelectItem
  futureShows: IFutureShows
  agreedTOS: boolean
  cloneCount: number
  agreedBoatFinderTOS: boolean
  length: number
}

interface IFutureShows {
  Nashville: boolean
  Chicago: boolean
  Atlanta: boolean
  Minneapolis: boolean
  'New York': boolean
  Louisville: boolean
  Miami: boolean
  'New England': boolean
  'Atlantic City': boolean
  Northwest: boolean
  Norwalk: boolean
}

type TFutureShows =
  | 'Nashville'
  | 'Chicago'
  | 'Atlanta'
  | 'Minneapolis'
  | 'New York'
  | 'Louisville'
  | 'Miami'
  | 'New England'
  | 'Atlantic City'
  | 'Northwest'
  | 'Norwalk'

const boatProductSchema = yup.object().shape({
  productName: yup.string().required('Required.'),
  boatType: yup.object().required('Required.'),
  propulsionType: yup.object().required('Required.'),
  basicGallery: yup.array().test({
    message: 'Please provide an image to upload.',
    test: arr => {
      if (arr?.length) {
        return arr?.length > 0
      }
      return false
    },
  }),
  ebcGallery: yup.array().when('enhancedBoatCard', {
    is: true,
    then: yup.array().test({
      message: 'Please provide an image to upload.',
      test: arr => {
        if (arr?.length) {
          return arr?.length > 0
        }
        return false
      },
    }),
  }),
  description: yup.string(),
  length: yup.number().required('Required.').moreThan(0, 'Required.'),
  modelYear: yup.object().required('Required.'),
  sourceProductCode: yup.string(),
  startingPrice: yup.string().required('Required.'),
  used: yup.boolean(),
  youtubeUrl: yup
    .string()
    .nullable()
    .matches(
      /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/,
      {
        message: 'Enter a valid Youtube video URL',
        excludeEmptyString: true,
      }
    ),
  brandListingUrl: yup.string().url().nullable(),
  maxPassengerCapacity: yup.string().nullable(),
  boatWeight: yup.string().nullable(),
  maxWeightCapacity: yup.string().nullable(),
  maxBridgeClearance: yup.string().nullable(),
  hullColor: yup.string().nullable(),
  otherHullColor1: yup.string().nullable(),
  otherHullColor2: yup.string().nullable(),
  otherHullColor3: yup.string().nullable(),
  otherHullColor4: yup.string().nullable(),
  propulsionManufacturer: yup.string().nullable(),
  maxHorsePower: yup.string().nullable(),
  maxFuelCapacity: yup.string().nullable(),
  numberOfEngines: yup.string().nullable(),
  enhancedBoatCard: yup.boolean().nullable(),
  enhancedDescription: yup.string().nullable(),
  published: yup.boolean().nullable(),
  futureShows: yup.object().nullable(),
  agreedTOS: yup.boolean().when('enhancedBoatCard', {
    is: true,
    then: yup.boolean().oneOf([true], 'Must agree to Terms and Conditions.'),
  }),
  agreedBoatFinderTOS: yup.boolean().when('enhancedBoatCard', {
    is: false,
    then: yup.boolean().oneOf([true], 'Must agree to Terms and Conditions.'),
  }),
})

const options = {
  propulsionType: [
    {
      value: 'Hybrid or Electric',
      label: 'Hybrid or Electric',
    },
    {
      value: 'Inboard',
      label: 'Inboard',
    },
    {
      value: 'Jet',
      label: 'Jet',
    },
    {
      value: 'Manual',
      label: 'Manual',
    },
    {
      value: 'Outboard',
      label: 'Outboard',
    },
    {
      value: 'Outboard or Stern Drive',
      label: 'Outboard or Stern Drive',
    },
    {
      value: 'Sail',
      label: 'Sail',
    },
    {
      value: 'Stern Drive',
      label: 'Stern Drive',
    },
  ],
  yesOrNo: [
    { value: true, label: YesNoEnum.YES },
    { value: false, label: YesNoEnum.NO },
  ],
  fuelType: [
    { value: '', label: 'Select...' },
    {
      value: 'Gasoline',
      label: 'Gasoline',
    },
    {
      value: 'Diesel',
      label: 'Diesel',
    },
  ],
  hullMaterial: [
    { value: '', label: 'Select...' },
    {
      value: 'Ferro-cement',
      label: 'Ferro-cement',
    },
    {
      value: 'Wood',
      label: 'Wood',
    },
    {
      value: 'Steel',
      label: 'Steel',
    },
    {
      value: 'Aluminum',
      label: 'Aluminum',
    },
    {
      value: 'Fiberglass',
      label: 'Fiberglass',
    },
    {
      value: 'Other',
      label: 'Other',
    },
  ],
  status: [
    { value: StatusEnum.APPROVED_EBC, label: StatusEnum.APPROVED_EBC },
    { value: StatusEnum.PENDING_EBC, label: StatusEnum.PENDING_EBC },
  ],
}

const futureShows = [
  {
    value: 'Nashville',
    label: 'Nashville $300 Member/$600 Non-Member',
  },
  {
    value: 'Chicago',
    label: 'Chicago $300 Member/$600 Non- Member',
  },
  {
    value: 'Atlanta',
    label: 'Atlanta $300 Member/$600 Non-Member',
  },
  {
    value: 'Minneapolis',
    label: 'Minneapolis $300 Member/$600 Non-Member',
  },
  {
    value: 'New York',
    label: 'New York $300 Member/$600 Non-Member',
  },
  {
    value: 'Louisville',
    label: 'Louisville $300 Member/$600 Non-Member',
  },
  {
    value: 'Miami',
    label: 'Miami $600 Member/$1,200 Non-Member',
  },
  {
    value: 'New England',
    label: 'New England $300 Member/$600 Non-Member',
  },
  {
    value: 'Atlantic City',
    label: 'Atlantic City $300 Member/$600 Non-Member',
  },
  {
    value: 'Northwest',
    label: 'Northwest $300 Member/$600 Non-Member',
  },
  {
    value: 'Norwalk',
    label: 'Norwalk $300 Member/$600 Non-Member',
  },
]

const years = Array.from({
  length: new Date().getFullYear() + 2 - 2009,
})
  .map((v, i) => ({ label: `${2010 + i}`, value: 2010 + i }))
  .sort((curr, next) => next.value - curr.value)

const CreateBoatProductForm: React.FC<CreateBoatProductFormProps> = ({
  isOpen,
  onClose: onFormClose,
  mutate,
  companyId,
  defaultValues = {},
  isCertified,
  isPendingEbc,
  viewOnly,
}) => {
  const toast = useToast()
  const { boatTypes } = useBoatTypes()
  const { companyRecord } = useCompany()

  const {
    register,
    control,
    handleSubmit,
    setValue,
    clearErrors,
    watch,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<BPFormData>({
    defaultValues: {
      companyId,
      basicGallery: [],
      ebcGallery: [],
      used: false,
      description: '',
      length: 0,
      ft: '0',
      inches: '0',
      youtubeUrl: '',
      brandListingUrl: '',
      draftFt: '0',
      draftIn: '0',
      beamFt: '0',
      beamIn: '0',
      maxPassengerCapacity: '0',
      boatWeight: '0',
      maxWeightCapacity: '0',
      maxBridgeClearanceFt: '0',
      maxBridgeClearanceIn: '0',
      hullColor: '',
      otherHullColor1: '',
      otherHullColor2: '',
      otherHullColor3: '',
      otherHullColor4: '',
      propulsionManufacturer: '',
      maxHorsePower: '0',
      maxFuelCapacity: '0',
      numberOfEngines: '0',
      enhancedBoatCard: false,
      enhancedDescription: '',
      published: false,
      futureShows: {
        Nashville: false,
        Chicago: false,
        Atlanta: false,
        Minneapolis: false,
        'New York': false,
        Louisville: false,
        Miami: false,
        'New England': false,
        'Atlantic City': false,
        Northwest: false,
      },
      agreedTOS: false,
      agreedBoatFinderTOS: false,
      cloneCount: 0,
    },
    resolver: yupResolver(boatProductSchema),
  })
  const [hasImageChanged, setImageChanged] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)
  const { isAuthenticated } = useAuthentication()

  const defKeys = Object.keys(defaultValues)

  const boatTypeRef = useRef(null)

  const onClose = () => {
    clearErrors('basicGallery')
    clearErrors('ebcGallery')
    setIsDeleting(false)
    setImageChanged(false)

    reset()
    onFormClose()
  }

  const onSubmit = async (data: BPFormData) => {
    const forUpdate = defKeys.length
    try {
      await submitForNultipleImage(data)
    } catch (err) {
      const errResponse = (err as any)?.response

      if (errResponse) {
        const { code } = errResponse.data

        if (code === 'ConditionalCheckFailedException') {
          toast({
            title: `Failed to ${
              forUpdate ? 'update' : 'create'
            } a boat product.`,
            description:
              'There is an existing boat product with the same name.',
            status: 'error',
            duration: 5000,
            isClosable: true,
          })
        } else {
          toast({
            title: `Failed to ${
              forUpdate ? 'update' : 'create'
            } a boat product.`,
            description: errResponse.data.message,
            status: 'error',
            duration: 2000,
            isClosable: true,
          })
        }
      } else {
        console.error(err)
      }
    }
  }

  const submitForNultipleImage = async (data: BPFormData) => {
    const forUpdate = defKeys.length
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const {
      basicGallery,
      ebcGallery,
      propulsionType,
      modelYear,
      boatType,
      status,
      ...rest
    } = data

    const filteredEbcGalleryImages = ebcGallery.filter(
      image => image.fileNameHashed === undefined
    )

    const filteredBasicGallery = basicGallery.filter(
      image => image.fileNameHashed === undefined
    )

    await Promise.all([
      await Promise.all(
        filteredEbcGalleryImages.map(async image => presignImages(image))
      ),
      await Promise.all(
        filteredBasicGallery.map(async image => presignImages(image))
      ),
    ]).then(async fileResponses => {
      const ebcFileResponse = fileResponses[0]
      const basicFileResponse = fileResponses[1]

      const { ebcGallery, basicGallery } = data

      const modifiedEbcFileResponse = ebcFileResponse.map(file => ({
        fileName: file?.fileName,
        fileNameHashed: file?.fileNameHashed,
      }))

      const modifiedBasicFileResponse = basicFileResponse.map(file => ({
        fileName: file?.fileName,
        fileNameHashed: file?.fileNameHashed,
      }))

      const existingImages: any[] = []
      const existingBasicImages: any[] = []
      const imagesForDelete: any[] = []

      if (forUpdate) {
        const existingEbcImages = filterExistingImages(ebcGallery)
        const existingBasicGallery = filterExistingImages(basicGallery)

        existingImages.push(...existingEbcImages)
        existingBasicImages.push(...existingBasicGallery)

        if (defaultValues.gallery?.length > 0) {
          const forDelete = defaultValues.gallery.filter(
            (image: CustomFile) =>
              !existingImages.some(
                (image2: CustomFile) =>
                  image.fileNameHashed === image2.fileNameHashed
              )
          )

          imagesForDelete.push(...forDelete)
        }

        if (defaultValues.basicGallery?.length > 0) {
          const forDelete = defaultValues.basicGallery.filter(
            (image: CustomFile) =>
              !existingImages.some(
                (image2: CustomFile) =>
                  image.fileNameHashed === image2.fileNameHashed
              )
          )

          imagesForDelete.push(...forDelete)
        }
      }

      const statusCondition =
        status?.value === '' ? options.status[1].value : status?.value

      const values = {
        ...rest,
        fileName: '',
        fileNameHashed: '',
        companyId,
        modelYear: modelYear?.value ? Number(modelYear?.value) : null,
        length: Number(Number(rest.ft) + Number(Number(rest.inches) / 12)),
        startingPrice: Number(rest.startingPrice || 0),
        propulsionType: propulsionType?.value,
        boatType: boatType?.value ? boatType?.value : '',
        certifiedFlag: isCertified,
        ...(forUpdate && { boatProductId: defaultValues.boatProductId }),
        ...(forUpdate &&
          !isAuthenticated && {
            isPendingExhibitorBoatProduct: true,
          }),
        gallery: [...existingImages, ...modifiedEbcFileResponse],
        basicGallery: [...existingBasicImages, ...modifiedBasicFileResponse],
        draft: Number(Number(rest.draftFt) + Number(Number(rest.draftIn) / 12)),
        beam: Number(Number(rest.beamFt) + Number(Number(rest.beamIn) / 12)),
        maxBridgeClearance: Number(
          Number(rest.maxBridgeClearanceFt) +
            Number(Number(rest.maxBridgeClearanceIn) / 12)
        ),
        otherHullColor: [
          rest.otherHullColor1,
          rest.otherHullColor2,
          rest.otherHullColor3,
          rest.otherHullColor4,
        ],
        fuelType: rest.fuelType?.value ? rest.fuelType?.value : '',
        hullMaterial: rest.hullMaterial?.value ? rest.hullMaterial?.value : '',
        maxWeightCapacity: Number(rest.maxWeightCapacity),
        maxPassengerCapacity: Number(rest.maxPassengerCapacity),
        boatWeight: Number(rest.boatWeight),
        maxHorsePower: Number(rest.maxHorsePower),
        maxFuelCapacity: Number(rest.maxFuelCapacity),
        numberOfEngines: Number(rest.numberOfEngines),
        status: rest.enhancedBoatCard ? statusCondition : '',
        cloneCount: rest.cloneCount ? rest.cloneCount : 0,
      }

      const res = await httpPost({
        url: `${BASE_URL}/boat-product/${forUpdate ? 'update' : 'create'}`,
        body: values,
      })

      if (res?.data) {
        const mergedResponses = [...ebcFileResponse, ...basicFileResponse]

        if (imagesForDelete.length > 0 && hasImageChanged) {
          await Promise.all(
            imagesForDelete.map(async image => {
              await httpPost({
                url: `${BASE_URL}/images/delete`,
                body: {
                  fileNameHashed: image.fileNameHashed,
                },
              })
            })
          )
        }

        await Promise.all(
          mergedResponses.map(async file => {
            await uploadPhotoToS3({
              url: file.url,
              file: file.image,
              defaultValues,
            })
          })
        )

        mutate?.().then(() => {
          toast({
            title: `Boat product ${forUpdate ? 'updated' : 'created'}.`,
            description: `The boat product has been ${
              forUpdate ? 'updated' : 'created'
            }.`,
            status: 'success',
            duration: 1500,
            isClosable: true,
          })
        })
        onClose()
      }
    })
  }

  const presignImages = async (image: CustomFile) => {
    const file = {
      fileName: image.name,
      fileType: image.type,
    }

    const presignedResponse = await httpPost({
      url: `${BASE_URL}/getPresignedUrl`,
      body: file,
    })

    return {
      ...presignedResponse.data,
      image,
    }
  }

  const filterExistingImages = (gallery: any[]) =>
    gallery
      .filter(
        (image: { fileNameHashed: undefined }) =>
          image.fileNameHashed !== undefined
      )
      .map((image: { fileNameHashed: any; name: any }) => ({
        fileNameHashed: image.fileNameHashed,
        fileName: image.name,
      }))

  const onDelete = async () => {
    setIsDeleting(true)
    if (defaultValues?.boatProductId) {
      try {
        const res = await httpPost({
          url: `${BASE_URL}/boat-product/delete`,
          body: {
            companyId,
            boatProductId: defaultValues?.boatProductId,
          },
        })

        if (res?.data) {
          if (!defaultValues?.isCloned) {
            if (defaultValues.gallery.length > 0) {
              Promise.all(
                defaultValues.gallery.map(async (image: CustomFile) => {
                  await httpPost({
                    url: `${BASE_URL}/images/delete`,
                    body: {
                      fileNameHashed: image.fileNameHashed,
                    },
                  })
                })
              )
            } else if (defaultValues.basicGallery.length > 0) {
              Promise.all(
                defaultValues.basicGallery.map(async (image: CustomFile) => {
                  await httpPost({
                    url: `${BASE_URL}/images/delete`,
                    body: {
                      fileNameHashed: image.fileNameHashed,
                    },
                  })
                })
              )
            } else {
              await httpPost({
                url: `${BASE_URL}/images/delete`,
                body: {
                  fileNameHashed: defaultValues?.fileNameHashed,
                },
              })
            }
          }

          mutate?.().then(() => {
            toast({
              title: 'Boat product has been deleted.',
              description: 'The boat product has been deleted.',
              status: 'success',
              duration: 1500,
              isClosable: true,
            })
          })
          onClose()
        }
      } catch (err) {
        const errResponse = (err as any)?.response

        toast({
          title: 'Failed to delete a boat product.',
          description: errResponse.data.message,
          status: 'error',
          duration: 6000,
          isClosable: true,
        })
        setIsDeleting(false)
      }
    }
  }

  const watchedFt = watch('ft')
  const watchedIn = watch('inches')
  const imagesField = register('basicGallery')
  const watchedImages = watch('basicGallery')
  const ebcGalleryField = register('ebcGallery')
  const watchedEbcGallery = watch('ebcGallery')
  const watchedBoatType = watch('boatType')
  const watchedEnhancedBoatCard = watch('enhancedBoatCard')
  const watchedFutureShows = watch('futureShows')
  const watchedFutureShowsChanges = Object.values(watchedFutureShows).some(
    show => show === true
  )

  // useDeepCompareEffect(() => {
  //   if (companyId && !watchedBoatType?.value && boatTypes?.length) {
  //     setValue('boatType', boatTypes[0])
  //   }
  // }, [companyId, watchedBoatType, boatTypes])

  useEffect(() => {
    setValue(
      'length',
      Number(Number(watchedFt) + Number(Number(watchedIn) / 12))
    )
  }, [watchedFt, watchedIn])

  useDeepCompareEffect(() => {
    if (watchedImages.length && errors.basicGallery?.message) {
      clearErrors('basicGallery')
    }

    if (watchedEbcGallery.length && errors.ebcGallery?.message) {
      clearErrors('ebcGallery')
    }
  }, [watchedEbcGallery, watchedImages])

  useDeepCompareEffect(() => {
    if (defKeys.length) {
      defKeys.forEach(key => {
        setValue(key as keyof BPFormData, defaultValues[key])
      })

      if (defaultValues?.length) {
        setValue('ft', `${Math.floor(defaultValues.length)}`)
        setValue(
          'inches',
          `${Number(
            Number(
              (defaultValues?.length - Math.floor(defaultValues.length)) * 12
            ).toFixed(2)
          )}`
        )
      }

      if (defaultValues?.maxBridgeClearance === 0) {
        setValue('maxBridgeClearanceFt', '0')
        setValue('maxBridgeClearanceIn', '0')
      } else if (defaultValues?.maxBridgeClearance) {
        setValue(
          'maxBridgeClearanceFt',
          `${Math.floor(defaultValues.maxBridgeClearance)}`
        )
        setValue(
          'maxBridgeClearanceIn',
          `${Number(
            Number(
              (defaultValues?.maxBridgeClearance -
                Math.floor(defaultValues.maxBridgeClearance)) *
                12
            ).toFixed(2)
          )}`
        )
      }

      if (defaultValues?.beam === 0) {
        setValue('beamFt', '0')
        setValue('beamIn', '0')
      } else if (defaultValues?.beam) {
        setValue('beamFt', `${Math.floor(defaultValues.beam)}`)
        setValue(
          'beamIn',
          `${Number(
            Number(
              (defaultValues?.beam - Math.floor(defaultValues.beam)) * 12
            ).toFixed(2)
          )}`
        )
      }

      if (defaultValues?.draft === 0) {
        setValue('draftFt', '0')
        setValue('draftFt', '0')
      } else if (defaultValues?.draft) {
        setValue('draftFt', `${Math.floor(defaultValues.draft)}`)
        setValue(
          'draftIn',
          `${Number(
            Number(
              (defaultValues?.draft - Math.floor(defaultValues.draft)) * 12
            ).toFixed(2)
          )}`
        )
      }
    } else {
      reset()
    }
  }, [defaultValues])

  const FutureShowCheckbox = ({
    label,
    value,
  }: {
    label: string
    value: TFutureShows
  }) => (
    <Box>
      <Checkbox
        disabled={
          defaultValues?.status?.value === 'Approved EBC' && !isAuthenticated
        }
        style={{
          display: 'flex',
          alignItems: 'flex-start',
        }}
        {...register(`futureShows.${value}`)}
        defaultValue={defaultValues?.futureShows?.[value]}
        onChange={e => {
          if (e.target.checked) {
            setValue('futureShows', { ...watchedFutureShows, [value]: true })
          } else {
            setValue('futureShows', { ...watchedFutureShows, [value]: false })
          }
        }}
      >
        <ThemedLabel htmlFor={value} fontWeight="500">
          {label}
        </ThemedLabel>
      </Checkbox>
    </Box>
  )

  return (
    <Modal
      isOpen={isOpen}
      closeOnOverlayClick={false}
      onClose={onClose}
      onCloseComplete={reset}
    >
      <ModalOverlay />
      <ModalContent p="0px" minW="700px">
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalHeader textAlign="center" pt="50px">
            <Text as="span" fontWeight="600" fontSize="32px">
              {defKeys.length ? 'Boat Details' : 'Add a Boat'}
            </Text>
          </ModalHeader>
          {!!defKeys.length && !viewOnly && (
            <ThemedModalTrashButton
              onClick={onDelete}
              isDeleting={isDeleting}
              entity="boat-product"
            />
          )}
          <ModalCloseButton
            color="mediumGray"
            marginTop="15px"
            marginRight="10px"
            isDisabled={isSubmitting || isDeleting}
          />
          <ModalBody paddingLeft="32px" paddingRight="32px">
            <Grid
              mt="20px"
              templateColumns="repeat(2,1fr)"
              autoColumns="max-content"
              autoRows="auto"
              rowGap="18px"
              columnGap="16px"
            >
              {!!defKeys.length && (
                <Box gridColumn="2/3">
                  <Flex alignItems="center" justifyContent="flex-end">
                    <ThemedLabel
                      htmlFor="published"
                      marginRight="10px"
                      fontSize={18}
                    >
                      Published
                    </ThemedLabel>

                    <Switch
                      id="published"
                      colorScheme="green"
                      size="lg"
                      {...register('published')}
                      defaultValue={defaultValues.published}
                      disabled={viewOnly}
                    />
                  </Flex>
                </Box>
              )}

              <Box gridColumn="1/3">
                <Text as="span" fontSize="13px">
                  * indicates a required field.
                </Text>
              </Box>
              <Box>
                <ThemedLabel htmlFor="productName">Product Name *</ThemedLabel>
                <ThemedInput
                  id="productName"
                  {...register('productName')}
                  isDisabled={viewOnly}
                />
                <ThemedFormErrorMessage>
                  {errors.productName?.message}
                </ThemedFormErrorMessage>
              </Box>
              <Box>
                <ThemedLabel htmlFor="sourceProductCode">
                  Source Product Code
                </ThemedLabel>
                <ThemedInput
                  id="sourceProductCode"
                  {...register('sourceProductCode')}
                  isDisabled={viewOnly}
                />
                {errors.sourceProductCode?.message ? (
                  <ThemedFormErrorMessage>
                    {errors.sourceProductCode?.message}
                  </ThemedFormErrorMessage>
                ) : (
                  <ThemedFormHelperMessage mt="4px">
                    Internal Use Only. Product SKU or Unique Identifier
                  </ThemedFormHelperMessage>
                )}
              </Box>

              <Box gridColumn="1/3">
                <ThemedLabel htmlFor="basicGallery">Upload Image *</ThemedLabel>
                <ThemedDragDrop
                  id="basicGallery"
                  {...imagesField}
                  ref={imagesField.ref}
                  setValue={setValue}
                  fileNameHashed={defaultValues?.fileNameHashed}
                  fileName={defaultValues.fileName}
                  hasImageChanged={hasImageChanged}
                  setImageChanged={setImageChanged}
                  isSingleUpload
                  gallery={defaultValues.basicGallery}
                  isDisabled={viewOnly}
                />
                <ThemedFormErrorMessage>
                  {errors.basicGallery?.message}
                </ThemedFormErrorMessage>
              </Box>

              <Box gridColumn="1/3">
                <Text as="span" fontWeight="600" fontSize="18px">
                  Basic Boat Card Options
                </Text>
              </Box>
              <Box>
                <ThemedLabel htmlFor="boatType">Boat Type *</ThemedLabel>
                <Controller
                  control={control}
                  name="boatType"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <ThemedSelect
                      id="boatType"
                      options={boatTypes || []}
                      isSearchable
                      value={defaultValues?.boatType}
                      maxWidthOptions={280}
                      onBlur={onBlur}
                      onChange={onChange}
                      checked={value}
                      inputRef={ref}
                      isDisabled={viewOnly}
                    />
                  )}
                />
                <ThemedFormErrorMessage>
                  {errors.boatType?.message}
                </ThemedFormErrorMessage>
              </Box>
              <Box>
                <ThemedLabel htmlFor="modelYear">Model Year *</ThemedLabel>

                <Controller
                  control={control}
                  name="modelYear"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <ThemedSelect
                      id="modelYear"
                      options={years}
                      isSearchable
                      defaultValue={defaultValues?.modelYear}
                      maxWidthOptions={280}
                      onBlur={onBlur}
                      onChange={onChange}
                      checked={value}
                      inputRef={ref}
                      isDisabled={viewOnly}
                    />
                  )}
                />

                <ThemedFormErrorMessage>
                  {errors.modelYear?.message}
                </ThemedFormErrorMessage>
              </Box>
              <Box>
                <ThemedLabel htmlFor="length">Length *</ThemedLabel>
                <Flex>
                  <InputGroup maxW="100px" mr="8px">
                    <ThemedInput
                      id="ft"
                      isDisabled={viewOnly}
                      pr="36px"
                      {...register('ft')}
                      type="number"
                      pattern="^\d*(\d{0,2})?$"
                      step="1"
                      onKeyDown={e => {
                        if (
                          e.key === '-' ||
                          e.key === '+' ||
                          e.key === 'e' ||
                          e.key === 'E' ||
                          e.key === '.'
                        ) {
                          e.preventDefault()
                          return false
                        }
                        return true
                      }}
                      onBlur={() => {
                        if (
                          Number(watch('ft')) < 1 ||
                          Number.isNaN(Number(watch('ft')))
                        ) {
                          setValue('ft', '0')
                          setValue('inches', '0')
                        } else if (Number(watch('ft')) >= 100) {
                          setValue('ft', '100')
                          setValue('inches', '0')
                        }
                      }}
                    />
                    <InputRightElement
                      pointerEvents="none"
                      color="darkGray"
                      fontSize="md"
                      mt="5px"
                      zIndex="0"
                    >
                      ft
                    </InputRightElement>
                  </InputGroup>
                  <InputGroup maxW="100px">
                    <ThemedInput
                      id="inches"
                      isDisabled={viewOnly}
                      {...register('inches')}
                      type="number"
                      pattern="^\d*(\.\d{0,2})?$"
                      step=".01"
                      pr="36px"
                      onKeyDown={e => {
                        if (
                          e.key === '-' ||
                          e.key === '+' ||
                          e.key === 'e' ||
                          e.key === 'E'
                        ) {
                          e.preventDefault()
                          return false
                        }
                        return true
                      }}
                      onBlur={() => {
                        const inches = Number(watch('inches'))

                        if (inches <= 0 || Number.isNaN(inches)) {
                          setValue('inches', '0')
                        } else if (inches >= 12) {
                          setValue('inches', '12')
                        }
                      }}
                    />
                    <InputRightElement
                      pointerEvents="none"
                      color="darkGray"
                      fontSize="md"
                      mt="5px"
                      zIndex="0"
                    >
                      in
                    </InputRightElement>
                  </InputGroup>
                </Flex>
                <ThemedFormHelperMessage mt="4px">
                  1 ft = 12 inches
                </ThemedFormHelperMessage>
                <ThemedFormErrorMessage>
                  {errors.length?.message}
                </ThemedFormErrorMessage>
              </Box>

              <Box>
                <ThemedLabel htmlFor="propulsionType">
                  Propulsion Type *
                </ThemedLabel>
                <Controller
                  control={control}
                  name="propulsionType"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <ThemedSelect
                      id="propulsionType"
                      isDisabled={viewOnly}
                      options={options.propulsionType}
                      isSearchable
                      defaultValue={defaultValues?.propulsionType}
                      maxWidthOptions={280}
                      onBlur={onBlur}
                      onChange={onChange}
                      checked={value}
                      inputRef={ref}
                    />
                  )}
                />
                <ThemedFormErrorMessage>
                  {errors.propulsionType?.message}
                </ThemedFormErrorMessage>
              </Box>

              <Box>
                <ThemedLabel htmlFor="certifiedFlag">
                  Certified Flag
                </ThemedLabel>
                <ThemedInput value={yesOrNo(isCertified ?? '')} isDisabled />
              </Box>

              <Box>
                <ThemedLabel htmlFor="startingPrice">
                  Starting Price *
                </ThemedLabel>

                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    color="darkGray"
                    fontSize="md"
                    mt="5px"
                    zIndex="0"
                  >
                    $
                  </InputLeftElement>
                  <ThemedInput
                    id="startingPrice"
                    isDisabled={viewOnly}
                    {...register('startingPrice')}
                    type="number"
                    pattern="^\d*(\.\d{0,2})?$"
                    step=".01"
                    pl="2rem"
                    min={0}
                    onKeyDown={e => {
                      if (
                        e.key === '-' ||
                        e.key === '+' ||
                        e.key === 'e' ||
                        e.key === 'E'
                      ) {
                        e.preventDefault()
                        return false
                      }
                      return true
                    }}
                  />
                </InputGroup>

                <ThemedFormErrorMessage>
                  {errors.startingPrice?.message}
                </ThemedFormErrorMessage>
              </Box>

              <Box gridColumn="1/3" display="flex">
                <Box>
                  <Checkbox
                    id="used"
                    {...register('used')}
                    defaultValue={defaultValues.used}
                    disabled={viewOnly}
                  >
                    <ThemedLabel htmlFor="used" mt="6px">
                      Used / Brokerage
                    </ThemedLabel>
                  </Checkbox>
                </Box>
              </Box>
              {companyRecord?.record?.type !== CompanyTypeEnum.BRAND && (
                <Box gridColumn="1 / 3">
                  <ThemedLabel htmlFor="description">Description</ThemedLabel>
                  <ThemedTextarea
                    id="description"
                    {...register('description')}
                    isDisabled={viewOnly}
                  />
                  <ThemedFormErrorMessage>
                    {errors.description?.message}
                  </ThemedFormErrorMessage>
                </Box>
              )}

              <Box gridColumn="1/3">
                <Box>
                  <Checkbox
                    id="enhancedBoatCard"
                    isDisabled={
                      (defaultValues?.status?.value === 'Approved EBC' &&
                        !isAuthenticated) ||
                      viewOnly
                    }
                    {...register('enhancedBoatCard')}
                    defaultValue={defaultValues.enhancedBoatCard}
                    onChange={e => {
                      if (e.target.checked) {
                        setValue('status', options.status[1])
                        setValue('enhancedBoatCard', true)
                      } else {
                        setValue('status', { label: '', value: '' })
                        setValue('enhancedBoatCard', false)
                      }
                    }}
                  >
                    <ThemedLabel htmlFor="enhancedBoatCard" mt="6px">
                      Enhanced Boat Card
                    </ThemedLabel>
                  </Checkbox>
                </Box>
              </Box>

              {!watchedEnhancedBoatCard && (
                <Box gridColumn="1/3">
                  <Text as="span" fontSize="13px">
                    Participation in NMMA’s Boat Finder platform, including both
                    Basic and Enhanced Boat Cards, is governed by the terms of
                    the{' '}
                    <a
                      href="https://uploads-ssl.webflow.com/61d73719ecbb6d15f52e61c8/633cc3d449e318d17dcafeb6_TermsConditions_BoatFinderContract.pdf"
                      style={{ textDecoration: 'underline', color: '#3366CC' }}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Boat Finder Terms & Conditions
                    </a>{' '}
                    (“Terms”). Please carefully review the Terms and indicate
                    your acceptance below.
                  </Text>

                  <Checkbox
                    id="agreedBoatFinderTOS"
                    disabled={
                      defaultValues?.agreedBoatFinderTOS === true &&
                      !isAuthenticated
                    }
                    mt={4}
                    style={{
                      display: 'flex',
                      alignItems: 'flex-start',
                    }}
                    {...register('agreedBoatFinderTOS')}
                    defaultValue={defaultValues.agreedBoatFinderTOS}
                    onChange={e => {
                      if (e.target.checked) {
                        setValue('agreedBoatFinderTOS', true)
                      } else {
                        setValue('agreedBoatFinderTOS', false)
                      }
                    }}
                  >
                    <ThemedLabel htmlFor="agreedBoatFinderTOS">
                      <Text as="span" fontWeight="500" fontSize="13px">
                        By checking this box, you indicate that you have
                        reviewed and agree (on behalf of the company) to be
                        bound by the{' '}
                        <a
                          href="https://uploads-ssl.webflow.com/61d73719ecbb6d15f52e61c8/633cc3d449e318d17dcafeb6_TermsConditions_BoatFinderContract.pdf"
                          style={{
                            textDecoration: 'underline',
                            color: '#3366CC',
                          }}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Terms
                        </a>{' '}
                        and are authorized to bind the company accordingly.
                      </Text>
                    </ThemedLabel>
                  </Checkbox>

                  <ThemedFormErrorMessage>
                    {errors.agreedBoatFinderTOS?.message}
                  </ThemedFormErrorMessage>
                </Box>
              )}
            </Grid>

            {watchedEnhancedBoatCard && (
              <>
                <Grid
                  mt="20px"
                  templateColumns="repeat(2,1fr)"
                  autoColumns="max-content"
                  autoRows="auto"
                  rowGap="18px"
                  columnGap="16px"
                >
                  <Box gridColumn="1/3" my={4}>
                    <Text as="span" fontWeight="600" fontSize="16px" mb={4}>
                      Upgrade to Enhanced Boat Card at the following shows: *
                    </Text>

                    <Grid
                      mt="20px"
                      templateColumns="repeat(2,1fr)"
                      autoColumns="max-content"
                      autoRows="auto"
                      rowGap="18px"
                      columnGap="16px"
                    >
                      {futureShows.map(({ label, value }: any) => (
                        <FutureShowCheckbox
                          label={label}
                          value={value}
                          key={value}
                        />
                      ))}
                    </Grid>
                  </Box>

                  <Box gridColumn="1/3" mb={4}>
                    <Text fontSize="13px">
                      A minimum spend of $1500 member/$3000 non-member is
                      required{' '}
                      <span
                        style={{
                          color: 'red',
                        }}
                      >
                        for each show selected
                      </span>
                      , except for Miami which requires a minimum spend of $3000
                      member/$6000 non-member.
                    </Text>
                    <Text fontSize="13px" mt={2}>
                      <a
                        href="https://uploads-ssl.webflow.com/61d73719ecbb6d15f52e61c8/633f4859a0e0b93061648a4e_BoatFinderEBC_PlanningForm.pdf"
                        style={{
                          textDecoration: 'underline',
                          color: '#3366CC',
                        }}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Click Here
                      </a>{' '}
                      to view pricing details and to use our planning form that
                      can help you determine your investment. Initial invoice
                      will reflect minimum for all shows selected.
                    </Text>
                  </Box>
                </Grid>

                {watchedFutureShowsChanges && (
                  <>
                    <Grid
                      mt="20px"
                      templateColumns="repeat(2,1fr)"
                      autoColumns="max-content"
                      autoRows="auto"
                      rowGap="18px"
                      columnGap="16px"
                    >
                      {isAuthenticated && (
                        <>
                          <Box gridColumn="1/3">
                            <ThemedLabel htmlFor="status">Status</ThemedLabel>
                            <Controller
                              control={control}
                              name="status"
                              render={({
                                field: { onChange, onBlur, value, ref },
                              }) => (
                                <ThemedSelect
                                  id="status"
                                  isDisabled={viewOnly}
                                  options={options.status}
                                  isSearchable={false}
                                  defaultValue={
                                    defaultValues?.status?.value !== ''
                                      ? defaultValues?.status
                                      : options?.status[1]
                                  }
                                  // isDisabled={!isAllowedToUpdate}
                                  maxWidthOptions={675}
                                  onBlur={onBlur}
                                  onChange={onChange}
                                  checked={value}
                                  inputRef={ref}
                                />
                              )}
                            />
                          </Box>
                        </>
                      )}

                      <Box gridColumn="1/3" mt="20px">
                        <Text as="span" fontWeight="600" fontSize="18px">
                          Enhanced Boat Card Options
                        </Text>
                      </Box>

                      <Box gridColumn="1/3">
                        <ThemedLabel htmlFor="brandListingUrl">
                          Brand Listing URL
                        </ThemedLabel>
                        <ThemedInput
                          id="brandListingUrl"
                          {...register('brandListingUrl')}
                          isDisabled={viewOnly}
                        />
                        <ThemedFormHelperMessage mt="4px">
                          Please add your URL that displays this boat model on
                          your website
                        </ThemedFormHelperMessage>
                        <ThemedFormErrorMessage>
                          {errors.brandListingUrl?.message}
                        </ThemedFormErrorMessage>
                      </Box>

                      <Box gridColumn="1/3">
                        <ThemedLabel htmlFor="ebcGallery">
                          Upload Image *
                        </ThemedLabel>
                        <ThemedDragDrop
                          id="ebcGallery"
                          {...ebcGalleryField}
                          ref={ebcGalleryField.ref}
                          setValue={setValue}
                          isMultipleUpload
                          gallery={defaultValues.gallery}
                          isDisabled={viewOnly}
                          isEBC={watchedEnhancedBoatCard}
                        />
                        <ThemedFormErrorMessage>
                          {errors.ebcGallery?.message}
                        </ThemedFormErrorMessage>
                      </Box>

                      <Box gridColumn="1/3">
                        <Box gridColumn="1 / 3">
                          <ThemedLabel htmlFor="enhancedDescription">
                            Description
                          </ThemedLabel>

                          <ThemedTextarea
                            id="enhancedDescription"
                            {...register('enhancedDescription')}
                            isDisabled={viewOnly}
                          />
                          <ThemedFormHelperMessage mt="4px">
                            Please add your product description for this boat
                            model
                          </ThemedFormHelperMessage>
                          <ThemedFormErrorMessage>
                            {errors.enhancedDescription?.message}
                          </ThemedFormErrorMessage>
                        </Box>
                      </Box>

                      <Box>
                        <ThemedLabel htmlFor="youtubeUrl">
                          Youtube Video URL
                        </ThemedLabel>
                        <ThemedInput
                          id="youtubeUrl"
                          {...register('youtubeUrl')}
                          isDisabled={viewOnly}
                        />
                        <ThemedFormHelperMessage mt="4px">
                          Please add your YouTube URL for this boat model
                        </ThemedFormHelperMessage>
                        <ThemedFormErrorMessage>
                          {errors.youtubeUrl?.message}
                        </ThemedFormErrorMessage>
                      </Box>

                      <Box gridColumn="1/3" mt="20px">
                        <Text as="span" fontWeight="600" fontSize="18px">
                          Dimensions
                        </Text>
                      </Box>

                      <Grid
                        gridColumn="1/3"
                        templateColumns="repeat(3,1fr)"
                        autoColumns="max-content"
                        autoRows="auto"
                        rowGap="18px"
                        columnGap="16px"
                      >
                        <Box>
                          <ThemedLabel htmlFor="maxPassengerCapacity">
                            Max Passenger Capacity
                          </ThemedLabel>
                          <InputGroup>
                            <ThemedInput
                              id="maxPassengerCapacity"
                              {...register('maxPassengerCapacity')}
                              isDisabled={viewOnly}
                              type="number"
                              pattern="^\d*(\.\d{0,2})?$"
                              step=".01"
                              pr="36px"
                              onKeyDown={e => {
                                if (
                                  e.key === '-' ||
                                  e.key === '+' ||
                                  e.key === 'e' ||
                                  e.key === 'E'
                                ) {
                                  e.preventDefault()
                                  return false
                                }
                                return true
                              }}
                            />
                          </InputGroup>
                          <ThemedFormErrorMessage>
                            {errors.maxPassengerCapacity?.message}
                          </ThemedFormErrorMessage>
                        </Box>
                        <Box>
                          <ThemedLabel htmlFor="boatWeight">
                            Boat Weight
                          </ThemedLabel>
                          <InputGroup>
                            <ThemedInput
                              id="boatWeight"
                              {...register('boatWeight')}
                              isDisabled={viewOnly}
                              type="number"
                              pattern="^\d*(\.\d{0,2})?$"
                              step=".01"
                              pr="36px"
                              onKeyDown={e => {
                                if (
                                  e.key === '-' ||
                                  e.key === '+' ||
                                  e.key === 'e' ||
                                  e.key === 'E'
                                ) {
                                  e.preventDefault()
                                  return false
                                }
                                return true
                              }}
                            />
                            <InputRightElement
                              pointerEvents="none"
                              color="darkGray"
                              fontSize="md"
                              mt="5px"
                              zIndex="0"
                            >
                              lbs
                            </InputRightElement>
                          </InputGroup>
                          <ThemedFormErrorMessage>
                            {errors.boatWeight?.message}
                          </ThemedFormErrorMessage>
                        </Box>
                        <Box>
                          <ThemedLabel htmlFor="maxWeightCapacity">
                            Max Weight Capacity
                          </ThemedLabel>
                          <InputGroup>
                            <ThemedInput
                              id="maxWeightCapacity"
                              {...register('maxWeightCapacity')}
                              isDisabled={viewOnly}
                              type="number"
                              pattern="^\d*(\.\d{0,2})?$"
                              step=".01"
                              pr="36px"
                              onKeyDown={e => {
                                if (
                                  e.key === '-' ||
                                  e.key === '+' ||
                                  e.key === 'e' ||
                                  e.key === 'E'
                                ) {
                                  e.preventDefault()
                                  return false
                                }
                                return true
                              }}
                            />
                            <InputRightElement
                              pointerEvents="none"
                              color="darkGray"
                              fontSize="md"
                              mt="5px"
                              zIndex="0"
                            >
                              lbs
                            </InputRightElement>
                          </InputGroup>
                          <ThemedFormErrorMessage>
                            {errors.maxWeightCapacity?.message}
                          </ThemedFormErrorMessage>
                        </Box>
                      </Grid>

                      <Grid
                        gridColumn="1/3"
                        mt="20px"
                        templateColumns="repeat(3,1fr)"
                        autoColumns="max-content"
                        autoRows="auto"
                        rowGap="18px"
                        columnGap="16px"
                      >
                        <Box>
                          <ThemedLabel htmlFor="maxBridgeClearance">
                            Max Bridge Clearance
                          </ThemedLabel>
                          <Flex>
                            <InputGroup maxW="100px" mr="8px">
                              <ThemedInput
                                id="maxBridgeClearanceFt"
                                pr="36px"
                                {...register('maxBridgeClearanceFt')}
                                isDisabled={viewOnly}
                                type="number"
                                pattern="^\d*(\d{0,2})?$"
                                step="1"
                                onKeyDown={e => {
                                  if (
                                    e.key === '-' ||
                                    e.key === '+' ||
                                    e.key === 'e' ||
                                    e.key === '.' ||
                                    e.key === 'E'
                                  ) {
                                    e.preventDefault()
                                    return false
                                  }
                                  return true
                                }}
                                onBlur={() => {
                                  if (
                                    Number(watch('maxBridgeClearanceFt')) < 1 ||
                                    Number.isNaN(
                                      Number(watch('maxBridgeClearanceFt'))
                                    )
                                  ) {
                                    setValue('maxBridgeClearanceFt', '0')
                                    setValue('maxBridgeClearanceIn', '0')
                                  } else if (
                                    Number(watch('maxBridgeClearanceFt')) >= 100
                                  ) {
                                    setValue('maxBridgeClearanceFt', '100')
                                    setValue('maxBridgeClearanceIn', '0')
                                  }
                                }}
                              />
                              <InputRightElement
                                pointerEvents="none"
                                color="darkGray"
                                fontSize="md"
                                mt="5px"
                                zIndex="0"
                              >
                                ft
                              </InputRightElement>
                            </InputGroup>

                            <InputGroup maxW="100px">
                              <ThemedInput
                                id="maxBridgeClearanceIn"
                                {...register('maxBridgeClearanceIn')}
                                isDisabled={viewOnly}
                                type="number"
                                pattern="^\d*(\.\d{0,2})?$"
                                step=".01"
                                pr="36px"
                                onKeyDown={e => {
                                  if (
                                    e.key === '-' ||
                                    e.key === '+' ||
                                    e.key === 'e' ||
                                    e.key === 'E'
                                  ) {
                                    e.preventDefault()
                                    return false
                                  }
                                  return true
                                }}
                                onBlur={() => {
                                  const inches = Number(
                                    watch('maxBridgeClearanceIn')
                                  )

                                  if (inches <= 0 || Number.isNaN(inches)) {
                                    setValue('maxBridgeClearanceIn', '0')
                                  } else if (inches >= 12) {
                                    setValue('maxBridgeClearanceIn', '12')
                                  }
                                }}
                              />
                              <InputRightElement
                                pointerEvents="none"
                                color="darkGray"
                                fontSize="md"
                                mt="5px"
                                zIndex="0"
                              >
                                in
                              </InputRightElement>
                            </InputGroup>
                          </Flex>
                          <ThemedFormHelperMessage mt="4px">
                            1 ft = 12 inches
                          </ThemedFormHelperMessage>
                        </Box>

                        <Box>
                          <ThemedLabel htmlFor="length">Beam</ThemedLabel>
                          <Flex>
                            <InputGroup maxW="100px" mr="8px">
                              <ThemedInput
                                id="beamFt"
                                pr="36px"
                                {...register('beamFt')}
                                isDisabled={viewOnly}
                                type="number"
                                pattern="^\d*(\d{0,2})?$"
                                step="1"
                                onKeyDown={e => {
                                  if (
                                    e.key === '-' ||
                                    e.key === '+' ||
                                    e.key === 'e' ||
                                    e.key === '.' ||
                                    e.key === 'E'
                                  ) {
                                    e.preventDefault()
                                    return false
                                  }
                                  return true
                                }}
                                onBlur={() => {
                                  if (
                                    Number(watch('beamFt')) < 1 ||
                                    Number.isNaN(Number(watch('beamFt')))
                                  ) {
                                    setValue('beamFt', '0')
                                    setValue('beamIn', '0')
                                  } else if (Number(watch('beamFt')) >= 100) {
                                    setValue('beamFt', '100')
                                    setValue('beamIn', '0')
                                  }
                                }}
                              />
                              <InputRightElement
                                pointerEvents="none"
                                color="darkGray"
                                fontSize="md"
                                mt="5px"
                                zIndex="0"
                              >
                                ft
                              </InputRightElement>
                            </InputGroup>
                            <InputGroup maxW="100px">
                              <ThemedInput
                                id="beamIn"
                                {...register('beamIn')}
                                isDisabled={viewOnly}
                                type="number"
                                pattern="^\d*(\.\d{0,2})?$"
                                step=".01"
                                pr="36px"
                                onKeyDown={e => {
                                  if (
                                    e.key === '-' ||
                                    e.key === '+' ||
                                    e.key === 'e' ||
                                    e.key === 'E'
                                  ) {
                                    e.preventDefault()
                                    return false
                                  }
                                  return true
                                }}
                                onBlur={() => {
                                  const inches = Number(watch('beamIn'))

                                  if (inches <= 0 || Number.isNaN(inches)) {
                                    setValue('beamIn', '0')
                                  } else if (inches >= 12) {
                                    setValue('beamIn', '12')
                                  }
                                }}
                              />
                              <InputRightElement
                                pointerEvents="none"
                                color="darkGray"
                                fontSize="md"
                                mt="5px"
                                zIndex="0"
                              >
                                in
                              </InputRightElement>
                            </InputGroup>
                          </Flex>
                          <ThemedFormHelperMessage mt="4px">
                            1 ft = 12 inches
                          </ThemedFormHelperMessage>
                        </Box>

                        <Box>
                          <ThemedLabel htmlFor="length">Draft</ThemedLabel>
                          <Flex>
                            <InputGroup maxW="100px" mr="8px">
                              <ThemedInput
                                id="draftFt"
                                pr="36px"
                                {...register('draftFt')}
                                isDisabled={viewOnly}
                                type="number"
                                pattern="^\d*(\d{0,2})?$"
                                step="1"
                                onKeyDown={e => {
                                  if (
                                    e.key === '-' ||
                                    e.key === '+' ||
                                    e.key === 'e' ||
                                    e.key === '.' ||
                                    e.key === 'E'
                                  ) {
                                    e.preventDefault()
                                    return false
                                  }
                                  return true
                                }}
                                onBlur={() => {
                                  if (
                                    Number(watch('draftFt')) < 1 ||
                                    Number.isNaN(Number(watch('draftFt')))
                                  ) {
                                    setValue('draftFt', '0')
                                    setValue('draftIn', '0')
                                  } else if (Number(watch('draftFt')) >= 100) {
                                    setValue('draftFt', '100')
                                    setValue('draftIn', '0')
                                  }
                                }}
                              />
                              <InputRightElement
                                pointerEvents="none"
                                color="darkGray"
                                fontSize="md"
                                mt="5px"
                                zIndex="0"
                              >
                                ft
                              </InputRightElement>
                            </InputGroup>
                            <InputGroup maxW="100px">
                              <ThemedInput
                                id="draftIn"
                                {...register('draftIn')}
                                isDisabled={viewOnly}
                                type="number"
                                pattern="^\d*(\.\d{0,2})?$"
                                step=".01"
                                pr="36px"
                                onKeyDown={e => {
                                  if (
                                    e.key === '-' ||
                                    e.key === '+' ||
                                    e.key === 'e' ||
                                    e.key === 'E'
                                  ) {
                                    e.preventDefault()
                                    return false
                                  }
                                  return true
                                }}
                                onBlur={() => {
                                  const inches = Number(watch('draftIn'))

                                  if (inches <= 0 || Number.isNaN(inches)) {
                                    setValue('draftIn', '0')
                                  } else if (inches >= 12) {
                                    setValue('draftIn', '12')
                                  }
                                }}
                              />
                              <InputRightElement
                                pointerEvents="none"
                                color="darkGray"
                                fontSize="md"
                                mt="5px"
                                zIndex="0"
                              >
                                in
                              </InputRightElement>
                            </InputGroup>
                          </Flex>
                          <ThemedFormHelperMessage mt="4px">
                            1 ft = 12 inches
                          </ThemedFormHelperMessage>
                        </Box>
                      </Grid>
                    </Grid>

                    <Grid
                      mt="40px"
                      templateColumns="repeat(2,1fr)"
                      autoColumns="max-content"
                      autoRows="auto"
                      rowGap="18px"
                      columnGap="16px"
                    >
                      <Box gridColumn="1/3">
                        <Text as="span" fontWeight="600" fontSize="18px">
                          Build
                        </Text>
                      </Box>

                      <Box gridColumn="1/3" maxWidth="300px">
                        <ThemedLabel htmlFor="hullMaterial">
                          Hull Material
                        </ThemedLabel>
                        <Controller
                          control={control}
                          name="hullMaterial"
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => (
                            <ThemedSelect
                              id="hullMaterial"
                              isDisabled={viewOnly}
                              options={options.hullMaterial}
                              isSearchable
                              defaultValue={
                                defaultValues?.hullMaterial?.value === ''
                                  ? options.hullMaterial[0]
                                  : defaultValues?.hullMaterial
                              }
                              maxWidthOptions={280}
                              onBlur={onBlur}
                              onChange={onChange}
                              checked={value}
                              inputRef={ref}
                            />
                          )}
                        />
                      </Box>

                      <Flex gridColumn="1/3">
                        <Box marginRight="20px">
                          <ThemedLabel htmlFor="certifiedFlag" marginBottom={3}>
                            Hull Color
                          </ThemedLabel>
                          <ColorPicker
                            onRemove={() => setValue('hullColor', '')}
                            defaultValue={
                              defaultValues?.hullColor &&
                              defaultValues?.hullColor
                            }
                            onChange={(v: string) => setValue('hullColor', v)}
                            disabled={viewOnly}
                          />
                        </Box>
                        <Box>
                          <ThemedLabel htmlFor="certifiedFlag" marginBottom={3}>
                            Other Colors
                          </ThemedLabel>
                          <Flex>
                            <ColorPicker
                              onRemove={() => setValue('otherHullColor1', '')}
                              defaultValue={
                                defaultValues?.otherHullColor &&
                                defaultValues?.otherHullColor[0]
                              }
                              onChange={(v: string) => {
                                setValue('otherHullColor1', v)
                              }}
                              disabled={viewOnly}
                            />
                            <ColorPicker
                              onRemove={() => setValue('otherHullColor2', '')}
                              defaultValue={
                                defaultValues?.otherHullColor &&
                                defaultValues?.otherHullColor[1]
                              }
                              onChange={(v: string) => {
                                setValue('otherHullColor2', v)
                              }}
                              disabled={viewOnly}
                            />
                            <ColorPicker
                              onRemove={() => setValue('otherHullColor3', '')}
                              defaultValue={
                                defaultValues?.otherHullColor &&
                                defaultValues?.otherHullColor[2]
                              }
                              onChange={(v: string) => {
                                setValue('otherHullColor3', v)
                              }}
                              disabled={viewOnly}
                            />
                            <ColorPicker
                              onRemove={() => setValue('otherHullColor4', '')}
                              defaultValue={
                                defaultValues?.otherHullColor &&
                                defaultValues?.otherHullColor[3]
                              }
                              onChange={(v: string) => {
                                setValue('otherHullColor4', v)
                              }}
                              disabled={viewOnly}
                            />
                          </Flex>
                        </Box>
                      </Flex>
                    </Grid>

                    <Grid
                      mt="20px"
                      templateColumns="repeat(2,1fr)"
                      autoColumns="max-content"
                      autoRows="auto"
                      rowGap="18px"
                      columnGap="16px"
                    >
                      <Box gridColumn="1/3" mt="20px">
                        <Text as="span" fontWeight="600" fontSize="18px">
                          Propulsion
                        </Text>
                      </Box>

                      <Box>
                        <ThemedLabel htmlFor="propulsionManufacturer">
                          Propulsion Manufacturer
                        </ThemedLabel>
                        <ThemedInput
                          id="propulsionManufacturer"
                          {...register('propulsionManufacturer')}
                          isDisabled={viewOnly}
                        />
                        <ThemedFormErrorMessage>
                          {errors.propulsionManufacturer?.message}
                        </ThemedFormErrorMessage>
                      </Box>

                      <Box>
                        <ThemedLabel htmlFor="fuelType">Fuel Type</ThemedLabel>
                        <Controller
                          control={control}
                          name="fuelType"
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => (
                            <ThemedSelect
                              id="fuelType"
                              options={options.fuelType}
                              isSearchable
                              defaultValue={
                                defaultValues?.fuelType?.value === ''
                                  ? options.fuelType[0]
                                  : defaultValues?.fuelType
                              }
                              maxWidthOptions={280}
                              onBlur={onBlur}
                              onChange={onChange}
                              checked={value}
                              inputRef={ref}
                              isDisabled={viewOnly}
                            />
                          )}
                        />
                      </Box>

                      <Grid
                        gridColumn="1/3"
                        mt="20px"
                        templateColumns="repeat(3,1fr)"
                        autoColumns="max-content"
                        autoRows="auto"
                        rowGap="18px"
                        columnGap="16px"
                      >
                        <Box>
                          <ThemedLabel htmlFor="maxHorsePower">
                            Max Horse Power
                          </ThemedLabel>
                          <InputGroup>
                            <ThemedInput
                              id="maxHorsePower"
                              {...register('maxHorsePower')}
                              isDisabled={viewOnly}
                              type="number"
                              pr="36px"
                              onKeyDown={e => {
                                if (
                                  e.key === '-' ||
                                  e.key === '+' ||
                                  e.key === 'E' ||
                                  e.key === 'e'
                                ) {
                                  e.preventDefault()
                                  return false
                                }
                                return true
                              }}
                            />
                            <InputRightElement
                              pointerEvents="none"
                              color="darkGray"
                              fontSize="md"
                              mt="5px"
                              zIndex="0"
                            >
                              hp
                            </InputRightElement>
                          </InputGroup>
                          <ThemedFormErrorMessage>
                            {errors.maxHorsePower?.message}
                          </ThemedFormErrorMessage>
                        </Box>

                        <Box>
                          <ThemedLabel htmlFor="maxFuelCapacity">
                            Max Fuel Capacity
                          </ThemedLabel>
                          <InputGroup>
                            <ThemedInput
                              id="maxFuelCapacity"
                              {...register('maxFuelCapacity')}
                              isDisabled={viewOnly}
                              type="number"
                              pattern="^\d*(\.\d{0,2})?$"
                              pr="36px"
                              onKeyDown={e => {
                                if (
                                  e.key === '-' ||
                                  e.key === '+' ||
                                  e.key === 'e' ||
                                  e.key === 'E'
                                ) {
                                  e.preventDefault()
                                  return false
                                }
                                return true
                              }}
                            />
                            <InputRightElement
                              pointerEvents="none"
                              color="darkGray"
                              fontSize="md"
                              mt="5px"
                              zIndex="0"
                              width={100}
                            >
                              gallons
                            </InputRightElement>
                          </InputGroup>
                          <ThemedFormErrorMessage>
                            {errors.maxFuelCapacity?.message}
                          </ThemedFormErrorMessage>
                        </Box>

                        <Box>
                          <ThemedLabel htmlFor="numberOfEngines">
                            Number of Engines
                          </ThemedLabel>
                          <InputGroup>
                            <ThemedInput
                              id="numberOfEngines"
                              {...register('numberOfEngines')}
                              isDisabled={viewOnly}
                              type="number"
                              pattern="^\d*(\.\d{0,2})?$"
                              pr="36px"
                              max={3}
                              onKeyDown={e => {
                                if (
                                  e.key === '-' ||
                                  e.key === '+' ||
                                  e.key === 'e' ||
                                  e.key === 'E'
                                ) {
                                  e.preventDefault()
                                  return false
                                }
                                return true
                              }}
                            />
                          </InputGroup>
                          <ThemedFormErrorMessage>
                            {errors.numberOfEngines?.message}
                          </ThemedFormErrorMessage>
                        </Box>
                      </Grid>
                    </Grid>

                    <Box gridColumn="1/3" mt="40px">
                      <Checkbox
                        id="agreedTOS"
                        disabled={
                          defaultValues?.status?.value === 'Approved EBC' &&
                          !isAuthenticated
                        }
                        style={{
                          display: 'flex',
                          alignItems: 'flex-start',
                        }}
                        {...register('agreedTOS')}
                        defaultValue={defaultValues.agreedTOS}
                        onChange={e => {
                          if (e.target.checked) {
                            setValue('agreedTOS', true)
                          } else {
                            setValue('agreedTOS', false)
                          }
                        }}
                      >
                        <ThemedLabel htmlFor="agreedTOS">
                          <Text as="span" fontWeight="500" fontSize="13px">
                            You understand and agree that your Enhanced Boat
                            Card selections above constitute a binding purchase
                            order which is subject to and governed by the{' '}
                            <a
                              href="https://uploads-ssl.webflow.com/61d73719ecbb6d15f52e61c8/633cc3d449e318d17dcafeb6_TermsConditions_BoatFinderContract.pdf"
                              style={{
                                textDecoration: 'underline',
                                color: '#3366CC',
                              }}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Terms.
                            </a>{' '}
                            Payment must be submitted in the amount(s) indicated
                            upon receipt of invoice. Payment may be made by
                            credit card, check or ACH. This order cannot be
                            canceled.
                          </Text>
                        </ThemedLabel>
                      </Checkbox>

                      <ThemedFormErrorMessage>
                        {errors.agreedTOS?.message}
                      </ThemedFormErrorMessage>
                    </Box>
                  </>
                )}
              </>
            )}
          </ModalBody>

          <ModalFooter justifyContent="center" pt="46px" pb="48px">
            {!isPendingEbc && (
              <ThemedButton
                isDisabled={isSubmitting || isDeleting}
                type="submit"
                height="55px"
                width="158px"
              >
                {!!defKeys.length && isSubmitting && (
                  <Spinner
                    thickness="2px"
                    speed="0.65s"
                    emptyColor="gray.200"
                    color="blue.500"
                    size="sm"
                  />
                )}
                {!!defKeys.length && !isSubmitting && (
                  <Text as="span">Update</Text>
                )}
                {!defKeys.length && isSubmitting && (
                  <Spinner
                    thickness="2px"
                    speed="0.65s"
                    emptyColor="gray.200"
                    color="blue.500"
                    size="sm"
                  />
                )}
                {!defKeys.length && !isSubmitting && (
                  <Text as="span">Submit</Text>
                )}
              </ThemedButton>
            )}
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  )
}

export default CreateBoatProductForm
