import { Flex, Box, Text, useDisclosure, Grid } from '@chakra-ui/react'
import styled from '@emotion/styled'
import { useAtom } from 'jotai'
import { isEqual } from 'lodash'
import { FiPlus } from 'react-icons/fi'
import { useEffect, useState } from 'react'
import uuid from 'react-uuid'
import useSWR from 'swr'

import { companyRecordAtom } from '../../atoms'
import { CreateProductsServicesForm } from '../../components/partials'
import {
  ThemedButton,
  ThemedImagesAtRow,
  ThemedSelect,
  ThemedTable,
} from '../../components/shared'
import { fetcher } from '../../fetchers'
import {
  AWSQueryOutput,
  QueryVariables,
  ThemedSelectItem,
} from '../../interfaces'
import { useCategories } from '../../hooks'
import ThemedFormHelperMessage from '../../components/shared/ThemedFormHelperMessage'

const columns = [
  'Image',
  'Product Name',
  'Brand Name',
  'Description',
  'Category',
  'Sub Category',
  'ID',
]

const StyledThemedTable = styled(ThemedTable)`
  & {
    width: ${({ isLoading }) => (isLoading ? '100%' : 'calc(100% - 200px)')};

    @media (min-width: 1560px) {
      width: calc(100% - 200px);
    }
  }
`

const ProductsServices: React.FC = () => {
  const [companyRecord] = useAtom(companyRecordAtom)
  const { categories, subCategories } = useCategories({ includeBoats: false })
  const [subCategoryOptions, setSubCategoryOptions] = useState<
    ThemedSelectItem[]
  >([])
  const [subCategoryKey, setSubCategoryKey] = useState('')
  const [filter, setFilter] = useState<Record<string, any>>({})
  const [defaultValues, setDefaultValues] = useState<Record<string, any>>({})
  const categoryOptions = [{ label: 'All', value: 'All' }, ...categories]
  const [variables, setVariables] = useState<QueryVariables>({
    api: '',
    first: 10,
    after: null,
    savedCursor: [null],
    currentPage: 0,
  })

  const {
    data: res,
    mutate,
    error,
  } = useSWR(
    [
      `/company/${companyRecord.record?.companyId}/products-services?first=${
        variables.first
      }${
        variables.after ? `&after=${encodeURIComponent(variables.after)}` : ''
      }`,
      filter,
    ],
    fetcher
  )

  const isLoading = !error && !res
  const records = (res?.data as AWSQueryOutput)?.Items?.map(i => {
    const c = i as any
    return {
      ...c,
      id: c.productServiceId,
      description: `${c.description?.substr(0, 19)}${
        c.description?.length >= 19 ? '...' : ''
      }`,
      image: <ThemedImagesAtRow fileNameHashed={c.fileNameHashed} />,
      brandCompany: companyRecord.record?.name,
    }
  })

  const [key, setKey] = useState('')
  const { isOpen, onOpen, onClose: onModalClose } = useDisclosure()

  const onClose = () => {
    onModalClose()
    setDefaultValues({})
    setTimeout(() => {
      setKey(uuid())
    }, 300)
  }

  const onRowClick = (row: any) => {
    const record = (res?.data as AWSQueryOutput)?.Items?.find(
      c => c.productServiceId === row.id
    )

    setDefaultValues({
      ...record,
      category: { label: record?.category, value: record?.category },
      subCategory: { label: record?.subCategory, value: record?.subCategory },
    })

    onOpen()
  }

  const handleNext = () => {
    if (res?.data?.LastEvaluatedKey) {
      setVariables(v => ({
        ...v,
        after: `${res?.data?.LastEvaluatedKey?.companyId}~${res?.data?.LastEvaluatedKey?.productServiceId}`,
        savedCursor: [
          ...v.savedCursor,
          `${res?.data?.LastEvaluatedKey?.companyId}~${res?.data?.LastEvaluatedKey?.productServiceId}`,
        ],
        currentPage: v.currentPage! + 1,
      }))
    }
  }

  const handlePrev = () => {
    setVariables(v => ({
      ...v,
      after: v.savedCursor[v.currentPage! - 1],
      currentPage: v.currentPage! - 1,
    }))
  }

  useEffect(() => {
    if (!filter?.category) {
      setSubCategoryOptions([])
      setSubCategoryKey(uuid())
    } else if (subCategories?.[filter?.category]?.length) {
      const newSubCategoryOptions = [
        { label: 'All', value: 'All' },
        ...subCategories?.[filter?.category],
      ]
      setSubCategoryOptions(newSubCategoryOptions)
      setSubCategoryKey(uuid())
    }
  }, [filter.category])

  return (
    <Box pb="171px" position="relative">
      <Flex
        justifyContent="space-between"
        mt="54px"
        w="calc(calc(100vw - 245px) - 302px)"
        ml="51px"
      >
        <Box>
          <Text as="span" fontSize="32px" fontWeight="600">
            Add Products &amp; Services
          </Text>
          <Text as="span" display="block" fontSize="13px" color="darkGray">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit
          </Text>
        </Box>
        <ThemedButton
          height="55px"
          width="158px"
          type="button"
          onClick={onOpen}
          leftIcon={<FiPlus color="white" fontSize="22px" />}
          isDisabled={isLoading || !categories.length}
        >
          <Text as="span" ml="8px">
            Create
          </Text>
        </ThemedButton>
      </Flex>
      <Grid
        w="500px"
        templateColumns="repeat(2, 1fr)"
        columnGap="14px"
        ml="51px"
        mt="47px"
      >
        <Box>
          <Text
            as="span"
            display="block"
            fontSize="13px"
            color="darkGray"
            mb="4px"
          >
            Category
          </Text>
          <ThemedSelect
            id=""
            options={categoryOptions}
            isSearchable={false}
            defaultValue={categoryOptions[0]}
            maxWidthOptions={215}
            hasShadow
            color="#718096"
            onChange={(e: ThemedSelectItem) => {
              if (!isEqual(filter, { ...filter, category: e.value })) {
                if (e.value === 'All') {
                  setVariables(v => ({
                    ...v,
                    savedCursor: [null],
                    currentPage: 0,
                    after: null,
                  }))
                  setFilter({})
                } else {
                  setVariables(v => ({
                    ...v,
                    savedCursor: [null],
                    currentPage: 0,
                    after: null,
                  }))
                  setFilter(prev => ({
                    ...prev,
                    category: e.value,
                  }))
                }
              }
            }}
          />
        </Box>
        <Box>
          <Text
            as="span"
            display="block"
            fontSize="13px"
            color="darkGray"
            mb="4px"
          >
            Subcategory
          </Text>
          <ThemedSelect
            id=""
            isDisabled={!subCategories?.[filter?.category]?.length}
            options={subCategoryOptions}
            isSearchable={false}
            defaultValue={subCategoryOptions[0]}
            maxWidthOptions={215}
            hasShadow
            color="#989DA9"
            onChange={(e: ThemedSelectItem) => {
              if (!isEqual(filter, { ...filter, subCategory: e.value })) {
                setVariables(v => ({
                  ...v,
                  savedCursor: [null],
                  currentPage: 0,
                  after: null,
                }))
                if (e.value === 'All') {
                  setFilter(prev => ({ category: prev?.category }))
                } else {
                  setFilter(prev => ({
                    ...prev,
                    subCategory: e.value,
                  }))
                }
              }
            }}
            key={subCategoryKey}
          />
        </Box>
      </Grid>
      <StyledThemedTable
        columns={columns}
        rows={records}
        mt="24px"
        isLoading={isLoading}
        onRowClick={onRowClick}
        disableNext={!res?.data?.hasNextPage}
        disablePrev={Number(variables.currentPage) + 1 === 1}
        handleNext={handleNext}
        handlePrev={handlePrev}
      />
      <Flex
        w="calc(calc(100vw - 245px) - 292px)"
        justifyContent="end"
        ml="51px"
        mb="20px"
        position="absolute"
        bottom="0px"
      >
        <ThemedFormHelperMessage>
          For questions, please contact showservice@nmma.org
        </ThemedFormHelperMessage>
      </Flex>
      <CreateProductsServicesForm
        isOpen={isOpen}
        onClose={onClose}
        mutate={mutate}
        companyId={companyRecord.record?.companyId}
        key={key}
        defaultValues={defaultValues}
      />
    </Box>
  )
}

export default ProductsServices
